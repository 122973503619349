import React from 'react';
import './AppDivider.scss';
import { IAppDividerProps } from './AppDivider.types';

/**
 *
 * @param props
 */
const AppDivider = (props: IAppDividerProps) => {
  const { direction = 'horizontal', color = 'red', className } = props;
  return (
    <div className={`app-divider ${direction} ${className || ''}`}>
      <div className={'app-divider__line'} style={{ backgroundColor: color }} />
    </div>
  );
};

export default AppDivider;
