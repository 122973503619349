import React, { FC } from 'react';
import { AppFontIcon, AppText } from '@ui-kit';
import { IScoreBalanceProps } from './ScoreBalance.types';
import { COLOR_GRAY_MEDIUM, COLOR_ORANGE } from '@shared/constants/colors';
import './ScoreBalance.scss';
import { getNoun, trimSeparator } from '@frontend-modules/frontend-utils';

/**
 *
 * @param props
 */
const ScoreBalance: FC<IScoreBalanceProps> = (props) => {
  const {
    score,
    type = 'default',
    icon,
    isWithPlus,
    isWithPostWord,
    isBackground,
    isFiat,
    textConfig,
  } = props;

  const formatScore = trimSeparator(score);
  const postWord = isFiat
    ? ` рубл${getNoun(score, 'ь', 'я', 'ей')}`
    : ` балл${getNoun(score, '', 'а', 'ов')}`;
  const text = `${isWithPlus ? '+' : ''}${formatScore}${isWithPostWord ? postWord : ''}`;
  return (
    <div className={`score-info ${type} ${isBackground ? 'background' : ''}`}>
      {type === 'full' && <AppText text={'Баллы '} fontType={'averta'} color={COLOR_GRAY_MEDIUM} />}
      <AppFontIcon
        icon={icon || (isFiat ? 'rouble' : 'coin')}
        color={COLOR_ORANGE}
        iconSize={type === 'default' && 24}
      />
      <AppText
        text={text}
        color={COLOR_ORANGE}
        fontType={'averta'}
        type={type === 'small' ? 'small' : 'subtitle'}
        {...textConfig}
      />
    </div>
  );
};

export default ScoreBalance;
