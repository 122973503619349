import React, { FC, useCallback, useEffect, useState } from 'react';
import { Input } from 'antd';
import { AppFontIcon, AppText, TAppInputProps } from '@ui-kit';
import './AppInput.scss';
import { IMaskInput } from 'react-imask';
import { COLOR_BLACK, COLOR_ERROR, COLOR_GRAY_MINI } from '@shared/constants/colors';
import type { InputStatus } from 'antd/es/_util/statusUtils';

const ReactMaskedInputTyped: typeof IMaskInput = IMaskInput;

/**
 *
 * @param props
 */
const AppInput: FC<TAppInputProps> = (props) => {
  const {
    className,
    onInput,
    onChange,
    label,
    iconLeft,
    icon,
    iconColor,
    isPassword,
    onIconClick,
    onLeftIconClick,
    value,
    onFocus,
    defaultValue,
    onBlur,
    mask,
    validationError,
    iconCursorType = 'pointer',
    iconSize,
    isTextArea,
    ...restProps
  } = props;

  const [valueLocal, setValueLocal] = useState('');
  const [isAutoFilled, setAutoFilled] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const isErrorExist = !!validationError?.length;

  /**
   * @description - если браузер подставил значение, то ловим это
   * чтобы инпут корректно отображался
   */
  const checkAutoFillRef = useCallback(
    (node: {
      input: { matches: (arg0: string) => boolean | ((prevState: boolean) => boolean) };
    }) => {
      if (!node) return;
      setTimeout(() => {
        setAutoFilled(node.input?.matches('*:-webkit-autofill'));
      }, 600);
    },
    [],
  );

  const labelClassName = `${
    isAutoFilled || isFocused || valueLocal
      ? 'signature label-small'
      : 'body-light label-placeholder'
  } ${iconLeft ? 'app-input-left-icon-label' : ''} label`;

  /**
   *
   * @param e
   */
  const onChangeLocal = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange?.(e.target.value, e);
    setValueLocal(e.target.value);
    setAutoFilled(false);
  };

  /**
   *
   * @param e
   */
  const onIMaskInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(e.target.value, e);
    setValueLocal(e.target.value);
    setAutoFilled(false);
  };

  /** */
  const onFocusLocal = () => {
    setIsFocused(true);
    setAutoFilled(false);
    onFocus?.();
  };

  /** */
  const onBlurLocal = () => {
    setIsFocused(!!valueLocal);
    setAutoFilled(false);
    onBlur?.();
  };

  useEffect(() => {
    if (defaultValue) {
      setValueLocal(String(defaultValue));
    }
  }, [defaultValue]);

  useEffect(() => {
    if (value) {
      setValueLocal(value);
    }
  }, [value]);

  const onionInputProps = {
    value: valueLocal,
    onChange: onChangeLocal,
    status: (isErrorExist ? 'error' : '') as InputStatus,
    onFocus: onFocusLocal,
    onBlur: onBlurLocal,
    className: `${className || ''} ${!label ? 'without-label' : ''} ${
      valueLocal.length ? 'app-input-filled' : ''
    } ${icon ? 'app-input-icon' : ''} ${iconLeft ? 'app-input-left-icon' : ''}`,
  };

  return (
    <div className={`app-ui-input ${isTextArea ? 'app-ui-input-textarea' : ''}`}>
      {mask ? (
        <ReactMaskedInputTyped
          {...restProps}
          mask={mask}
          onFocus={onFocusLocal}
          onBlur={onBlurLocal}
          onInput={onIMaskInputChange}
          value={valueLocal}
          className={`ant-input ${!label ? 'without-label' : ''} ${icon ? 'app-input-icon' : ''} ${
            iconLeft ? 'app-input-left-icon' : ''
          } ${className} mask-phone ${isErrorExist ? 'error' : ''} ${
            valueLocal.length ? 'app-input-filled' : ''
          }`}
          defaultValue={valueLocal}
          placeholder={''}
          ref={checkAutoFillRef}
          size={null}
        />
      ) : isTextArea ? (
        <Input.TextArea
          style={{ height: 108, resize: 'none' }}
          {...onionInputProps}
          {...restProps}
        />
      ) : (
        <Input {...restProps} {...onionInputProps} ref={checkAutoFillRef} />
      )}

      {(label || isErrorExist) && (
        <AppText
          className={labelClassName}
          text={validationError?.[0] || label}
          color={isErrorExist && COLOR_ERROR}
        />
      )}
      {iconLeft && (
        <AppFontIcon
          className={'left-icon'}
          icon={iconLeft}
          color={iconColor || (valueLocal.length ? COLOR_BLACK : COLOR_GRAY_MINI)}
          iconSize={iconSize}
          onClick={onLeftIconClick}
          cursorType={iconCursorType}
        />
      )}
      {icon && (
        <AppFontIcon
          className={'icon'}
          icon={icon}
          iconSize={iconSize}
          color={iconColor}
          onClick={onIconClick}
          cursorType={iconCursorType}
        />
      )}
    </div>
  );
};

export default AppInput;
