import React, { FC } from 'react';
import { AppFontIcon, AppText, LabelValue } from '@ui-kit';
import { COLOR_BLACK } from '@shared/constants/colors';
import './OrderDeliveryInfo.scss';
import { IOrderDeliveryInfoProps } from './OrderDeliveryInfo.types';
import { showSuccessToast } from '@shared/utils/toast';
/**
 *
 * @param props
 */
const OrderDeliveryInfo: FC<IOrderDeliveryInfoProps> = (props) => {
  const { title = 'Детали доставки', name, phone, deliveryName, address, comment, track } = props;

  const onCopyClick = async () => {
    await navigator.clipboard.writeText(track);
    showSuccessToast({ message: 'Трек-код скопирован' });
  };

  return (
    <div className={'order-delivery-info'}>
      {title && <AppText text={title} type={'subtitle'} color={COLOR_BLACK} />}
      <div className={'order-delivery-info__content'}>
        <div className={'order-delivery-info__content-row'}>
          <LabelValue label={name}>
            <AppText text={phone} />
          </LabelValue>
          <LabelValue label={deliveryName}>
            <AppText text={address} />
          </LabelValue>
        </div>
        {track ? (
          <LabelValue label={'Трек-код'}>
            <div className={'order-delivery-info__content-row__track'}>
              <AppText text={track} />
              <AppFontIcon
                icon={'copy'}
                onClick={onCopyClick}
                iconSize={20}
                cursorType={'pointer'}
              />
            </div>
          </LabelValue>
        ) : null}
        {comment ? (
          <LabelValue label={'Комментарий к заказу'}>
            <AppText text={comment} />
          </LabelValue>
        ) : null}
      </div>
    </div>
  );
};

export default OrderDeliveryInfo;
