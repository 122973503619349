import React from 'react';
import './ProductCardVerticalList.scss';
import { AppVerticalScrollList } from '@ui-kit';
import { IProductData } from '@shared/models/product';

/**
 * @description - лист товаров со скроллом (вертикальный)
 * @param props
 * @param props.productList
 * @param props.renderProduct
 * @param props.productKey
 */
const ProductCardVerticalList = (props: {
  productList: IProductData[];
  renderProduct: (item: IProductData, index: number) => React.ReactNode;
  productKey?: keyof IProductData | string[];
}) => {
  const { productList, renderProduct, productKey = 'externalId' } = props;

  return (
    <div className={'product-card-vertical-list'}>
      <AppVerticalScrollList<IProductData>
        paddingBottom={0}
        className={'product-card-vertical-list__scroll'}
        list={productList}
        renderItem={renderProduct}
        itemKey={productKey}
      />
    </div>
  );
};

export default ProductCardVerticalList;
