import React, { FC, useState } from 'react';
import './Sidebar.scss';
import { sidebarItems } from './Sidebar.config';
import { AppDrawer, AppMenu, AppSider, LogoButton } from '@ui-kit';
import { SidebarFooter } from '../SidebarFooter';
import { PRIVATE_ROUTES } from '@shared/constants/routes';
import { ISiderProps } from './Sidebar.types';
import { useScreenSize } from '@shared/hooks/useScreenSize';

/**
 *
 * @param props
 */
const Sidebar: FC<ISiderProps> = (props) => {
  const { onClick } = props;
  const { screenSize } = useScreenSize();
  const isCollapsableScreenSize = screenSize.width < 1286 && screenSize.width > 639;

  const [isSiderCollapsed, setIsSiderCollapsed] = useState(isCollapsableScreenSize);
  return (
    <div className={'sidebar'}>
      {isCollapsableScreenSize ? (
        <AppDrawer
          open
          isCollapsed={isCollapsableScreenSize}
          onHover={(isHover) => setIsSiderCollapsed(!isHover)}
        >
          <AppSider
            collapsible={isSiderCollapsed}
            collapsed={isSiderCollapsed}
            header={<LogoButton isSmall={isSiderCollapsed} logoButtonLink={PRIVATE_ROUTES.HOME} />}
            footer={<SidebarFooter isCollapsed={isSiderCollapsed} onClick={onClick} />}
          >
            <AppMenu menuItems={sidebarItems} />
          </AppSider>
        </AppDrawer>
      ) : (
        <AppSider
          header={<LogoButton logoButtonLink={PRIVATE_ROUTES.HOME} />}
          footer={<SidebarFooter onClick={onClick} />}
        >
          <AppMenu menuItems={sidebarItems} />
        </AppSider>
      )}
    </div>
  );
};

export default Sidebar;
