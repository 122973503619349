import React from 'react';
import { AppCheckbox, AppLink, AppText } from '@ui-kit';
import { EXTERNAL_ROUTES } from '@shared/constants/routes';
import { IOrderFormConfirmProps } from './OrderFormConfirm.types';

/**
 * @description - блок подтверждения соглашений
 * @param props
 */
const OrderFormConfirm = (props: IOrderFormConfirmProps) => {
  const { isConfirmAgreements, setConfirmAgreements } = props;
  return (
    <AppCheckbox
      value={isConfirmAgreements}
      onChange={setConfirmAgreements}
      label={
        <div className={'agreement-inks'}>
          <AppText text={'Я прочитал(а) и принимаю '} type={'small'} />
          <AppLink isNested={false} target={'_blank'} link={EXTERNAL_ROUTES.TERMS_OF_SERVICE}>
            <AppText text={'Соглашение о пользовании услугами '} type={'small'} />
          </AppLink>
          <AppText text={'и '} type={'small'} />
          <AppLink isNested={false} target={'_blank'} link={EXTERNAL_ROUTES.WEBSITE_PUBLIC_OFFER}>
            <AppText text={'Публичную оферту сайта *'} type={'small'} />
          </AppLink>
        </div>
      }
    />
  );
};

export default OrderFormConfirm;
