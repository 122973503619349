import { createSlice } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { FetchStatuses } from '@shared/constants/fetch';
import { persistReducer } from 'redux-persist';
import { loadNotMyOrderListAction, loadOrderListAction } from '@entities/order';
import { TOrderState } from '@shared/models/order';
import { logoutAction } from '@entities/auth';

const initialState: TOrderState = {
  data: {},
  clientData: null,
  referralData: null,
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {},
  /**
   *
   * @param builder
   */
  extraReducers: (builder) => {
    builder
      .addCase(loadOrderListAction.pending, (state, { meta }) => {
        const payload = meta.arg;
        state.data = {
          ...state.data,
          [payload.status]: {
            ...state.data[payload.status],
            fetchStatus: payload.isLoadMore ? FetchStatuses.PENDING_MORE : FetchStatuses.PENDING,
          },
        };
      })
      .addCase(loadOrderListAction.fulfilled, (state, { payload, meta }) => {
        const requestPayload = meta.arg;
        state.data = {
          ...state.data,
          [requestPayload.status]: {
            ...state.data[requestPayload.status],
            amountTotal: payload.amountTotal,
            bonusTotal: payload.bonusTotal,
            pagination: payload.pagination,
            sorting: requestPayload.query.sorting,
            filters: requestPayload.query.filters,
            results: requestPayload.isLoadMore
              ? [...(state.data?.[requestPayload.status]?.results || []), ...payload.results]
              : payload.results,
            fetchStatus: FetchStatuses.FULFILLED,
          },
        };
      })
      .addCase(loadOrderListAction.rejected, (state, { error, meta }) => {
        const requestPayload = meta.arg;
        state.data = {
          ...state.data,
          [requestPayload.status]: {
            ...state.data[requestPayload.status],
            fetchStatus: FetchStatuses.REJECTED,
            error: error.message,
          },
        };
      });
    builder
      .addCase(loadNotMyOrderListAction.pending, (state, { meta }) => {
        const payload = meta.arg;
        state[`${payload.type}Data`] = {
          ...state?.[`${payload.type}Data`],
          [payload.status]: {
            ...state?.[`${payload.type}Data`]?.[payload.status],
            fetchStatus: FetchStatuses.PENDING,
          },
        };
      })
      .addCase(loadNotMyOrderListAction.fulfilled, (state, { payload, meta }) => {
        const requestPayload = meta.arg;
        state[`${requestPayload.type}Data`] = {
          ...state[`${requestPayload.type}Data`],
          [requestPayload.status]: {
            ...state[`${requestPayload.type}Data`][requestPayload.status],
            amountTotal: payload.amountTotal,
            bonusTotal: payload.bonusTotal,
            pagination: payload.pagination,
            filters: requestPayload.query.filters,
            results: payload.results,
            fetchStatus: FetchStatuses.FULFILLED,
          },
        };
      })
      .addCase(loadNotMyOrderListAction.rejected, (state, { error, meta }) => {
        const requestPayload = meta.arg;
        state[`${requestPayload.type}Data`] = {
          ...state[`${requestPayload.type}Data`],
          [requestPayload.status]: {
            ...state[`${requestPayload.type}Data`][requestPayload.status],
            fetchStatus: FetchStatuses.REJECTED,
            error: error.message,
          },
        };
      });
    builder.addCase(logoutAction, () => {
      return initialState;
    });
  },
});

export const orderReducer = orderSlice.reducer;

export const orderPersistConfig = {
  key: 'biorich::order',
  storage,
  version: 1,
};

export const persistedOrderReducer = persistReducer(orderPersistConfig, orderReducer);
