import React, { FC, useEffect, useState } from 'react';
import { AppButton, AppFontIcon, AppLink } from '@ui-kit';
import { IAppTextIconLinkProps } from './AppTextIconLink.types';
import { useScreenSize } from '@shared/hooks/useScreenSize';
import './AppTextIconLink.scss';
import { useNavigate } from 'react-router-dom';

/**
 *
 * @param props
 */
const AppTextIconLink: FC<IAppTextIconLinkProps> = (props) => {
  const { text, link, isNested = true, isGoBack } = props;
  const { calculateScreenType, screenSize } = useScreenSize();

  const [isAdaptive, setIsAdaptive] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (screenSize.width < 1150) {
      setIsAdaptive(true);
    } else {
      setIsAdaptive(false);
    }
  }, [screenSize]);

  const onBackClick = () => {
    navigate(-1);
  };

  return (
    <>
      {calculateScreenType('desktop') ? (
        <>
          <AppLink isNested={isNested} link={link} isGoBack={isGoBack}>
            <div className={`auth-header-link ${isAdaptive ? 'adaptive' : ''}`}>
              {isAdaptive ? (
                <AppButton icon={'arrow-left'} onClick={onBackClick} size="large" />
              ) : (
                <AppFontIcon icon={'arrow-left'} />
              )}
              {isAdaptive ? '' : text}
            </div>
          </AppLink>
        </>
      ) : (
        <>
          <AppButton icon={'arrow-left'} onClick={onBackClick} size="large" />
        </>
      )}
    </>
  );
};

export default AppTextIconLink;
