export type TFetchStatus =
  | 'idle'
  | 'pending'
  | 'pending-prev'
  | 'pending-more'
  | 'fulfilled'
  | 'rejected';

export enum FetchStatuses {
  'IDLE' = 'idle',
  'PENDING' = 'pending',
  'PENDING_PREV' = 'pending-prev',
  'PENDING_MORE' = 'pending-more',
  'FULFILLED' = 'fulfilled',
  'REJECTED' = 'rejected',
}
