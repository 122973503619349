import React from 'react';
import { AppButton } from '@ui-kit';
import { ChooseDeliveryMethodCards } from '@features/delivery/ChooseDeliveryMethod';
import { DeliveryPointInfoCard, getDeliveryTypeName } from '@entities/delivery';
import { TDeliveryTypes } from '@shared/models/delivery';
import { ChooseDeliveryPointByMap } from '@features/delivery/ChooseDeliveryPointByMap';
import { IFormDeliveryProps } from './OrderFormDelivery.types';

/**
 * @description - компонент выбора доставки в форме
 * @param props
 */
const OrderFormDelivery = (props: IFormDeliveryProps) => {
  const {
    isDeliveryDataLoading,
    isDeliveryErrorExist,
    activeMapDeliveryPoint,
    setActiveMapDeliveryPoint,
    mapConfig,
    setMapConfig,
    loadDeliveryPointData,
    onInputAddressSelect,
    onInputAddressBlur,
    orderInfo,
    mapShowStatus,
    isTablet,
    onGoBackClick,
    onDeliveryPointClick,
    deliveryData,
    detailedCartList,
    onPickDeliveryPointClick,
    ymapsSuggest,
    ymaps,
    cartLoading,
    isAddressValid,
    onDeliveryMethodChange,
    activeDeliveryPoint,
    setActiveDeliveryPoint,
    setDeliveryData,
  } = props;
  return (
    <>
      <ChooseDeliveryMethodCards
        isDisabled={!!cartLoading || isDeliveryDataLoading}
        mapRef={ymaps?.current}
        selectedMethod={orderInfo.delivery}
        selectedAddress={orderInfo.address}
        isAddressValid={isAddressValid}
        onChange={onDeliveryMethodChange}
      />
      <div
        className={`delivery-info-container ${
          orderInfo.delivery !== 'pvz' && isAddressValid && deliveryData ? 'show' : 'hidden'
        }`}
      >
        <DeliveryPointInfoCard
          deliveryData={deliveryData}
          cartList={detailedCartList}
          pointData={{ address: orderInfo.address }}
          title={getDeliveryTypeName(orderInfo?.delivery as TDeliveryTypes)}
          isPointLoading={isDeliveryDataLoading}
        />
      </div>
      <div className={`map-container ${mapShowStatus}`}>
        {activeDeliveryPoint ? (
          <DeliveryPointInfoCard
            deliveryData={deliveryData}
            cartList={detailedCartList}
            pointData={activeDeliveryPoint}
            isPointLoading={isDeliveryDataLoading}
            control={
              <AppButton
                type={'black'}
                size={'medium'}
                textType={'body'}
                text={'Изменить пункт'}
                isFull
                onClick={() => {
                  setActiveDeliveryPoint(null);
                  setDeliveryData(null);
                }}
              />
            }
          />
        ) : (
          <ChooseDeliveryPointByMap
            mapConfig={mapConfig}
            orderInfo={orderInfo}
            mapShowStatus={mapShowStatus}
            activeMapDeliveryPoint={activeMapDeliveryPoint}
            isDeliveryErrorExist={isDeliveryErrorExist}
            isTablet={isTablet}
            isDeliveryDataLoading={isDeliveryDataLoading}
            setActiveMapDeliveryPoint={setActiveMapDeliveryPoint}
            loadDeliveryPointData={loadDeliveryPointData}
            onInputAddressSelect={onInputAddressSelect}
            onInputAddressBlur={onInputAddressBlur}
            setMapConfig={setMapConfig}
            onGoBackClick={onGoBackClick}
            onDeliveryPointClick={onDeliveryPointClick}
            deliveryData={deliveryData}
            detailedCartList={detailedCartList}
            onPickDeliveryPointClick={onPickDeliveryPointClick}
            ymaps={ymaps}
            ymapsSuggest={ymapsSuggest}
          />
        )}
      </div>
    </>
  );
};

export default OrderFormDelivery;
