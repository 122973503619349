import React from 'react';
import './HomePage.scss';
import { LatestOrders } from '@widgets/order/LatestOrders';
import { ActiveOrders } from '@widgets/order/ActiveOrders';

/** */
const HomePage = () => {
  return (
    <div className={'home-page'}>
      <ActiveOrders />
      <LatestOrders />
    </div>
  );
};

export default HomePage;
