import { RefObject, useEffect, useRef } from 'react';

/**
 *
 * @param callback
 * @param ignoreClassNames
 */
export const useClickOutside = (callback: () => void, ignoreClassNames?: string[]) => {
  const ref: RefObject<HTMLDivElement> = useRef(null);

  useEffect(() => {
    /**
     *
     * @param event
     */
    const handleClickOutside = (event: MouseEvent) => {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) && // используйте Node вместо HTMLElement
        (!ignoreClassNames ||
          ignoreClassNames.length === 0 ||
          ignoreClassNames.every((className) => !(event.target as HTMLElement).closest(className)))
      ) {
        callback();
      }
    };

    /**
     *
     * @param event
     */
    const handleGlobalClick = (event: MouseEvent) => {
      if (!ref.current?.contains(event.target as Node)) {
        handleClickOutside(event);
      }
    };

    document.addEventListener('click', handleGlobalClick);

    return () => {
      document.removeEventListener('click', handleGlobalClick);
    };
  }, [callback, ignoreClassNames, ref]);

  return ref;
};
