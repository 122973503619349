export const PUBLIC_ROUTES = {
  AUTHORIZATION: '/authorization',
  RECOVERY: '/recovery',
  REGISTRATION: '/registration',
  RESET_PASSWORD: '/reset-password',
  PUBLIC_CART: '/public-cart',
  PUBLIC_ORDER_SUCCESS: '/public-cart/success',
  PUBLIC_ORDER_ERROR: '/public-cart/error',
  REFERRAL_LINK_PAGE: '/r',
  ERROR: '*',
};

export const PRIVATE_ROUTES = {
  HOME: '/',
  SCORE: '/score',
  HISTORY: '/history',
  SETTINGS: '/settings',
  CART: '/cart',
};

export const EXTERNAL_ROUTES = {
  OLD_SITE: process.env.REACT_APP_SITE_URL,
  PRODUCTS: `${process.env.REACT_APP_SITE_URL}/shop/`,
  ABOUT_COMPANY: `${process.env.REACT_APP_SITE_URL}/o-kompanii/`,
  TERMS_OF_SERVICE: `${process.env.REACT_APP_SITE_URL}/soglashenie-o-polzovanii-uslugami/`,
  POLICY: `${process.env.REACT_APP_SITE_URL}/privacy-policy/`,
  WEBSITE_PUBLIC_OFFER: `${process.env.REACT_APP_SITE_URL}/publichnaya-oferta/`,
  TELEGRAM_OFFICIAL_CHANNEL: `https://t.me/+PmZPbv4VFhxhNDRi`,
  TELEGRAM_OFFICIAL_CHAT: `https://t.me/+q4W0SL3bTo85ZjQy`,
  VK_OFFICIAL: `https://vk.com/biorichcom
`,
};
