import React, { useState } from 'react';
import './AppDrawer.scss';
import { Drawer } from 'antd';
import { IAppDrawerProps } from './AppDrawer.types';

/**
 * @description - сайдбар
 * @param props
 */
const AppDrawer = (props: IAppDrawerProps) => {
  const {
    children,
    placement = 'left',
    extra = null,
    title = '',
    onHover,
    isCollapsed,
    ...restProps
  } = props;

  const [isOpen, setIsOpen] = useState(!isCollapsed);

  /**
   *
   * @param value
   */
  const handleHover = (value: boolean) => {
    if (isCollapsed) {
      onHover?.(value);
      setIsOpen(value);
    }
  };
  return (
    <Drawer
      title={title}
      placement={placement}
      closable={false}
      onClose={() => handleHover(false)}
      extra={extra}
      mask={isCollapsed && isOpen}
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
      {...restProps}
    >
      {children}
    </Drawer>
  );
};

export default AppDrawer;
