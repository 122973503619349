import React, { useEffect, useRef, useState } from 'react';
import { AppButton, AppCheckbox, AppFontIcon, AppSearchInput, AppText } from '@ui-kit';
import { IDeliveryParams, IDeliveryPoint, TDeliverySourceTypes } from '@shared/models/delivery';
import { COLOR_BLACK, COLOR_GRAY_MAX } from '@shared/constants/colors';
import {
  DeliveryPointInfoBalloon,
  DeliveryProvider,
  getDeliveryName,
  getDeliveryTypeIcon,
  getDeliveryTypeName,
} from '@entities/delivery';
import {
  adjustDuplicateCoordinates,
  Clusterer,
  combineDeliveryPoints,
  GeolocationControl,
  initReplaceAPIYMap,
  Map,
  suggestChangeVisibility,
  TypeSelector,
  YandexCustomPlacemark,
  ZoomControl,
} from '@entities/yandex';
import { IPaginationResponse } from '@shared/models/pagination';
import { Event } from 'yandex-maps';
import { debounce } from '@shared/utils/debounce';
import { YMapsApi } from '@pbe/react-yandex-maps/typings/util/typing';
import { createPortal } from 'react-dom';
import { IChooseDeliveryPointByMapProps } from './ChooseDeliveryPointByMap.types';

/**
 *
 * @param props
 */
const ChooseDeliveryPointByMap = (props: IChooseDeliveryPointByMapProps) => {
  const {
    isDeliveryDataLoading,
    isDeliveryErrorExist,
    activeMapDeliveryPoint,
    setActiveMapDeliveryPoint,
    mapConfig,
    setMapConfig,
    loadDeliveryPointData,
    onInputAddressSelect,
    onInputAddressBlur,
    orderInfo,
    mapShowStatus,
    isTablet,
    onGoBackClick,
    onDeliveryPointClick,
    deliveryData,
    detailedCartList,
    onPickDeliveryPointClick,
    ymapsSuggest,
    ymaps,
  } = props;

  const mapInstance = useRef(null);

  const [points, setPoints] = useState<{
    pagination: IPaginationResponse;
    results: IDeliveryPoint[];
    clicked: IDeliveryPoint[];
  }>({ pagination: null, results: [], clicked: [] });
  const [inputPoints, setInputPoints] = useState<{
    pagination: IPaginationResponse;
    results: IDeliveryPoint[];
  }>({ pagination: null, results: [] });
  const [activeFilters, setActiveFilters] = useState({
    search: '',
    source: ['cdek', 'post_russia', 'yandex'],
  });
  const [isInputPointsLoading, setInputPointsLoading] = useState(false);
  const [isInputPointsLoadingMore, setInputPointsLoadingMore] = useState(false);
  const balloonRootElement = document.getElementById(
    `point-portal-${activeMapDeliveryPoint?.externalId}`,
  );

  useEffect(() => {
    setPoints((prevState) => {
      if (
        !activeMapDeliveryPoint ||
        prevState.clicked.find((cl) => cl?.externalId === activeMapDeliveryPoint?.externalId)
      ) {
        return prevState;
      }
      return {
        ...prevState,
        clicked: [...prevState.clicked, activeMapDeliveryPoint],
      };
    });
  }, [activeMapDeliveryPoint]);

  /**
   *
   * @param params
   * @param params.search
   * @param params.currentPage
   * @param params.limit
   */
  const findDeliveryPointsByInput = async (params: IDeliveryParams) => {
    return DeliveryProvider.findDeliveryPoints(params);
  };

  /**
   *
   * @param bounds
   */
  const findDeliveryPointsByMap = async (bounds: number[]) => {
    return DeliveryProvider.findDeliveryPoints({
      limit: 150,
      latitudeRange: [bounds[0][0], bounds[1][0]],
      longitudeRange: [bounds[0][1], bounds[1][1]],
    });
  };

  /**
   *
   * @param newParams
   */
  const onSearchInputLoad = async (newParams) => {
    setInputPointsLoading(true);
    setActiveFilters((prevState) => {
      return {
        ...prevState,
        search: newParams.search,
      };
    });
    const res = await findDeliveryPointsByInput({ ...activeFilters, ...newParams });
    if (res?.results) {
      const newPoints = {
        pagination: res.pagination,
        results: adjustDuplicateCoordinates(res?.results),
      };
      setInputPoints(newPoints);
    }
    setInputPointsLoading(false);
  };
  /**
   *
   * @param newParams
   */
  const onSearchInputLoadMore = async (newParams) => {
    setInputPointsLoadingMore(true);
    const res = await findDeliveryPointsByInput({ ...activeFilters, ...newParams });
    if (res.results) {
      const newPoints = {
        pagination: res.pagination,
        results: [...points.results, ...adjustDuplicateCoordinates(res?.results)],
      };
      setInputPoints(newPoints);
    }
    setInputPointsLoadingMore(false);
  };

  /**
   *
   * @param newFilters
   */
  const onFiltersChange = async (newFilters) => {
    if (activeFilters.search) {
      setInputPointsLoadingMore(true);
      const res = await findDeliveryPointsByInput({
        ...activeFilters,
        ...newFilters,
      });
      if (res.results) {
        const newPoints = {
          pagination: res.pagination,
          results: adjustDuplicateCoordinates(res?.results),
        };
        setInputPoints(newPoints);
      }
      setInputPointsLoadingMore(false);
    }
  };

  /**
   *
   * @param point
   */
  const onInputMapDeliveryPointSelect = (point) => {
    setActiveMapDeliveryPoint(point);
    setMapConfig((prevState) => ({
      ...prevState,
      center: [point.latitude, point.longitude],
      zoom: 20,
    }));
    setPoints((prevState) => {
      const existingIndex = prevState.results.findIndex(
        (item) => item.externalId === point.externalId,
      );
      // если точка уже загружена то ничего не делаем
      if (existingIndex !== -1) {
        return prevState;
      }
      // если не нашли то добавляем точку на карту
      return {
        ...prevState,
        results: [
          ...prevState.results,
          {
            ...point,
            isDefaultOpened: true,
          },
        ],
      };
    });
    loadDeliveryPointData(point, true);
  };

  /**
   *
   * @param event
   */
  const onMapBoundsChange = (event: Event) => {
    const newBounds = event.get('newBounds');
    findDeliveryPointsByMap(newBounds)
      .then((response) => {
        if (response.results) {
          setPoints((prevState) => {
            return {
              ...prevState,
              pagination: response.pagination,
              results: adjustDuplicateCoordinates(
                combineDeliveryPoints(response?.results, prevState.clicked),
              ),
            };
          });
        }
      })
      .catch(() => {});
  };

  const onMapBoundsChangeDebounced = debounce(onMapBoundsChange, 500);
  /**
   * @description инициализация карты с переопределением метода open для подмены запроса с яндекса на наш сервер
   * @param map
   */
  const onMapInit = (map: YMapsApi) => {
    initReplaceAPIYMap();
    ymaps.current = map;
    ymapsSuggest.current = new ymaps.current.SuggestView('suggest');
    ymapsSuggest.current.events.add('select', onInputAddressSelect);
    mapInstance.current.events.add('boundschange', onMapBoundsChangeDebounced);
    ymapsSuggest.current.changeVisibility = suggestChangeVisibility();
    if (orderInfo?.address) {
      // нужно вызывать чтобы свалидировать адрес, если он пришел с бэка
      onInputAddressBlur();
    }
  };

  // скрывает навбар, когда карта во весь экран
  useEffect(() => {
    const stickyNavbar = document.querySelector('.personal-content__navbar');
    if (mapShowStatus === 'show' && isTablet) {
      stickyNavbar?.classList.remove('navbar_sticky');
    } else {
      stickyNavbar?.classList.add('navbar_sticky');
    }
  }, [isTablet, mapShowStatus]);

  return (
    <>
      <div className={'map-container__map'}>
        <div className={'map-container__map-filters'}>
          <AppSearchInput<IDeliveryPoint>
            isResultPanel
            disabled={isDeliveryDataLoading}
            clickOutsideIgnoreClasses={['.map-container__map-filters-checkboxes']}
            results={inputPoints.results}
            renderItem={(item) => {
              return (
                <div className={'create-order-form__search-input__item'}>
                  <div className={'create-order-form__search-input__item-title'}>
                    <AppFontIcon
                      color={COLOR_GRAY_MAX}
                      icon={getDeliveryTypeIcon(item.source, item.typeSource)}
                    />
                    <AppText
                      text={`${getDeliveryTypeName(item?.typeSource)} (${getDeliveryName(
                        item?.source,
                      )})`}
                      color={COLOR_GRAY_MAX}
                      type={'small'}
                    />
                  </div>
                  <AppText text={item.title} type={'body'} />
                </div>
              );
            }}
            className={'map-container__map-filters-input'}
            pagination={points.pagination}
            onLoad={onSearchInputLoad}
            onLoadMore={onSearchInputLoadMore}
            isFetching={isInputPointsLoading}
            isFetchingMore={isInputPointsLoadingMore}
            limit={20}
            onItemClick={onInputMapDeliveryPointSelect}
            label={'Поиск'}
          />
          <div className={'map-container__map-filters-checkboxes'}>
            {(['post_russia', 'cdek', 'yandex'] as Array<TDeliverySourceTypes>).map((item) => (
              <AppCheckbox
                checked={activeFilters.source.includes(item)}
                label={getDeliveryName(item)}
                disabled={activeFilters.source.includes(item) && activeFilters.source.length <= 1}
                onChange={(newState) => {
                  setActiveFilters((prevState) => {
                    const newFilters = {
                      ...prevState,
                      source: newState
                        ? [...prevState.source, item]
                        : prevState.source.filter((i) => i !== item),
                    };
                    onFiltersChange(newFilters);
                    return newFilters;
                  });
                }}
                key={item}
              />
            ))}
          </div>
        </div>
        {mapShowStatus === 'show' && isTablet && (
          <AppButton
            className={'map-container__map__back-button'}
            size={'small'}
            type={'link'}
            fontWeight={400}
            textType={'subtitle'}
            text={'Назад'}
            iconSize={24}
            color={COLOR_BLACK}
            iconLeft={'arrow-left'}
            onClick={onGoBackClick}
          />
        )}
        <div className={'map-container__map-map'}>
          <Map
            instanceRef={mapInstance}
            state={mapConfig}
            onLoad={onMapInit}
            options={{ yandexMapDisablePoiInteractivity: true }}
            width={'100%'}
            height={isTablet ? '100%' : 360}
          >
            {mapShowStatus === 'show' && !isTablet && (
              <>
                <GeolocationControl />
                <ZoomControl />
                <TypeSelector />
              </>
            )}
            <Clusterer
              options={{
                groupByCoordinates: false,
              }}
            >
              {points?.results?.map((point) => {
                return (
                  <YandexCustomPlacemark
                    key={point.externalId}
                    data={point}
                    isActive={point.externalId === activeMapDeliveryPoint?.externalId}
                    mapInstanceRef={ymaps}
                    onClick={onDeliveryPointClick}
                  />
                );
              })}
            </Clusterer>
          </Map>
        </div>
      </div>
      {balloonRootElement &&
        activeMapDeliveryPoint &&
        createPortal(
          <DeliveryPointInfoBalloon
            isClosable
            deliveryData={deliveryData}
            cartList={detailedCartList}
            pointData={activeMapDeliveryPoint}
            isPointError={isDeliveryErrorExist}
            isPointLoading={isDeliveryDataLoading}
            control={
              <AppButton
                loading={isDeliveryDataLoading}
                disabled={isDeliveryErrorExist}
                type={'black'}
                size={'medium'}
                text={'Заберу здесь'}
                textType={'body'}
                isFull
                onClick={onPickDeliveryPointClick}
              />
            }
          />,
          balloonRootElement,
        )}
    </>
  );
};

export default ChooseDeliveryPointByMap;
