import React, { FC } from 'react';
import './ProductCard.scss';
import { IProductCardProps } from './ProductCard.types';
import { AppText } from '@ui-kit';
import { COLOR_GRAY_MAX, COLOR_GRAY_MEDIUM } from '@shared/constants/colors';
import { trimSeparator } from '@frontend-modules/frontend-utils';

/**
 *
 * @param props
 */
const ProductCard: FC<IProductCardProps> = (props) => {
  const { productData, bonusBlock, actionButton, bottomActionBlock } = props;

  const { name, oldPrice, count, imageUrl, offer, amount } = productData;

  return (
    <div className={'product-card-wrapper'}>
      {!!bonusBlock && <div className={'product-card__bonus'}>{bonusBlock}</div>}
      <div className={'product-card'}>
        <div className={'product-card__img'}>
          <div
            className={'product-card__img_img'}
            style={{ backgroundImage: `url(${imageUrl})` }}
          />
        </div>
        <div className={'product-card__description'}>
          <div className={'product-card__description-name'}>
            <AppText text={name} type={'note-bold'} />
            {!!actionButton && <div className={'product-card__feature'}>{actionButton}</div>}
          </div>
          <div className={'product-card__description-footer'}>
            <div className={'product-card__description-quantity'}>
              {bottomActionBlock || (
                <AppText
                  text={`${count || 0} ${offer?.unit?.sym || 'шт'}`}
                  type={'small'}
                  fontWeight={400}
                  color={COLOR_GRAY_MAX}
                />
              )}
            </div>
            <div className={'product-card__prices'}>
              {oldPrice && (
                <AppText
                  text={`${oldPrice || 0} руб.`}
                  color={COLOR_GRAY_MEDIUM}
                  type={'small'}
                  fontWeight={400}
                  className={'crossed-out'}
                />
              )}
              <AppText text={`${trimSeparator(amount)} руб.`} fontWeight={600} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
