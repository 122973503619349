import { $BaseHttpClient } from '@shared/api/BaseHttpClient';
import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import {
  getAccessToken,
  getRefreshToken,
  removeAccessToken,
  removeRefreshToken,
  setAccessToken,
  setRefreshToken,
} from '@shared/utils/token';
import { showErrorToast } from '@shared/utils/toast';
import { IErrorResponse } from '@shared/models/errors';
import { ErrorsCaptureWrapperApi } from '@frontend-modules/error-handler';
import { UntraceableApiList } from './untraceableApiList';
import { Endpoints } from '@shared/constants/endpoints';

const previousControllers: Record<string, AbortController> = {};

// TODO: переписать способ обновления токена
// на обновление только 1 раз за все запросы
// eslint-disable-next-line @typescript-eslint/naming-convention
const $HttpClient = new $BaseHttpClient({
  _baseURL: process.env.REACT_APP_HOST,
  _headers: {
    'Content-type': 'application/json; charset=UTF-8',
  },
});

/**
 *
 * @param refresh
 */
const updateRefreshToken = (refresh) => {
  const refreshAxios = axios.create({
    baseURL: `${process.env.REACT_APP_HOST}`,
  });
  return refreshAxios.post(Endpoints.TOKEN_REFRESH, { refresh });
};

/**
 *
 * @param httpClient
 * @param callback
 */
const httpClientErrorListener = (httpClient, callback) => {
  httpClient.instance.interceptors.response.use(
    async (response) => response,
    async (error) => {
      callback?.(error);
      return error;
    },
  );
};

$HttpClient.instance.interceptors.request.use(
  async (config: InternalAxiosRequestConfig & { abortKey?: string }) => {
    const { abortKey } = config;
    if (config && abortKey) {
      if (previousControllers[abortKey]) {
        previousControllers[abortKey].abort();
      }
      const controller = new AbortController();
      config.signal = controller.signal;
      previousControllers[abortKey] = controller;
    }
    const accessToken = getAccessToken();
    if (accessToken) {
      if (config) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    }
    return config;
  },
);

/** */
const logout = () => {
  removeAccessToken();
  removeRefreshToken();
  window.location.href = `${window.location.origin}/authorization`;
};

$HttpClient.instance.interceptors.response.use(
  (response: AxiosResponse) => response.data,
  async (error: AxiosError<IErrorResponse>) => {
    if (error.code === 'ERR_CANCELED') {
      // если запрос отменен, то его нужно просто проигнорировать
      // поэтому просто возвращаем ничего
      return;
    }
    const errorData = error.response;
    const originalConfig = error.config;
    const currentRefresh = getRefreshToken();
    if (errorData?.data?.extra?.fields?.non_field_errors?.length) {
      showErrorToast({ message: errorData.data.extra.fields.non_field_errors[0] });
    }
    if (errorData?.status === 500 || errorData?.status === 502) {
      showErrorToast({ message: 'Что-то пошло не так...' });
    }

    if (errorData?.status === 401) {
      if (currentRefresh) {
        try {
          const newTokens = await updateRefreshToken(currentRefresh);
          setAccessToken(newTokens.data.access);
          if (newTokens.data.refresh) {
            setRefreshToken(newTokens.data.refresh);
          }
          return await $HttpClient.instance(originalConfig);
        } catch (errorAfterRefresh) {
          // если 400 пришел на рефреше - то разлогиниваем
          if (errorAfterRefresh.response.config.url.includes('token/refresh')) {
            logout();
            return;
          }
          throw errorAfterRefresh;
        }
      } else {
        logout();
        return;
      }
    }
    ErrorsCaptureWrapperApi({
      error,
      UntraceableApiList,
      showAlertReportBtn: false,
      alertConfig: {
        isShowAlert: false,
      },
    });
    throw error;
  },
);

export { $HttpClient, httpClientErrorListener };
