import { isProduction } from '@shared/utils/environment';
import { IOrderCreateResponse } from '@shared/models/order';
import { IProductData } from '@shared/models/product';

const localWindow: any = window;

/**
 * @description - форматируем товары в нужный для метрики вид
 * @param products
 */
const formatProducts = (products: IProductData[]) => {
  return products.map((product) => {
    return {
      id: `product_id_${product.externalId}`,
      name: product.name,
      price: product.offer.price,
      // category: 'БАД',
      quantity: product.count,
    };
  });
};

/**
 * @description - отслеживание добавления товара и его количества  в корзину
 * @param products
 */
const trackAddToCart = (products: IProductData[]) => {
  if (isProduction()) {
    localWindow.dataLayer.push({
      ecommerce: {
        currencyCode: 'RUB',
        add: {
          products: formatProducts(products),
        },
      },
    });
  }
};

/**
 * @description отслеживанием удаление товара из корзины
 * @param products
 */
const trackRemoveFromCart = (products: IProductData[]) => {
  if (isProduction()) {
    localWindow.dataLayer.push({
      ecommerce: {
        currencyCode: 'RUB',
        remove: {
          products: formatProducts(products),
        },
      },
    });
  }
};
/**
 * @description отслеживание создания заказа
 * @param products
 * @param createdOrderResponse
 */
const trackCreateOrder = (products: IProductData[], createdOrderResponse: IOrderCreateResponse) => {
  if (isProduction()) {
    const { number } = createdOrderResponse;
    localWindow.dataLayer.push({
      ecommerce: {
        currencyCode: 'RUB',
        purchase: {
          actionField: {
            id: number,
          },
          products: formatProducts(products),
        },
      },
    });
  }
};

export const YandexMetric = {
  trackAddToCart,
  trackRemoveFromCart,
  trackCreateOrder,
};
