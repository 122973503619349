import React from 'react';
import { IAppLinkProps } from './AppLink.types';
import './AppLink.scss';
import { useNavigate } from 'react-router-dom';

/**
 *@description Компонент ссылки
 * @param props
 * @param props.isNested - если ссылка внутри приложения
 * @param props.link - ссылка строкой
 * @param props.isGoBack - 'вернуться назад'
 * @param props.text - текст
 * @param props.target - где открыть
 */
const AppLink = (props: IAppLinkProps) => {
  const {
    children,
    isNested,
    href,
    link,
    isGoBack,
    type = 'base',
    target = '_self',
    className,
    style,
  } = props;
  const navigate = useNavigate();
  /**
   *
   * @param e
   * @param currentTarget
   */
  const onLinkClick = (
    e: React.MouseEvent<HTMLAnchorElement>,
    currentTarget?: React.HTMLAttributeAnchorTarget,
  ) => {
    if (!href) {
      e.preventDefault();
    }
    if (isGoBack) {
      navigate(-1);
    }

    const isTargetBlank =
      (e.ctrlKey && e.type === 'click') || currentTarget === '_blank' ? '_blank' : '';
    if (!isNested || isTargetBlank) {
      window.open(link, isTargetBlank || target);
    } else {
      navigate(link);
    }
  };

  /**
   * Клик по средней кнопке открывает ссылку в новой вкладке
   *
   * @param e
   */
  const onAuxLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (e.button === 1) {
      onLinkClick(e, '_blank');
    }
  };

  return (
    <div className={`app-ui-link ${className || ''} ${type}`} style={style}>
      <a target={target} href={href || link} onClick={onLinkClick} onAuxClick={onAuxLinkClick}>
        {children}
      </a>
    </div>
  );
};

export default AppLink;
