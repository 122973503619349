import { configureStore } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import { rootReducer } from './rootReducer';

export const store = configureStore({
  reducer: rootReducer,
  /**
   * @description Функция, возвращающая middleware по умолчанию
   * @param getDefaultMiddleware - Функция, возвращающая middleware по умолчанию.
   * @returns - Массив middleware.
   */
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
export const persistor = persistStore(store);

export type TRootState = ReturnType<typeof store.getState>;
export type TAppDispatch = typeof store.dispatch;
