import * as Sentry from '@sentry/react';

/** */
const initSentry = () => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY,
    tracesSampleRate: 1, //флаг, указывающий на то, что 100% ошибок будет отправлено в сентри
    replaysSessionSampleRate: 0.1, //записывать 10% сессий пользователей в видеоподобном формате (replay)
    replaysOnErrorSampleRate: 1.0, //записывать 100% скринкастов при возникновении ошибок (replay)
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    environment: process.env.REACT_APP_ENV, //среда разработки (названия сред тоже прописываются настройках sentry)
  });
};

export { Sentry, initSentry };
