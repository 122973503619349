import { $HttpClient } from '@shared/api/';
import { IMeta } from '@shared/models/meta';
import { Endpoints } from '@shared/constants/endpoints';

/**
 * MetaProvider общие запросы
 */

const MetaProvider = {
  /**
   * @description - получаем константы приложения
   */
  loadConstants: () => {
    return $HttpClient.post<IMeta>(Endpoints.CONSTANTS);
  },
};

export default MetaProvider;
