import React, { Fragment } from 'react';
import './AppVerticalScrollList.scss';
import { IAppVerticalScrollList } from './AppVerticalScrollList.types';

/**
 * @description - рендерит любые элементы в столбик со скроллом в вертикаль
 * @param props
 */
const AppVerticalScrollList = <T,>(props: IAppVerticalScrollList<T>) => {
  const {
    list,
    renderItem,
    itemKey,
    className,
    itemClassName,
    onScroll,
    paddingBottom = 20,
  } = props;

  /**
   * @description - возвращает ключ для элемента
   * @param item
   * @param key
   */
  const getItemKey = (item: T, key: string | string[]) => {
    if (Array.isArray(key)) {
      return key.map((k) => item[k]).join('-');
    }
    if (typeof key === 'string') {
      return item?.[key];
    }
  };
  return (
    <div
      className={`app-vertical-scroll-list ${className || ''}`}
      onScroll={onScroll}
      style={{ paddingBottom }}
    >
      {list?.map((item, index) => (
        <div
          className={`app-vertical-scroll-list__item ${itemClassName || ''}`}
          key={getItemKey(item, itemKey) || index}
        >
          <Fragment key={getItemKey(item, itemKey) || index}>
            {renderItem({ ...(item || {}) }, index)}
          </Fragment>
        </div>
      ))}
    </div>
  );
};

export default AppVerticalScrollList;
