import React from 'react';
import { DeliveryPointInfoCard } from '../DeliveryPointInfoCard';
import './DeliveryPointInfoBalloon.scss';
import { IDeliveryPointInfoCardProps } from '@shared/models/delivery';

/**
 * @description - карточка информации о пункте выдаче
 * @param props
 */
const DeliveryPointInfoBalloon = (props: IDeliveryPointInfoCardProps) => {
  const { ...restProps } = props;

  return (
    <div className={'delivery-point-info-balloon'}>
      <DeliveryPointInfoCard {...restProps} />
    </div>
  );
};

export default DeliveryPointInfoBalloon;
