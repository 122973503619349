import React from 'react';
import { Skeleton } from 'antd';
import './AppSkeleton.scss';
import { IAppSkeletonProps } from './AppSkeleton.types';

/**
 *
 * @param props
 */
const AppSkeleton = (props: IAppSkeletonProps) => {
  const { height = '100%', width = '100%', skeletonStyles, className, ...restProps } = props;

  return (
    <Skeleton.Button
      className={`app-skeleton ${className}`}
      style={{ width, height, borderRadius: 24, ...skeletonStyles }}
      active
      {...restProps}
    />
  );
};

export default AppSkeleton;
