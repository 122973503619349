import React, { FC } from 'react';
import './NavbarDesktop.scss';
import { IDesktopNavbarProps } from './NavbarDesktop.types';
import { NavbarDropdown } from '../NavbarDropdown';
import { ScoreBalance } from '@entities/score';
import { CartNavigateButton } from '@entities/cart';

/**
 *
 * @param props
 */
const NavbarDesktop: FC<IDesktopNavbarProps> = (props) => {
  const { userName, email, score, onPersonInfoOptionClick, isEmptyCart } = props;

  return (
    <div className={'desktop-navbar'}>
      <ScoreBalance score={score} isWithPostWord />
      <div className={'desktop-navbar__right'}>
        <CartNavigateButton isEmptyCart={isEmptyCart} />
        <NavbarDropdown
          userName={userName}
          userEmail={email}
          className={'dropdown-select-margin-top'}
          onClick={onPersonInfoOptionClick}
        />
      </div>
    </div>
  );
};

export default NavbarDesktop;
