import { recoveryResponseImg } from '../../assets';
import './AuthRecoveryFormResponse.scss';
import { AppText } from '@ui-kit';

/** */
const AuthRecoveryFormResponse = () => {
  return (
    <div className={'recovery-response'}>
      <img className={'recovery-response__img'} src={recoveryResponseImg} alt="responseImg" />
      <div>
        <AppText
          text={'Мы отправили вам инструкцию для восстановления доступа'}
          fontType={'gotham'}
          className={'recovery-response__description'}
        />
      </div>
    </div>
  );
};

export default AuthRecoveryFormResponse;
