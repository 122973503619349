import React from 'react';
import { Navigate } from 'react-router-dom';
import { PRIVATE_ROUTES } from '@shared/constants/routes';

/**
 * @description - в будущем сделать страницу ошибки. пока что просто редиректим
 */
const ErrorPage = () => {
  // return <h1>404</h1>
  return <Navigate to={PRIVATE_ROUTES.HOME} />;
};

export default ErrorPage;
