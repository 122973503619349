import React, { FC } from 'react';
import { AppText } from '@ui-kit';
import { COLOR_BLACK, COLOR_GRAY_MAX } from '@shared/constants/colors';
import './LabelValue.scss';
import { ILabelValueProps } from './LabelValue.types';
/**
 * лейбл с значением
 *
 * @param props
 */
const LabelValue: FC<ILabelValueProps> = (props) => {
  const {
    label,
    children,
    value,
    isFullWidth = true,
    direction = 'vertical',
    labelConfig,
    valueConfig,
    className = '',
  } = props;
  return (
    <div className={`label-value ${direction} ${className} ${isFullWidth ? 'full' : ''}`}>
      <AppText text={label} color={COLOR_GRAY_MAX} {...labelConfig} />
      {children || <AppText text={value} color={COLOR_BLACK} {...valueConfig} />}
    </div>
  );
};
export default LabelValue;
