import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TAsyncState } from '@shared/models/async-state';
import { TFetchStatus } from '@shared/constants/fetch';
import { IErrorResponse } from '@shared/models/errors';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { IUser, loadUserAction } from '@entities/user';
import { logoutAction } from '@entities/auth';

interface IUserState extends TAsyncState {
  patchUserStatus: TFetchStatus;
  patchUserError: IErrorResponse;
  data: IUser;
}

const initialState: IUserState = {
  status: 'idle',
  error: null,
  data: {
    firstName: '',
    lastName: '',
    patronymic: '',
    email: '',
    phone: '',
    bonus: null,
    status: 'client',
  },
  patchUserError: null,
  patchUserStatus: 'idle',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearPatchUserError(state) {
      state.patchUserError = null;
    },
    setUser(state, action: PayloadAction<Partial<IUser>>) {
      state.data = { ...initialState.data, ...action.payload };
    },
  },
  /**
   *
   * @param builder
   */
  extraReducers: (builder) => {
    builder
      .addCase(loadUserAction.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(loadUserAction.fulfilled, (state, { payload }) => {
        state.status = 'fulfilled';
        state.data = payload;
      })
      .addCase(loadUserAction.rejected, (state, { error }) => {
        state.status = 'rejected';
        state.error = error;
      });

    builder.addCase(logoutAction, () => {
      return initialState;
    });
  },
});
export const { setUser, clearPatchUserError } = userSlice.actions;

export const userReducer = userSlice.reducer;

export const userPersistConfig = {
  key: 'biorich::user',
  storage,
  version: 1,
};

export const persistedUserReducer = persistReducer(userPersistConfig, userReducer);
