import React from 'react';
import { AppEmptyData } from '@ui-kit';
import './PaymentOrderError.scss';
import { PRIVATE_ROUTES } from '@shared/constants/routes';
import { useNavigate } from 'react-router-dom';
import { ErrorSmilePng } from '@shared/assets/images';
import { IPaymentOrderErrorProps } from './PaymentOrderError.types';

/**
 * @description Блок ошибки при оплате
 * @param props
 */
const PaymentOrderError = (props: IPaymentOrderErrorProps) => {
  const { title, description, buttonText, onClick } = props;
  const navigate = useNavigate();

  /** */
  const onMainReturnClick = () => {
    if (onClick) {
      onClick?.();
    } else {
      navigate(PRIVATE_ROUTES.HOME);
    }
  };

  return (
    <div className={'payment-order-error-empty'}>
      <img src={ErrorSmilePng} alt={'payment-error'} />
      <AppEmptyData
        title={title || 'Что-то пошло не так'}
        buttonText={buttonText || 'Вернуться на главную'}
        description={description || 'К сожалению Ваша оплата не прошла'}
        onButtonClick={onMainReturnClick}
      />
    </div>
  );
};

export default PaymentOrderError;
