import React, { FC, useMemo } from 'react';
import { Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from '@shared/constants/routes';
import {
  CartPage,
  ErrorPage,
  HistoryPage,
  HomePage,
  LoginPage,
  OrderStatusPage,
  PublicCartPage,
  RecoveryPage,
  RegistrationPage,
  ResetPasswordPage,
} from '@pages';
import PrivateRoutesResolver from '@app/router/PrivateRoutesResolver';
import PublicToPrivateRoutesResolver from '@app/router/PublicToPrivateRoutesResolver';
import { PersonalLayout } from '@widgets/layouts/PersonalLayout';
import PromoSlider from '@widgets/promo/PromoSlider';
import { ScoreInfo } from '@widgets/score/ScoreInfo';
import { PublicLayout } from '@widgets/layouts/PublicLayout';
import ReferralLinkPage from '@pages/public/ReferralLinkPage/ReferralLinkpage';
import { ErrorBoundary } from '@frontend-modules/error-handler';

/**
 * @description - Основной компонент маршрутизации
 */
export const Router: FC = () => {
  const location = useLocation();
  const isShowUserOfferInfo = useMemo(
    () => location.pathname === PRIVATE_ROUTES.HOME || location.pathname === PRIVATE_ROUTES.SCORE,
    [location],
  );
  return (
    <Routes>
      <Route
        element={
          <PublicToPrivateRoutesResolver to={PRIVATE_ROUTES.HOME}>
            <ErrorBoundary
              key={'PUBLIC_ROUTES'}
              errorMessage="PUBLIC_ROUTES has crashed"
              showAlertReportBtn={false}
            >
              <Outlet />
            </ErrorBoundary>
          </PublicToPrivateRoutesResolver>
        }
      >
        <>
          {/*/!* Публичные маршруты *!/*/}
          <Route path={PUBLIC_ROUTES.AUTHORIZATION} element={<LoginPage />} />
          <Route path={PUBLIC_ROUTES.REGISTRATION} element={<RegistrationPage />} />
          <Route path={PUBLIC_ROUTES.RECOVERY} element={<RecoveryPage />} />
          <Route path={PUBLIC_ROUTES.RESET_PASSWORD} element={<ResetPasswordPage />} />
          <Route path={PUBLIC_ROUTES.ERROR} element={<ErrorPage />} />
          <Route
            path={`${PUBLIC_ROUTES.REFERRAL_LINK_PAGE}/:referralCode`}
            element={<ReferralLinkPage />}
          />
        </>
      </Route>

      {/* Публичные маршршуты, которые имеют приватный аналог*/}
      <Route
        path={PUBLIC_ROUTES.PUBLIC_CART}
        element={
          <PublicToPrivateRoutesResolver to={PRIVATE_ROUTES.CART}>
            <ErrorBoundary
              key={'PublicCartPage'}
              errorMessage="PublicCartPage has crashed"
              showAlertReportBtn={false}
            >
              <PublicLayout>
                <PublicCartPage />
              </PublicLayout>
            </ErrorBoundary>
          </PublicToPrivateRoutesResolver>
        }
      />
      <Route
        path={`${PUBLIC_ROUTES.PUBLIC_CART}/:orderId`}
        element={
          <PublicToPrivateRoutesResolver to={`${PRIVATE_ROUTES.CART}/:orderId`}>
            <PublicLayout>
              <ErrorBoundary
                key={'OrderStatusPage'}
                errorMessage="OrderStatusPage has crashed"
                showAlertReportBtn={false}
              >
                <OrderStatusPage />
              </ErrorBoundary>
            </PublicLayout>
          </PublicToPrivateRoutesResolver>
        }
      />

      {/* Приватные маршруты, которые требует аутентификации */}
      <Route
        path={PRIVATE_ROUTES.HOME}
        element={
          <PrivateRoutesResolver>
            <PersonalLayout
              leftBlock={
                isShowUserOfferInfo && (
                  <ErrorBoundary
                    key={'ScoreInfo'}
                    errorMessage="ScoreInfo has crashed"
                    showAlertReportBtn={false}
                  >
                    <ScoreInfo />
                  </ErrorBoundary>
                )
              }
              rightBlock={
                isShowUserOfferInfo && (
                  <ErrorBoundary
                    key={'PromoSlider'}
                    errorMessage="PromoSlider has crashed"
                    showAlertReportBtn={false}
                  >
                    <PromoSlider />
                  </ErrorBoundary>
                )
              }
            >
              <ErrorBoundary
                key={'PRIVATE_ROUTES'}
                errorMessage="PRIVATE_ROUTES has crashed"
                showAlertReportBtn={false}
                fallbackContainerStyle={{ height: isShowUserOfferInfo ? '5vh' : '38vh' }}
              >
                <Outlet />
              </ErrorBoundary>
            </PersonalLayout>
          </PrivateRoutesResolver>
        }
      >
        <Route index element={<HomePage />} />
        {/*<Route path={PRIVATE_ROUTES.SCORE} element={<ScorePage />} />*/}
        <Route path={PRIVATE_ROUTES.HISTORY} element={<HistoryPage />} />
        {/*<Route path={PRIVATE_ROUTES.SETTINGS} element={<SettingsPage />} />*/}
        <Route path={PRIVATE_ROUTES.CART} element={<CartPage />} />
        <Route path={`${PRIVATE_ROUTES.CART}/:orderId`} element={<OrderStatusPage />} />
      </Route>
    </Routes>
  );
};
