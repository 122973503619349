import { AppToast, IAppToastProps } from '@ui-kit';
import { notification } from 'antd';

/**
 * @description - toast на основе antd
 * @param config
 */
const showToast = (config: IAppToastProps) => {
  notification.open(AppToast({ ...config }));
};

const closeToast = (message: string) => {
  notification?.destroy(message);
};

/**
 * @description - success toast
 * @param config
 */
const showSuccessToast = (config: Pick<IAppToastProps, 'message' | 'description'>) => {
  showToast({ ...config, type: 'success' });
};

/**
 * @description - error toast
 * @param config
 */
const showErrorToast = (config: Pick<IAppToastProps, 'message' | 'description'>) => {
  showToast({ ...config, type: 'error' });
};

/**
 * @description - telegram toast
 * @param config
 */
const showTelegramToast = (config: Pick<IAppToastProps, 'message' | 'description'>) => {
  showToast({ ...config, type: 'telegram' });
};

/**
 * @description - info toast
 * @param config
 */
const showInfoToast = (config: Pick<IAppToastProps, 'message' | 'description'>) => {
  showToast({ ...config, type: 'info' });
};

export {
  showErrorToast,
  closeToast,
  showTelegramToast,
  showSuccessToast,
  showToast,
  showInfoToast,
};
