import React from 'react';
import { AppButton } from '@ui-kit';
import { replaceProductsInCart } from '@entities/cart';
import { useNavigate } from 'react-router-dom';
import { PRIVATE_ROUTES } from '@shared/constants/routes';
import { IRepeatOrderButtonProps } from './RepeatOrderButton.types';
import { YandexMetric } from '@entities/yandex';

/**
 * @description - кнопка повтора заказа
 * @param props
 */
const RepeatOrderButton = (props: IRepeatOrderButtonProps) => {
  const { onClick, productList } = props;

  const navigate = useNavigate();

  /** */
  const onRepeatOrderClick = () => {
    const cartItemsForReplace = productList?.map((item) => {
      return {
        id: item.externalId,
        count: item.count,
      };
    });
    const cartItemsForMetric = productList?.map((item) => {
      return {
        id: item.externalId,
        ...item,
      };
    });
    replaceProductsInCart(cartItemsForReplace);
    YandexMetric.trackAddToCart(cartItemsForMetric);
    navigate(PRIVATE_ROUTES.CART);
    onClick?.();
  };

  return (
    <AppButton
      onClick={onRepeatOrderClick}
      iconSize={20}
      iconLeft={'return'}
      text={'Повторить заказ'}
      textType={'body'}
      type={'link'}
    />
  );
};

export default RepeatOrderButton;
