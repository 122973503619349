import { getDeliveryIcon } from '@entities/delivery';
import { PIN_SIZE } from '../config/Placemarks';
import './createPlacemarkFactory.scss';

/**
 * @description - фабрика по созданию placemarks
 * @param ymaps
 */
export const createPlacemarkFactory = (ymaps) => {
  return (props) => {
    const { onClick, isActive, type, isDefaultOpened } = props;
    const image = getDeliveryIcon(type);
    const layout = ymaps.templateLayoutFactory.createClass(
      `<div class="pin-container">
                <img src="${image}" alt="Cdek" style="min-width: 52px; height: 52px;"  class="custom-marker-img" />
              </div>
             `,
      {
        // eslint-disable-next-line complexity
        /** */
        build() {
          layout.superclass.build.call(this);

          // GET ELEMENTS

          // const pinContainer = this.getParentElement().getElementsByClassName('pin-container')[0];

          // INITIAL STATE LAYOUT
          const pinSize = PIN_SIZE;
          if (isActive) {
            setTimeout(() => {
              // pinContainer.style.transform = `translate(-${0}px, -${2}px)`;
              // pinContainer.style.border = '1px solid black';
            });
          }
          // HOVER LAYOUT

          this.getData().geoObject.events.add(
            'mouseenter',
            () => {
              // pinContainer.style.border = '1px solid red';
              // backgroundElement.style.top = `-${PIN_EXPANDED_INSET}px`;
              // backgroundElement.style.bottom = `-${PIN_EXPANDED_INSET}px`;
              // backgroundElement.style.left = `-${PIN_EXPANDED_INSET}px`;
              // backgroundElement.style.right = `-${PIN_EXPANDED_INSET}px`;
            },
            this,
          );

          this.getData().geoObject.events.add(
            'mouseleave',
            () => {
              if (!isActive) {
                // pinContainer.style.border = 'none';
                // backgroundElement.style.top = '0px';
                // backgroundElement.style.bottom = '0px';
                // backgroundElement.style.left = '0px';
                // backgroundElement.style.right = '0px';
              }
            },
            this,
          );
          // TOUCHABLE ZONE SHAPE
          this.getData().options.set('shape', {
            type: 'Circle',
            coordinates: [pinSize / 2, pinSize / 2],
            radius: pinSize / 2,
          });

          this.getData().geoObject.events.types.click = [];
          this.getData().geoObject.events.add('click', onClick, this);

          if (isDefaultOpened) {
            this.getData().geoObject.events.fire('click', this);
          }
        },
      },
    );
    return layout;
  };
};
