import React, { useEffect, useState } from 'react';
import { Collapse, ConfigProvider } from 'antd';
import { IAppCollapseProps } from './AppCollapse.types';
import { AppButton } from '@ui-kit';
import { COLOR_GRAY_MAX } from '@shared/constants/colors';
import './AppCollapse.scss';

/**
 *
 * @param props
 */
const AppCollapse = (props: IAppCollapseProps) => {
  const {
    children,
    expandIconPosition = 'end',
    onClick,
    isCollapsed,
    activeKey,
    ...restProps
  } = props;

  const [isCollapse, setIsCollapse] = useState(false);

  const onCollapseClick = (key: string | string[]) => {
    setIsCollapse(!isCollapse);
    onClick?.(!isCollapse, key);
  };

  useEffect(() => {
    setIsCollapse(isCollapsed);
  }, []);

  return (
    <ConfigProvider
      theme={{
        components: {
          Collapse: {
            contentBg: 'transparent',
            headerBg: 'transparent',
            colorBorder: 'transparent',
            padding: 24,
            headerPadding: `${!isCollapse ? '0 0 17px 0' : 0}`,
            contentPadding: '0',
          },
        },
      }}
    >
      <Collapse
        defaultActiveKey={[activeKey || isCollapsed ? '' : '1']}
        expandIcon={() => (
          <AppButton
            iconColor={COLOR_GRAY_MAX}
            size={'small'}
            iconSize={18}
            icon={isCollapse ? 'arrow-full-up' : 'arrow-full-down'}
          />
        )}
        {...restProps}
        expandIconPosition={expandIconPosition}
        onChange={onCollapseClick}
      />
    </ConfigProvider>
  );
};

export default AppCollapse;
