import React, { FC, useEffect, useState } from 'react';
import './ChangeAmountProductsInCart.scss';
import { IAmountProductsControllerProps } from './ChangeAmountProductsInCart.types';
import { AppButton, AppText } from '@ui-kit';
import { changeCountProductInCart } from '@entities/cart';
import { YandexMetric } from '@entities/yandex';

/**
 *
 * @param props
 */
const ChangeAmountProductsInCart: FC<IAmountProductsControllerProps> = (props) => {
  const { product, onChange } = props;
  const { count, externalId: productId } = product;
  const [localCount, setLocalCount] = useState(count);

  /** */
  const onIncreaseItemClick = () => {
    setLocalCount((prevState) => prevState + 1);
    YandexMetric.trackAddToCart([{ ...product, count: 1 }]);
  };

  const onDecreaseItemClick = () => {
    setLocalCount((prevState) => prevState - 1);
    YandexMetric.trackRemoveFromCart([{ ...product, count: 1 }]);
  };

  useEffect(() => {
    if (localCount !== count) {
      changeCountProductInCart(+productId, localCount);
      onChange?.(productId, localCount);
    }
  }, [localCount]);

  return (
    <div className={'amount-products-controller'}>
      <AppButton
        className={'slick-prev'}
        icon={'arrow-full-left'}
        type={'grayMax'}
        iconSize={20}
        onClick={onDecreaseItemClick}
        disabled={localCount === 1}
      />
      <AppText text={localCount} />
      <AppButton
        className={'slick-prev'}
        icon={'arrow-full-right'}
        type={'grayMax'}
        iconSize={20}
        onClick={onIncreaseItemClick}
      />
    </div>
  );
};

export default ChangeAmountProductsInCart;
