import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TAsyncState } from '@shared/models/async-state';
import { TFetchStatus } from '@shared/constants/fetch';
import { IErrorResponse } from '@shared/models/errors';
import { logoutAction } from './authActions';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

interface IAuthState extends TAsyncState {
  isAuthStatus: boolean;
  patchAuthStatus: TFetchStatus;
  patchAuthError: IErrorResponse;
}

const initialState: IAuthState = {
  status: 'idle',
  isAuthStatus: false,
  error: null,
  patchAuthError: null,
  patchAuthStatus: 'idle',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth(state, action: PayloadAction<{ isAuthStatus: boolean }>) {
      const { isAuthStatus } = action.payload;
      state.isAuthStatus = isAuthStatus;
    },
    clearPatchAuthError(state) {
      state.patchAuthError = null;
    },
  },
  /**
   *
   * @param builder
   */
  extraReducers: (builder) => {
    builder.addCase(logoutAction, () => {
      return initialState;
    });
  },
});

export const { setAuth } = authSlice.actions;
export const authReducer = authSlice.reducer;

export const authPersistConfig = {
  key: 'biorich::auth',
  storage,
  version: 1,
  whitelist: ['isAuthStatus'],
};

export const persistedAuthReducer = persistReducer(authPersistConfig, authReducer);
