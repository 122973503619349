import { EXTERNAL_ROUTES, PRIVATE_ROUTES } from '@shared/constants/routes';

export const sidebarItems = [
  {
    label: 'Главная',
    route: PRIVATE_ROUTES.HOME,
    key: PRIVATE_ROUTES.HOME,
    iconName: 'home',
  },
  // { label: 'Баллы', route: PRIVATE_ROUTES.SCORE, key: PRIVATE_ROUTES.SCORE, iconName: 'score' },
  {
    label: 'История',
    route: PRIVATE_ROUTES.HISTORY,
    key: PRIVATE_ROUTES.HISTORY,
    iconName: 'history',
  },
  {
    label: 'Продукты',
    route: EXTERNAL_ROUTES.PRODUCTS,
    key: EXTERNAL_ROUTES.PRODUCTS,
    iconName: 'market',
    isExternal: true,
  },
  // {
  //   label: 'Настройки',
  //   route: PRIVATE_ROUTES.SETTINGS,
  //   key: PRIVATE_ROUTES.SETTINGS,
  //   iconName: 'settings',
  // },
];
