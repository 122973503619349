import {
  IDeliveryCalculation,
  TDeliverySourceTypes,
  TDeliveryTypes,
} from '@shared/models/delivery';
import { getNoun, trimSeparator } from '@frontend-modules/frontend-utils';
import { CdekIcoSvg, RPIcoSvg, YandexIcoSvg } from '@entities/delivery/assets';

/**
 * @description - название доставки
 * @param source
 * @param type
 */
export const getDeliveryName = (source: TDeliverySourceTypes, type?: TDeliveryTypes) => {
  if (type === 'express') return 'Экспресс доставка';
  if (source === 'cdek') return 'СДЭК';
  if (source === 'post_russia') return 'Почта России';
  if (source === 'yandex') return 'Яндекс доставка';
};
/**
 * @description - иконка доставки
 * @param source
 */
export const getDeliveryIcon = (source: TDeliverySourceTypes) => {
  if (source === 'cdek') return CdekIcoSvg;
  if (source === 'post_russia') return RPIcoSvg;
  if (source === 'yandex') return YandexIcoSvg;
};

/**
 * @description - какой вид доставки конкретно
 * @param type
 */
export const getDeliveryTypeName = (type: TDeliveryTypes) => {
  if (type === 'express') return 'Курьер';
  if (type === 'to_door') return 'До двери';
  if (type === 'pvz') return 'Пункт выдачи заказов';
  if (type === 'postamat') return 'Постамат';
};

/**
 * @description         - форматирование в текст даты доставки
 * @param deliveryData
 */
export const getDeliveryDate = (deliveryData: IDeliveryCalculation) => {
  if (deliveryData) {
    const { daysMin, daysMax } = deliveryData;
    const noun = getNoun(daysMax, 'день', 'дня', 'дней');
    if (!daysMin || daysMin === daysMax) return `${daysMax} ${noun}`;
    return `${daysMin}-${daysMax} ${noun}`;
  }
};

/**
 * Проверка на бесплатную доставку
 *
 * @param deliveryAmount
 */
export const renderDeliveryAmount = (deliveryAmount: number) => {
  if (deliveryAmount > 0) {
    return `${trimSeparator(deliveryAmount)} руб.`;
  }
  return 'Бесплатно';
};

export const getDeliveryTypeIcon = (source: TDeliverySourceTypes, type: TDeliveryTypes) => {
  return type === 'postamat' ? 'postamat' : source === 'cdek' ? 'sdek' : 'physic-mail';
};
