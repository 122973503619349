import './App.css';
import { Router } from '@app/router/Router';
import YandexProvider from '@app/yandex/YandexProvider';
import MetaProvider from '@app/meta/MetaProvider';
import TechSupportProvider from '@app/techSupport/TechSupportProvider';

/** */
const App = () => {
  return (
    <div className="App">
      <TechSupportProvider>
        <MetaProvider>
          <YandexProvider>
            <Router />
          </YandexProvider>
        </MetaProvider>
      </TechSupportProvider>
    </div>
  );
};
export default App;
