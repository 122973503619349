import { AppButton, AppLink, AppText, LabelValue } from '@ui-kit';
import {
  COLOR_BLACK,
  COLOR_GRAY_MAX,
  COLOR_GRAY_MEDIUM,
  COLOR_SUCCESS,
} from '@shared/constants/colors';
import { IScoreInfoCardProps, ScoreBalance } from '@entities/score';
import { formatDate, getNoun } from '@frontend-modules/frontend-utils';
import { IUserScoreInfoData, IUserStatusLabel } from '@shared/models/score';
import { getDaysToDate } from '@shared/utils/date';
import { PRIVATE_ROUTES } from '@shared/constants/routes';

/**
 *функция для создания компонентов в карточке тарифа
 *
 * @param userStatusData
 * @param isAdaptive
 * @param onFooterButtonClick
 */
export const clientCardComponents = (
  userStatusData: IUserScoreInfoData,
  isAdaptive: boolean,
  onFooterButtonClick?: () => void,
): IScoreInfoCardProps => {
  const userStatusLabel: Record<IUserStatusLabel, string> = {
    client: 'Базовый',
    referral: 'Премиум',
  };

  const daysLeftCounter = getDaysToDate(userStatusData.dateReferral);

  const daysLeftCounterText =
    daysLeftCounter === 0
      ? 'Последний день'
      : `Осталось ${daysLeftCounter} ${getNoun(daysLeftCounter, 'день', 'дня', 'дней')}`;

  if (userStatusData.userStatus === 'client') {
    return {
      headerComponents: [
        <LabelValue
          className={'score-info-card-header_param'}
          label={'Тариф'}
          labelConfig={{ color: COLOR_GRAY_MEDIUM }}
        >
          <AppText
            text={userStatusLabel[userStatusData.userStatus]}
            type={'subtitle'}
            fontWeight={400}
          />
        </LabelValue>,
        <LabelValue
          className={'score-info-card-header_param'}
          label={'Бонус'}
          labelConfig={{ color: COLOR_GRAY_MEDIUM }}
        >
          <AppText text={`${userStatusData.myBonus}%`} type={'subtitle'} fontWeight={400} />
        </LabelValue>,
        <LabelValue
          className={'score-info-card-header_param'}
          label={'Баллы'}
          labelConfig={{ color: COLOR_GRAY_MEDIUM }}
        >
          <ScoreBalance score={userStatusData.score} />
        </LabelValue>,
      ],
      textComponent: (
        <div className={'score-info-card-text-content'}>
          <AppText text={userStatusData.bonusInfo} type={'body'} color={COLOR_GRAY_MAX} />
        </div>
      ),
      footerComponent: (
        <div className={'score-info-card-footer_param'}>
          <AppLink isNested link={PRIVATE_ROUTES.CART}>
            <AppButton
              type={'primary'}
              text={'Получить бонус'}
              size={'medium'}
              textType={'body'}
              onClick={onFooterButtonClick}
            />
          </AppLink>
        </div>
      ),
    };
  }
  if (userStatusData.userStatus === 'referral') {
    return {
      headerComponents: [
        <div className={'score-info-card-header_param_referral'}>
          <LabelValue label={'Тариф'} labelConfig={{ color: COLOR_GRAY_MEDIUM }}>
            <AppText
              text={userStatusLabel[userStatusData.userStatus]}
              type={'subtitle'}
              fontWeight={400}
            />
          </LabelValue>
        </div>,
      ],
      contentComponents: [
        <LabelValue
          className={'score-info-card-content_param'}
          label={`${userStatusData.myBonus}%`}
          labelConfig={{ color: COLOR_BLACK, type: 'subtitle', fontWeight: 400 }}
        >
          <AppText text={'баллами за ваши покупки '} type={'small'} color={COLOR_GRAY_MAX} />
        </LabelValue>,
        <>
          {isAdaptive && (
            <LabelValue
              className={`score-info-card-content_param`}
              labelConfig={{ color: COLOR_SUCCESS, type: 'subtitle', fontWeight: 600 }}
              label={daysLeftCounterText}
            >
              <AppText
                text={`Тариф активен: до ${formatDate(userStatusData.dateReferral, 'DD.MM.YYYY')}`}
                type={'small'}
                color={COLOR_GRAY_MAX}
              />
            </LabelValue>
          )}
        </>,
      ],
      footerComponent: !isAdaptive && (
        <LabelValue
          className={`score-info-card-footer_param isReferral`}
          label={daysLeftCounterText}
          labelConfig={{ color: COLOR_SUCCESS, type: 'body', fontWeight: 600 }}
        >
          <AppText
            text={`Тариф активен: до ${formatDate(userStatusData.dateReferral, 'DD.MM.YYYY')}`}
            type={'signature-bold'}
            color={COLOR_GRAY_MAX}
          />
        </LabelValue>
      ),
    };
  }
};
