import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EXTERNAL_ROUTES, PUBLIC_ROUTES } from '@shared/constants/routes';
import './PublicNavbar.scss';
import { AppButton, AppLink, AppMenu, AppText, AppTour } from '@ui-kit';
import { companyLogoWithoutText } from '@shared/assets/images';
import { COLOR_BLACK, COLOR_GRAY_MEDIUM } from '@shared/constants/colors';
import { TPublicNavbarProps } from './PublicNavbar.types';
import { TourProps } from 'antd';
import { publicNavbarItems } from './PublicNavbar.config';
import { useAppSelector } from '@app/store/hooks';

/**
 * @description - Публичный навигационный бар
 * @param props
 */
const PublicNavbar = (props: TPublicNavbarProps) => {
  const { isAdaptive } = props;

  const menuRef = useRef(null);
  const CONTACT_PHONE_NUMBER = useAppSelector((state) => state.meta.data?.phoneOrganization || '-');
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  /** */
  const onCartClick = () => {
    navigate(PUBLIC_ROUTES.PUBLIC_CART);
  };
  const onMobileNavMenuClick = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const menuSteps: TourProps['steps'] = [
    {
      title: '',
      target: menuRef.current,
      className: 'app-ui-tour-description',
      description: (
        <>
          <div className={'app-ui-tour-description-content'}>
            <AppMenu menuItems={publicNavbarItems} onClick={onMobileNavMenuClick} />
          </div>
          <div className={'app-ui-tour-description-footer'}>
            <AppLink isNested href={`tel:+${CONTACT_PHONE_NUMBER}`}>
              <AppText
                text={CONTACT_PHONE_NUMBER}
                color={COLOR_BLACK}
                type={'note'}
                fontType={'codec'}
              />
            </AppLink>
          </div>
        </>
      ),
    },
  ];

  return (
    <nav className={'public-navbar'}>
      <div className={'public-navbar__container'}>
        <div className={'public-navbar__left'}>
          <AppLink link={EXTERNAL_ROUTES.OLD_SITE}>
            <img className={'company-img'} src={companyLogoWithoutText} alt="Biorich" />
          </AppLink>
          {!isAdaptive && (
            <div className="public-navbar__left__links">
              <AppLink target={'_blank'} link={EXTERNAL_ROUTES.PRODUCTS}>
                <AppText fontWeight={400} text={'Продукты'} />
              </AppLink>
              <AppLink target={'_blank'} link={EXTERNAL_ROUTES.ABOUT_COMPANY}>
                <AppText fontWeight={400} text={'О компании'} />
              </AppLink>
            </div>
          )}
        </div>
        <div className={'public-navbar__right'}>
          {isAdaptive ? (
            <>
              <AppButton
                icon={isMobileMenuOpen ? 'close' : 'menu'}
                onClick={onMobileNavMenuClick}
                ref={menuRef}
                size="large"
                className={isMobileMenuOpen ? 'heightZIndex' : ''}
              />
              <AppTour
                scrollIntoViewOptions
                open={isMobileMenuOpen}
                onClose={() => setIsMobileMenuOpen(false)}
                steps={menuSteps}
                arrow={false}
                closeIcon={false}
                placement={'bottomRight'}
              />
            </>
          ) : (
            <>
              <AppButton
                text={'Корзина'}
                fontWeight={400}
                textType={'subtitle'}
                type="primary"
                size={'large'}
                iconLeft={'cart'}
                onClick={onCartClick}
              />
              <div className="public-navbar__right__phone">
                <AppText text={'Телефон'} color={COLOR_GRAY_MEDIUM} />
                <AppLink isNested href={`tel:+${CONTACT_PHONE_NUMBER}`}>
                  <AppText
                    text={CONTACT_PHONE_NUMBER}
                    color={COLOR_BLACK}
                    type={'title-h6'}
                    fontType={'codec'}
                  />
                </AppLink>
              </div>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default PublicNavbar;
