import { useEffect, useState } from 'react';
import { TScreenTypes } from '@shared/models/screen-types';

/** */
const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  /**
   *
   * @param screenTypeValue
   *
   *  - mobile: 480,
   *  - tablet: 1024,
   *  - laptop: 1560,
   *  - desktop: 1920,
   */
  const calculateScreenType = (screenTypeValue: TScreenTypes = 'desktop'): boolean => {
    const screenAdaptive: Record<TScreenTypes, number> = {
      mobile: 639,
      tablet: 1024,
      laptop: 1559,
      desktop: 1920,
    };

    const { width } = screenSize;
    return width <= screenAdaptive[screenTypeValue];
  };

  useEffect(() => {
    /** */
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    screenSize,
    calculateScreenType,
  };
};
export { useScreenSize };
