import { useEffect, useState } from 'react';
import { AppButton, AppPasswordInput, AppText } from '@ui-kit';
import './AuthPasswordResetForm.scss';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IResetValidation } from './AuthPasswordResetForm.types';
import { EMPTY_ERROR, PASSWORD_CONFIRM_ERROR } from '@shared/constants/errors';
import { AuthProvider } from '@entities/auth';
import { PUBLIC_ROUTES } from '@shared/constants/routes';

/** */
const AuthPasswordResetForm = () => {
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [password, setPassword] = useState('');
  const [tokenLocal, setTokenLocal] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [resetValidation, setResetValidation] = useState<IResetValidation | null>(null);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const tokenURl = searchParams.get('token');

    AuthProvider.validateRecoveryToken(tokenURl)
      .then(() => (tokenURl ? setTokenLocal(tokenURl) : navigate(PUBLIC_ROUTES.RECOVERY)))
      .catch(() => navigate(PUBLIC_ROUTES.RECOVERY));
  }, [navigate, searchParams]);

  const onPasswordConfirmChange = (value: string) => {
    setResetValidation((prev) => ({ ...prev, passwordConfirm: null }));
    setPasswordConfirm(value);
  };

  const onPasswordChange = (value: string) => {
    setResetValidation((prev) => ({ ...prev, password: null }));
    setPassword(value);
  };

  /** */
  const onContinueClick = () => {
    if (!password) {
      return setResetValidation((prev) => ({ ...prev, password: [EMPTY_ERROR] }));
    }
    if (password !== passwordConfirm) {
      return setResetValidation((prev) => ({
        ...prev,
        passwordConfirm: [PASSWORD_CONFIRM_ERROR],
      }));
    }
    setIsLoading(true);
    AuthProvider.confirmRecoveryPassword({ password, token: tokenLocal })
      .then(() => {
        navigate(PUBLIC_ROUTES.AUTHORIZATION);
      })
      .catch((e) => {
        setResetValidation(e.response?.data?.extra?.fields);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className={'reset-password-form'}>
      <AppText
        tag={'h2'}
        text={'Сброс пароля'}
        className={'reset-password-form__title'}
        type={'title'}
      />
      <div className={'reset-password-form__controls'}>
        <div className={'reset-password-form__controls__inputs'}>
          <AppPasswordInput
            validationError={resetValidation?.password}
            label={'Новый пароль'}
            isShowPrompt
            onChange={onPasswordChange}
          />
          <AppPasswordInput
            validationError={resetValidation?.passwordConfirm}
            label={'Повторите пароль'}
            onChange={onPasswordConfirmChange}
          />
        </div>
        <AppButton
          loading={isLoading}
          text={'Продолжить'}
          type="primary"
          size={'large'}
          isFull
          onClick={onContinueClick}
        />
      </div>
    </div>
  );
};

export default AuthPasswordResetForm;
