import { getCookie, removeCookie, setCookie } from '@shared/utils/cookies';

const accessTokens = ['access_token'];
const refreshTokens = ['refresh_token'];

export const getAccessToken = () => {
  return accessTokens.map((item) => getCookie(item)).find((item) => !!item);
};

export const setAccessToken = (value: string | number) => {
  return accessTokens.forEach((item) => setCookie(item, value));
};

export const removeAccessToken = () => {
  return accessTokens.forEach((item) => removeCookie(item));
};

export const getRefreshToken = () => {
  return refreshTokens.map((item) => getCookie(item)).find((item) => !!item);
};

export const setRefreshToken = (value: string | number) => {
  return refreshTokens.forEach((item) => setCookie(item, value));
};

export const removeRefreshToken = () => {
  return refreshTokens.forEach((item) => removeCookie(item));
};
