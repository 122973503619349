import React, { FC, useState } from 'react';
import { AppButton, AppModal, AppPhoneInput, AppText, TAppModalProps } from '@ui-kit';
import './CallBackFormModal.scss';
import { COLOR_GRAY_MAX } from '@shared/constants/colors';
import { useAppSelector } from '@app/store/hooks';
import { ServiceProvider } from '@entities/service';
import { showSuccessToast } from '@shared/utils/toast';

/**
 * @description Модалка обратной связи
 */

/**
 *
 * @param props
 */
const CallBackFormModal: FC<TAppModalProps> = (props) => {
  const { open: isOpen, onCancel } = props;

  const userPhoneNumber = useAppSelector((state) => state.user.data.phone);

  const [userPhoneNumberValue, setUserPhoneNumberValue] = useState(
    userPhoneNumber?.replace('+', '') || '',
  );

  const [isLoading, setIsLoading] = useState(false);
  const [callBackFormValidation, setCallBackFormValidation] = useState<string[] | null>(null);

  const onPhoneChange = (value: string) => {
    setCallBackFormValidation(null);
    setUserPhoneNumberValue(value);
  };

  /** */
  const onSendCallBackBtnClick = () => {
    setIsLoading(true);
    ServiceProvider.callBackRequest(userPhoneNumberValue)
      .then(() => {
        onCancel();
        showSuccessToast({ message: 'Заявка принята' });
      })
      .catch((e) => {
        setCallBackFormValidation(e.response?.data?.extra?.fields?.phone);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <AppModal
      open={isOpen}
      onCancel={onCancel}
      title={'Обратный звонок'}
      description={'Оставьте заявку, мы вам перезвоним!'}
    >
      <div className={'callback-form'}>
        <AppPhoneInput
          label={'Номер'}
          onChange={onPhoneChange}
          defaultValue={userPhoneNumber}
          validationError={callBackFormValidation}
        />
        <div className={'callback-form__footer'}>
          <AppButton
            loading={isLoading}
            disabled={!userPhoneNumberValue}
            text={'Отправить'}
            textType={'subtitle'}
            size={'large'}
            type={'primary'}
            isFull
            onClick={onSendCallBackBtnClick}
          />
          <AppText
            text={'Нажимая на кнопку, вы даете согласие на обработку своих персональных данных.'}
            color={COLOR_GRAY_MAX}
            type={'signature-bold'}
          />
        </div>
      </div>
    </AppModal>
  );
};

export default CallBackFormModal;
