import React, { FC } from 'react';
import './AppTour.scss';
import { TAppTourProps } from './AppTour.types';
import { useClickOutside } from '@shared/hooks/useClickOutside';
import { Tour } from 'antd';

/**
 *
 * @param props
 */
const AppTour: FC<TAppTourProps> = (props) => {
  const { open: isOpen, onClose, ...restProps } = props;
  /** */
  const handleClickOutside = () => {
    if (isOpen) {
      onClose?.();
    }
  };
  const ref = useClickOutside(handleClickOutside, ['.ant-tour', '.height-z-index_button']);

  return (
    <div ref={ref}>
      <Tour open={isOpen} {...restProps} />
    </div>
  );
};

export default AppTour;
