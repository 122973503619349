import { useEffect } from 'react';
import { useAppSelector } from '@app/store/hooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { TPrivateRoutesResolver } from '@app/router/PrivateRoutesResolver.types';

/**
 * @description - если авторизирован, то перемещаем на определенную страницу
 * @param props
 * @param props.children
 * @param props.to
 */
const PublicToPrivateRoutesResolver = (props: TPrivateRoutesResolver) => {
  const { children, to } = props;
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthStatus);
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  // Проверка авторизации
  useEffect(() => {
    if (isAuthenticated) {
      const nextParam = new URLSearchParams(location.search).get('next');
      nextParam ? navigate(nextParam) : navigate(to);
    }
  }, [navigate, isAuthenticated, to, params]);

  return !isAuthenticated ? children : null;
};

export default PublicToPrivateRoutesResolver;
