import React, { FC } from 'react';
import IcoMoon from 'react-icomoon';
import iconSet from './selection.json';
import { TAppFontIconProps } from './AppFontIcon.types';
import './AppFontIcon.scss';

/**
 * @description - компонент иконочного шрифта,
 * чтобы посмотреть доступные иконки, нужно открыть файл selection.json на этом сайте
 * https://icomoon.io/app/#/select
 * @param props
 */
const AppFontIcon: FC<TAppFontIconProps> = (props) => {
  const { icon, iconSize, background, style, cursorType = 'default', ...restProps } = props;
  return (
    <div className={`app-ui-font-icon cursor-${cursorType}`}>
      <IcoMoon
        iconSet={iconSet}
        icon={icon}
        size={iconSize || 24}
        style={{ background, ...style }}
        {...restProps}
      />
    </div>
  );
};

export default AppFontIcon;
