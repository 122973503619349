import React, { FC, useEffect, useState } from 'react';
import './PersonalLayout.scss';
import { IPersonalLayoutProps } from './PersonalLayout.types';
import { Navbar, Sidebar } from './ui';
import { useScreenSize } from '@shared/hooks/useScreenSize';
import { useAppDispatch } from '@app/store/hooks';
import { loadUserAction } from '@entities/user';
import { CallBackFormModal } from '@features/service/CallBackFormModal';
import { OrderDetailsModal } from '@widgets/order/OrderDetailsModal';
import { customEventEmitter } from '@frontend-modules/frontend-utils';
import { OBSERVER_EVENT_ORDER_DETAIL_MODAL } from '@entities/order';
import { PublicFooter } from '@widgets/layouts/PublicLayout';

const observer = customEventEmitter.Instance;
/**
 * @description - layout для личного кабинета пользователя
 * от сюда должны лететь все межстраничные ОБЩИЕ запрос.
 * Например, на userInfo
 * @param props
 */
const PersonalLayout: FC<IPersonalLayoutProps> = (props) => {
  const { children: page, leftBlock, rightBlock } = props;

  const [isCallBackFormModalOpen, setIsCallBackFormModalOpen] = useState(false);
  const [orderDetailsModalData, setOrderDetailsModalData] = useState(null);

  const dispatch = useAppDispatch();

  const { calculateScreenType } = useScreenSize();
  const isAdaptive = calculateScreenType('tablet');

  useEffect(() => {
    dispatch(loadUserAction());

    observer.listen(OBSERVER_EVENT_ORDER_DETAIL_MODAL, (order) => {
      setOrderDetailsModalData(order);
    });

    return () => {
      observer.removeListen(OBSERVER_EVENT_ORDER_DETAIL_MODAL);
    };
  }, [dispatch]);

  const onPhoneClick = () => {
    setIsCallBackFormModalOpen(true);
  };

  return (
    <>
      <div className={'personal-layout'}>
        {!isAdaptive && <Sidebar onClick={onPhoneClick} />}
        <div className={'personal-content'}>
          <div className={`personal-content__navbar ${isAdaptive ? 'navbar_sticky' : ''}`}>
            <Navbar isAdaptive={isAdaptive} onPhoneClick={onPhoneClick} />
          </div>

          <div className={'personal-content__page'}>
            {!!leftBlock && !!rightBlock && (
              <div className={`additional-info-layout`}>
                <div className={`additional-info-layout_left`}>{leftBlock}</div>
                <div className={`additional-info-layout_right`}>{rightBlock}</div>
              </div>
            )}
            {page}
            <div className={'personal-content__page-footer'}>
              <PublicFooter />
            </div>
          </div>
        </div>
      </div>
      {isCallBackFormModalOpen && (
        <CallBackFormModal
          open={isCallBackFormModalOpen}
          onCancel={() => setIsCallBackFormModalOpen(false)}
        />
      )}

      {!!orderDetailsModalData && (
        <OrderDetailsModal
          open={!!orderDetailsModalData}
          data={orderDetailsModalData}
          onCancel={() => setOrderDetailsModalData(null)}
        />
      )}
    </>
  );
};
export default PersonalLayout;
