import React from 'react';
import { AppButton, AppLink } from '@ui-kit';
import { COLOR_BLACK, COLOR_DISABLED } from '@shared/constants/colors';
import { PRIVATE_ROUTES } from '@shared/constants/routes';
import './CartNavigationButton.scss';
import { TCartNavigateButtonProps } from './CartNavigateButton.types';

/**
 *
 * @param props
 */
const CartNavigateButton = (props: TCartNavigateButtonProps) => {
  const { onClick, isEmptyCart } = props;
  const onCartClick = () => {
    onClick?.();
  };

  return (
    <div className={'cart-navigation-btn'}>
      <AppLink isNested link={PRIVATE_ROUTES.CART}>
        <>
          <AppButton
            className={`cart-navigation-btn ${!isEmptyCart ? 'active' : ''}`}
            icon={'cart'}
            iconColor={!isEmptyCart ? COLOR_BLACK : COLOR_DISABLED}
            size="large"
            onClick={onCartClick}
          />
          {!isEmptyCart && <div className={'circle'} />}
        </>
      </AppLink>
    </div>
  );
};

export default CartNavigateButton;
