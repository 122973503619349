import React, { useEffect, useState } from 'react';
import { Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import './AppCheckbox.scss';
import { TAppCheckboxProps } from './AppCheckbox.types';
import { AppText } from '@ui-kit';
import { COLOR_BLACK, COLOR_GRAY_MAX } from '@shared/constants/colors';

/**
 + * Компонент чекбокса
 + * @param {string} props.label - Текст метки для чекбокса.
 + * @param {boolean} props.value - Текущее значение чекбокса.
 + * @param {object} props.textProps - Дополнительные свойства для компонента текста метки.
 + * @param {string} [props.type='primary' | 'secondary' | 'disabled] - Тип чекбокса - черный, синий или серый .
 +
 *
 * @param props
 */
const AppCheckbox = (props: TAppCheckboxProps) => {
  const {
    label,
    onChange,
    value,
    textProps,
    disabled,
    checked,
    type = 'primary',
    ...restProps
  } = props;
  const [isLocalChecked, setLocalChecked] = useState(false);

  /**
   *
   * @param e
   */
  const onChangeLocal = (e: CheckboxChangeEvent) => {
    const isNewVal = e.target.checked;
    onChange?.(isNewVal);
    setLocalChecked(isNewVal);
  };
  const localType = disabled ? 'disabled' : type;

  useEffect(() => {
    setLocalChecked(checked);
  }, [checked]);

  return (
    <div className={'app-ui-checkbox'}>
      <Checkbox
        {...restProps}
        checked={isLocalChecked}
        onChange={onChangeLocal}
        className={localType}
        disabled={disabled}
      >
        {typeof label === 'string' ? (
          <AppText
            text={label}
            color={isLocalChecked ? COLOR_BLACK : COLOR_GRAY_MAX}
            {...textProps}
          />
        ) : (
          label
        )}
      </Checkbox>
    </div>
  );
};

export default AppCheckbox;
