import React from 'react';
import { AppText } from '@ui-kit';
import { IAppToastProps } from './AppToast.types';
import './AppToast.scss';
import { iconType } from './AppToast.config';
import { closeToast } from '@shared/utils/toast';

/**
 *
 * @param props
 */
const AppToast = (props: IAppToastProps) => {
  const { message, description, type } = props;

  const removeToast = () => {
    closeToast(message as string);
  };
  return {
    message: (
      <div className="app-ui-toast__message">
        <img src={iconType[type]} alt="" />
        {typeof message === 'string' ? <AppText text={message} type="note-bold" /> : message}
      </div>
    ),
    description: typeof description === 'string' ? <AppText text={description} /> : description,
    className: 'app-ui-toast',
    delay: 3000,
    key: message as string,
    onClick: removeToast,
  };
};

export default AppToast;
