import { useState } from 'react';
import { AppButton, AppInput, AppText } from '@ui-kit';
import './AuthRecoveryForm.scss';
import { EMPTY_ERROR } from '@shared/constants/errors';
import { AuthProvider } from '@entities/auth';
import { AuthRecoveryFormResponse } from './ui';

/** */
const AuthRecoveryForm = () => {
  const [email, setEmail] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emailValidation, setEmailValidation] = useState<string[] | null>(null);

  const onEmailChange = (value: string) => {
    setEmailValidation(null);
    setEmail(value);
  };

  /** */
  const onResetClick = () => {
    if (!email) {
      setEmailValidation([EMPTY_ERROR]);
    } else {
      setIsLoading(true);
      AuthProvider.sendPasswordRecoveryEmail(email)
        .then(() => {
          setIsSuccess(!isSuccess);
        })
        .catch((e) => {
          const error =
            e.response?.data?.extra?.fields?.email ||
            e.response?.data?.extra?.fields?.non_field_errors;
          setEmailValidation(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <div className={'recovery-form'}>
      <AppText
        tag={'h2'}
        text={'Забыли пароль'}
        className={'recovery-form__title'}
        type={'title'}
      />
      <AppText
        text={
          'Для сброса пароля, заполните e-mail в поле ниже, и мы отправим вам инструкцию для восстановлению доступа.'
        }
        fontType={'gotham'}
        className={'recovery-form__subtitle'}
      />
      <div className={'recovery-form__controls'}>
        <AppInput
          label={'E-mail'}
          onChange={onEmailChange}
          type={'email'}
          name={'email'}
          validationError={emailValidation}
        />
        <AppButton
          loading={isLoading}
          text={'Сбросить пароль'}
          size={'large'}
          type="primary"
          isFull
          onClick={onResetClick}
        />
      </div>
      {isSuccess && <AuthRecoveryFormResponse />}
    </div>
  );
};

export default AuthRecoveryForm;
