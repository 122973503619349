import { $HttpClient } from '@shared/api/';
import { IPaymentLinkResponse } from '@shared/models/payment';
import { Endpoints } from '@shared/constants/endpoints';

/**
 * PaymentProvider запросы связанные с корзиной
 */

const PaymentProvider = {
  /**
   * @description получаем ссылку для оплаты заказа
   * @param number
   */
  getPaymentLink: (number: string) => {
    return $HttpClient.post<IPaymentLinkResponse>(Endpoints.ORDERS_PAYMENT, {
      number,
    });
  },
};

export default PaymentProvider;
