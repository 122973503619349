import { ICalculateBonusResponse, ICartParams } from '@shared/models/cart';
import { $HttpClient } from '@shared/api';
import { Endpoints } from '@shared/constants/endpoints';

/**
 * AuthProvider запросы связанные с корзиной
 */
const CartProvider = {
  /**
   * @description - получить расчет бонусов на основе заказа
   * @param params
   * @param params.products
   * @param params.amountDelivery
   * @param params.bonusDebt
   */
  getCartData: (params: ICartParams) => {
    return $HttpClient.post<ICalculateBonusResponse>(Endpoints.CART, {
      ...params,
    });
  },
};

export default CartProvider;
