import React, { useState } from 'react';
import { AppInput, AppText } from '@ui-kit';
import { TAppPasswordInputProps } from './AppPasswordInput.types';
import { COLOR_ORANGE } from '@shared/constants/colors';
import './AppPasswordInput.scss';

/**
 *
 * @param props
 */
const AppPasswordInput = (props: TAppPasswordInputProps) => {
  const { isShowPrompt = false } = props;
  const [isCloseEyeIcon, setIsCloseEyeIcon] = useState(true);

  const onEyeIconClick = () => {
    setIsCloseEyeIcon((prev) => !prev);
  };

  return (
    <div className={'app-ui-password-input'}>
      <AppInput
        onIconClick={onEyeIconClick}
        type={isCloseEyeIcon ? 'password' : 'text'}
        icon={isCloseEyeIcon ? 'eye-close' : 'eye-open'}
        {...props}
      />
      {isShowPrompt && (
        <AppText
          className={'password-prompt'}
          color={COLOR_ORANGE}
          type={'small'}
          text={
            'Пароль должен состоять из 8 или более символов.\n' +
            'Содержать цифры, а также специальные символы («!», «@», «#», «$» и т.д.).'
          }
          fontWeight={400}
        />
      )}
    </div>
  );
};

export default AppPasswordInput;
