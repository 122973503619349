import { Endpoints } from '@shared/constants/endpoints';

export const untraceableApis = [
  Endpoints.ORDERS_CREATE,
  Endpoints.USERS_REGISTER,
  Endpoints.TOKEN,
  Endpoints.PASSWORD_RESET_REQUEST,
  Endpoints.PASSWORD_RESET_CONFIRM,
  Endpoints.CART,
  Endpoints.DELIVERY_POINTS,
  Endpoints.DELIVERY_CALCULATION,
  Endpoints.PRODUCTS,
  Endpoints.CALLBACK,
  Endpoints.SUGGEST,
  Endpoints.MAPS_SEARCH,
];

export const UntraceableApiList = untraceableApis.map((url) => {
  return {
    url,
    statuses: [400],
  };
});
