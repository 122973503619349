import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { PUBLIC_ROUTES } from '@shared/constants/routes';
import { useAppDispatch, useAppSelector } from '@app/store/hooks';
import { TPrivateRoutesResolver } from '@app/router/PrivateRoutesResolver.types';
import { getRefreshToken } from '@shared/utils/token';
import { setAuth } from '@entities/auth';

/**
 * @description - обертка для приватных маршрутов
 * @param props
 * @param props.children
 */
const PrivateRoutesResolver = ({ children }: TPrivateRoutesResolver) => {
  const dispatch = useAppDispatch();

  const isAuthenticated =
    useAppSelector((state) => state.auth.isAuthStatus) && !!getRefreshToken()?.length;
  /**
   * если нет токенов, то сбрасываем авторизацию
   */
  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(setAuth({ isAuthStatus: false }));
    }
  }, [isAuthenticated]);

  // Проверка авторизации
  if (!isAuthenticated) {
    // Перенаправление на страницу авторизации
    return <Navigate to={PUBLIC_ROUTES.AUTHORIZATION} />;
  }

  return <>{children}</>;
};

export default PrivateRoutesResolver;
