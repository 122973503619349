import React from 'react';
import { AppButton } from '@ui-kit';
import { removeProductFromCart } from '@entities/cart';
import { COLOR_GRAY_MAX } from '@shared/constants/colors';
import { IRemoveProductFormCartButtonProps } from './RemoveProductFormCartButton';
import { YandexMetric } from '@entities/yandex';

/**
 * @description - кнопка удаления продукта из корзины
 * @param props
 */
const RemoveProductFromCartButton = (props: IRemoveProductFormCartButtonProps) => {
  const { product, onClick } = props;

  /** */
  const onRemoveClick = () => {
    removeProductFromCart(+product.externalId);
    YandexMetric.trackRemoveFromCart([product]);
    onClick?.(product.externalId);
  };

  return (
    <AppButton
      size={'small'}
      icon={'trash'}
      iconColor={COLOR_GRAY_MAX}
      iconSize={18}
      onClick={onRemoveClick}
    />
  );
};

export default RemoveProductFromCartButton;
