import { $HttpClient } from '@shared/api/';
import {
  IAuthConfirmPasswordResetParams,
  IAuthLoginParams,
  IAuthLoginResponse,
  IAuthRegistrationParams,
} from '@shared/models/auth';
import { Endpoints } from '@shared/constants/endpoints';

/**
 * AuthProvider запросы связанные с авторизацией
 */

const AuthProvider = {
  /**
   * @description login пользователя
   * @param userData
   */
  loginUser: (userData: IAuthLoginParams) => {
    return $HttpClient.post<IAuthLoginResponse>(Endpoints.TOKEN, {
      ...userData,
    });
  },

  /**
   * @description регистрация нового пользователя
   * @param userData
   */

  /**
   *
   * @param userData
   */
  registrationUser: (userData: IAuthRegistrationParams) => {
    return $HttpClient.post(Endpoints.USERS_REGISTER, {
      ...userData,
    });
  },

  /**
   * @description запрос на получение ссылки на почте
   * @param email
   */
  sendPasswordRecoveryEmail: (email: string) => {
    return $HttpClient.post<{ email: string }>(Endpoints.PASSWORD_RESET_REQUEST, {
      email,
    });
  },

  /**
   * @description Подтверждение сброса пароля
   * @param userData
   */
  confirmRecoveryPassword: (userData: IAuthConfirmPasswordResetParams) => {
    return $HttpClient.post(Endpoints.PASSWORD_RESET_CONFIRM, {
      ...userData,
    });
  },

  /**
   * @description Проверка валидности токена
   * @param token
   */
  validateRecoveryToken: (token: string) => {
    return $HttpClient.get(Endpoints.PASSWORD_RESET_VALIDATE, { token });
  },
};

export default AuthProvider;
