import { IProductData } from '@shared/models/product';
import { TCartParams } from '@shared/models/cart';
import { getCookie, setCookie } from '@shared/utils/cookies';

/**
 * description - скрипт для обработки добавления элементов в корзину
 */
export const cookieCartItemsKey = 'cartItems';
const isLocalHost = window.location.href.includes('localhost');
const { host } = window.location;
const cookieDomain = isLocalHost
  ? undefined
  : host.includes('tech') || isLocalHost
  ? 'biorich.tech'
  : 'biorich.com';

/**
 * Функция для получения текущих элементов корзины из Cookie
 *
 */
export const getCartItems = (): TCartParams[] => {
  const storedItems = getCookie(cookieCartItemsKey);
  return storedItems ? JSON.parse(storedItems) : [];
};

/**
 * Функция для сохранения элементов корзины в Cookie
 *
 * @param items
 */
export const saveCartItems = (items: TCartParams[]) => {
  setCookie(cookieCartItemsKey, JSON.stringify(items), {
    domain: cookieDomain,
  });
};

/**
 * @description Функция для обработки кликов по кнопкам добавления в корзину
 * @param productId
 * @param count
 */
export const addProductToCart = (productId: string, count: number) => {
  const cartItems: TCartParams[] = getCartItems().map((item: IProductData) => ({
    id: item.id,
    count: item.count,
  }));
  const existingItem = cartItems.find((item) => item.id === String(productId));
  if (existingItem) {
    existingItem.count += count;
  } else {
    const newItem = {
      id: String(productId),
      count,
    };
    cartItems.push(newItem);
  }
  saveCartItems(cartItems);
};

/**
 * @description - заменяем список продуктов в корзине
 * @param cartItems
 */
export const replaceProductsInCart = (cartItems: Array<{ id: string; count: number }>) => {
  saveCartItems(cartItems);
};

/**
 * @description Функция для получения элемента корзины
 * @param productId
 */
export const findProductInCart = (productId: number) => {
  const cartItems = getCartItems();
  return (
    cartItems.find((product) => String(product.id) === String(productId)) || {
      id: null,
      count: 0,
    }
  );
};

/**
 * @description Функция для обработки кликов по кнопкам удаления
 * @param productId
 */
export const removeProductFromCart = (productId: number) => {
  const cartItems = getCartItems();
  const updatedCartItems = cartItems.filter((item) => item.id !== String(productId));
  saveCartItems(updatedCartItems);
};

/**
 * @description Функция для очистки корзины
 */
export const removeAllProductFromCart = () => {
  saveCartItems([]);
};

/**
 * @description Функция для изменения количества товаров
 * @param productId
 * @param newCount
 */
export const changeCountProductInCart = (productId: number, newCount: number) => {
  const cartItems = getCartItems();
  const targetIndex = cartItems.findIndex((item) => item.id === String(productId));
  cartItems[targetIndex].count = newCount;
  saveCartItems(cartItems);
};
