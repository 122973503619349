import { EXTERNAL_ROUTES, PUBLIC_ROUTES } from '@shared/constants/routes';

export const publicNavbarItems = [
  {
    label: 'Корзина',
    route: PUBLIC_ROUTES.PUBLIC_CART,
    key: PUBLIC_ROUTES.PUBLIC_CART,
    iconName: 'cart',
  },
  {
    label: 'Продукты',
    route: EXTERNAL_ROUTES.PRODUCTS,
    key: EXTERNAL_ROUTES.PRODUCTS,
    iconName: 'market',
  },
  {
    label: 'О компании',
    route: EXTERNAL_ROUTES.ABOUT_COMPANY,
    key: EXTERNAL_ROUTES.ABOUT_COMPANY,
    iconName: 'coin',
  },
];
