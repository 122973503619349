import React, { FC } from 'react';
import { INavbarDropdownProps } from './NavbarDropdown.types';
import './NavbarDropdown.scss';
import { AppDropdownSelect, AppText } from '@ui-kit';
import { COLOR_GRAY_MAX } from '@shared/constants/colors';
import { navbarDropDownOptionsList } from './NavbarDropdown.config';

/**
 *
 * @param props
 */
const NavbarDropdown: FC<INavbarDropdownProps> = (props) => {
  const { userName, userEmail, onClick, className, isAdaptive } = props;

  return (
    <div className={`${isAdaptive ? 'limited-width' : ''} person-info`}>
      <AppDropdownSelect
        label={userName || '-'}
        className={className}
        onOptionClick={onClick}
        options={navbarDropDownOptionsList}
        placement={isAdaptive ? 'bottom' : 'bottomRight'}
      />
      <AppText
        text={userEmail || '@mail.ru'}
        className={'ellipses'}
        fontType={'averta'}
        color={COLOR_GRAY_MAX}
      />
    </div>
  );
};

export default NavbarDropdown;
