import {
  COLOR_BLUE,
  COLOR_ERROR,
  COLOR_LIGHT_BLUE,
  COLOR_LIGHT_ERROR,
  COLOR_LIGHT_SUCCESS,
  COLOR_SUCCESS,
} from '@shared/constants/colors';

export const orderTagStatus = {
  completed: {
    color: COLOR_SUCCESS,
    backgroundColor: COLOR_LIGHT_SUCCESS,
    text: 'Доставлен',
  },
  new: {
    color: COLOR_BLUE,
    backgroundColor: COLOR_LIGHT_BLUE,
    text: 'Создан',
  },
  approval: {
    color: COLOR_BLUE,
    backgroundColor: COLOR_LIGHT_BLUE,
    text: 'Обрабатывается',
  },
  await_payment: {
    color: COLOR_BLUE,
    backgroundColor: COLOR_LIGHT_BLUE,
    text: 'Обработка платежа',
  },
  payment: {
    color: COLOR_BLUE,
    backgroundColor: COLOR_LIGHT_BLUE,
    text: 'Ожидает оплаты',
  },
  delivery: {
    color: COLOR_BLUE,
    backgroundColor: COLOR_LIGHT_BLUE,
    text: 'Выполняется',
  },
  cancel: {
    color: COLOR_ERROR,
    backgroundColor: COLOR_LIGHT_ERROR,
    text: 'Отменен',
  },
  return: {
    color: COLOR_BLUE,
    backgroundColor: COLOR_LIGHT_BLUE,
    text: 'Возврат',
  },
};
