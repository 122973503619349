import React, { useEffect, useMemo, useState } from 'react';
import './AppMenu.scss';
import { IAppMenuProps } from './AppMenu.types';
import { ConfigProvider, Menu } from 'antd';

import { AppFontIcon, AppLink } from '@ui-kit';
import { COLOR_HOVER } from '@shared/constants/colors';
import { useLocation } from 'react-router-dom';

/**
 * @description - Меню
 * @param props
 * onMenuItemClick - клик по пункту меню
 * menuItems - пункты меню
 */
const AppMenu = (props: IAppMenuProps) => {
  const { menuItems, isCollapsed, onClick, ...restProps } = props;
  const location = useLocation();
  const [activeLink, setActiveLink] = useState('');

  const menuItemsLocal = useMemo(() => {
    return menuItems.map((item) => {
      const { iconName, isExternal, label, route, ...restItem } = item;
      return {
        ...restItem,
        icon: <AppFontIcon icon={iconName} iconSize={20} />,
        label: (
          <AppLink link={route} isNested={!isExternal} target={isExternal ? '_blank' : '_self'}>
            {label}
          </AppLink>
        ),
      };
    });
  }, [menuItems]);

  const onMenuItemClick = () => {
    onClick?.();
  };

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            activeBarBorderWidth: 0,
            controlItemBgActive: COLOR_HOVER,
          },
        },
      }}
    >
      <Menu
        className={'app-ui-menu'}
        mode="vertical"
        onClick={onMenuItemClick}
        items={menuItemsLocal}
        selectedKeys={[activeLink]}
        {...restProps}
      />
    </ConfigProvider>
  );
};

export default AppMenu;
