import { $HttpClient } from '@shared/api/';
import {
  INotMyOrderListParams,
  IOrderCreateParams,
  IOrderCreateResponse,
  IOrderListParams,
  IOrderListResponse,
} from '@shared/models/order';
import { Endpoints } from '@shared/constants/endpoints';

/**
 * OrderProvider запросы связанные с заказами
 */

const OrderProvider = {
  /**
   * @description создаем ордер
   * @param orderData
   */
  createOrder: (orderData: IOrderCreateParams) => {
    return $HttpClient.post<IOrderCreateResponse>(Endpoints.ORDERS_CREATE, {
      ...orderData,
    });
  },

  /**
   *
   * @param orderId
   */
  loadOrder: (orderId: string) => {
    return $HttpClient.post<IOrderListResponse>(Endpoints.ORDERS, {
      limit: 1,
      filters: { orderNumber: orderId },
    });
  },
  /**
   * @description - загружаем список ордеров
   * @param body
   */
  loadOrders: (body: IOrderListParams) => {
    return $HttpClient.post<IOrderListResponse>(Endpoints.ORDERS, body);
  },
  /**
   * @description - загружаем список не наших ордеров
   * @param body
   */
  loadNotMyOrders: (body: INotMyOrderListParams) => {
    return $HttpClient.post<IOrderListResponse>(Endpoints.ORDERS_BONUS, body);
  },
};

export default OrderProvider;
