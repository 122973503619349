import React, { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IOptionValue } from '@ui-kit';
import { IAdaptiveNavbarProps } from './Navbar.types';
import { useAppDispatch, useAppSelector } from '@app/store/hooks';
import { logoutAction } from '@entities/auth';
import { persistor } from '@app/store/store';
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from '@shared/constants/routes';
import { removeAccessToken, removeRefreshToken } from '@shared/utils/token';
import { NavbarDesktop } from '../NavbarDesktop';
import { NavbarMobile } from '../NavbarMobile';
import { cookieCartItemsKey, getCartItems } from '@entities/cart';
import { isEmptyList } from '@frontend-modules/frontend-utils';
import { subscribeToCookieChanges } from '@shared/utils/cookies';

/**
 *
 * @param props
 */
const Navbar: FC<IAdaptiveNavbarProps> = (props) => {
  const { isAdaptive, onPhoneClick } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { firstName, lastName, email, bonus } = useAppSelector((state) => state.user.data) || {};
  const [isCartEmpty, setIsCartEmpty] = useState(false);

  const checkCartProducts = useCallback(() => {
    return setIsCartEmpty(isEmptyList(getCartItems()));
  }, []);

  useEffect(() => {
    checkCartProducts();
    // Возвращает метод для отписки
    const unsubscribe = subscribeToCookieChanges(cookieCartItemsKey, () => {
      checkCartProducts();
    });
    return () => {
      unsubscribe();
    };
  }, [checkCartProducts]);

  /** */
  const onExitClick = () => {
    removeAccessToken();
    removeRefreshToken();
    dispatch(logoutAction());
    persistor.purge();
    navigate(PUBLIC_ROUTES.AUTHORIZATION);
  };

  /**
   *
   * @param value
   */
  const onPersonInfoOptionClick = (value: IOptionValue) => {
    switch (value.value) {
      case 'exit':
        onExitClick();
        break;
      default:
        break;
    }
  };

  const userName = `${firstName} ${lastName}`;

  return (
    <nav className={'personal-navbar'}>
      {!isAdaptive ? (
        <NavbarDesktop
          score={bonus}
          userName={userName}
          email={email}
          onPersonInfoOptionClick={onPersonInfoOptionClick}
          isEmptyCart={isCartEmpty}
        />
      ) : (
        <NavbarMobile
          logoButtonLink={PRIVATE_ROUTES.HOME}
          score={bonus}
          userName={userName}
          email={email}
          onPhoneClick={onPhoneClick}
          onPersonInfoOptionClick={onPersonInfoOptionClick}
          isEmptyCart={isCartEmpty}
        />
      )}
    </nav>
  );
};

export default Navbar;
