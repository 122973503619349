import React, { FC, useEffect, useRef, useState } from 'react';
import { ProductCard } from '../ProductCard';
import { ScoreBalance } from '@entities/score';
import './ProductCardSliderList.scss';
import { AppCarousel } from '@ui-kit';
import { IProductCardSliderListProps } from './ProductCardSliderList.types';
import {
  PRODUCT_CARD_SLIDER_PROPS,
  PRODUCT_CARD_WIDTH,
  PRODUCTS_SLIDER_GAP,
} from './ProductCardSliderList.config';

/**
 *
 * @param props
 * @param props.productList
 */
const ProductCardSliderList: FC<IProductCardSliderListProps> = ({ productList }) => {
  const productCardSliderListRef = useRef<HTMLDivElement>(null);
  const [isCarousel, setIsCarousel] = useState(false);

  useEffect(() => {
    setIsCarousel(
      productCardSliderListRef &&
        productList?.length > 1 &&
        (PRODUCT_CARD_WIDTH + PRODUCTS_SLIDER_GAP) * productList.length >
          productCardSliderListRef?.current?.clientWidth,
    );
  }, [productList.length]);
  /** */
  const ProductCards = productList.map((productItem) => (
    <ProductCard
      productData={productItem}
      key={productItem.externalId}
      bonusBlock={
        productItem.bonus && (
          <ScoreBalance
            score={productItem.bonus}
            isWithPlus
            isBackground
            textConfig={{ type: 'small', fontWeight: 400 }}
          />
        )
      }
    />
  ));

  return (
    <div
      className={`product-card-slider-list ${isCarousel ? 'carousel' : ''}`}
      ref={productCardSliderListRef}
    >
      {isCarousel ? (
        <AppCarousel {...PRODUCT_CARD_SLIDER_PROPS}>{ProductCards}</AppCarousel>
      ) : (
        ProductCards
      )}
    </div>
  );
};

export default ProductCardSliderList;
