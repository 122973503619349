import React, { FC, useEffect, useState } from 'react';
import { AppInput, TAppPhoneInputProps } from '@ui-kit';
import './AppPhoneInput.scss';
import { formattedPhoneNumber } from './AppPhoneInput.utils';
import { DEFAULT_MASK_VALUE, phoneMasksMap } from './AppPhoneInput.config';

/**
 *
 * @param props
 */
const AppPhoneInput: FC<TAppPhoneInputProps> = (props) => {
  const { onChange, defaultValue, ...restProps } = props;

  const [phoneInfo, setPhoneInfo] = useState({
    phoneNumber: '',
    phoneMask: DEFAULT_MASK_VALUE,
  });

  /**
   *
   * @param phone
   */
  const onInputChange = (phone: string) => {
    if (!phone) {
      setPhoneInfo({ phoneNumber: '', phoneMask: DEFAULT_MASK_VALUE });
      return;
    }
    if (phone[1] === '3' || phone[1] === '7' || phone[1] === '9') {
      setPhoneInfo({
        phoneNumber: formattedPhoneNumber(phone),
        phoneMask: phoneMasksMap[phone[1]] || DEFAULT_MASK_VALUE,
      });
    } else {
      setPhoneInfo({
        phoneNumber: '7',
        phoneMask: phoneMasksMap[7],
      });
    }
  };

  useEffect(() => {
    if (defaultValue) {
      const formattedValue = defaultValue[0] !== '+' ? `+${defaultValue}` : defaultValue;
      setPhoneInfo({
        phoneNumber: formattedPhoneNumber(formattedValue),
        phoneMask: phoneMasksMap[formattedValue[1]] || DEFAULT_MASK_VALUE,
      });
    }
  }, [defaultValue]);

  useEffect(() => {
    onChange(phoneInfo.phoneNumber);
  }, [phoneInfo.phoneNumber]);

  return (
    <div className={`app-ui-phone-input`}>
      <AppInput
        {...restProps}
        type={'tel'}
        defaultValue={phoneInfo.phoneNumber}
        mask={[{ mask: phoneInfo.phoneMask }]}
        name="phone"
        onChange={onInputChange}
        value={phoneInfo.phoneNumber}
        className={'app-ui-phone-input_phone'}
      />
    </div>
  );
};

export default AppPhoneInput;
