import React, { useEffect } from 'react';
import { AppButton, AppDropdownSelect, AppEmptyData, AppLink, AppSkeleton, AppText } from '@ui-kit';
import { COLOR_BLACK, COLOR_GRAY_MAX, EMPTY_ORDERS_BG } from '@shared/constants/colors';
import { ScoreBalance } from '@entities/score';
import './LatestOrders.scss';
import { useScreenSize } from '@shared/hooks/useScreenSize';
import { loadOrderListAction, OBSERVER_EVENT_ORDER_DETAIL_MODAL, OrderCard } from '@entities/order';
import { customEventEmitter, isEmptyList } from '@frontend-modules/frontend-utils';
import { EXTERNAL_ROUTES, PRIVATE_ROUTES } from '@shared/constants/routes';
import { useAppDispatch, useAppSelector } from '@app/store/hooks';
import { FetchStatuses } from '@shared/constants/fetch';
import { IOrder } from '@shared/models/order';

const observer = customEventEmitter.Instance;

/** */
const LatestOrders = () => {
  const { calculateScreenType } = useScreenSize();
  const isAdaptive = calculateScreenType('laptop');
  const dispatch = useAppDispatch();

  const myOrdersLastData = useAppSelector((state) => state.order.data.last);
  const isLoading = myOrdersLastData?.fetchStatus === FetchStatuses.PENDING;

  const cardDataList = myOrdersLastData?.results?.map((item) => ({
    ...item,
    ...(item.products && {
      productsDropDownOptionsList: item.products.map((product) => ({
        label: `x${product.count} ${product.name}`,
        value: product.name,
      })),
    }),
  }));

  const onOrderNumberClick = (order: IOrder) => {
    observer.emit(OBSERVER_EVENT_ORDER_DETAIL_MODAL, order);
  };

  const onEmptyDataBtnClick = () => {
    window.open(EXTERNAL_ROUTES.PRODUCTS);
  };

  useEffect(() => {
    dispatch(
      loadOrderListAction({
        status: 'last',
        query: {
          currentPage: 1,
          limit: 3,
          filters: {
            status: 'last',
          },
          sorting: {
            createAt: 'up',
          },
        },
      }),
    );
  }, [dispatch]);
  return (
    <div className={'latest-orders'}>
      <div className={'latest-orders__header'}>
        <AppText text={'Последние заказы'} type={'title-h3'} color={COLOR_BLACK} />
        <AppLink isNested link={PRIVATE_ROUTES.HISTORY}>
          <AppButton
            size={'small'}
            type={'text'}
            text={'История заказов'}
            iconSize={20}
            icon={'arrow-right'}
          />
        </AppLink>
      </div>
      {isLoading && isEmptyList(cardDataList) ? (
        <AppSkeleton height={405} />
      ) : isEmptyList(cardDataList) ? (
        <div className={'empty-latest-orders'}>
          <AppEmptyData
            title={'Вы еще не совершили ни одной покупки'}
            description={'Хотите начать сейчас?'}
            bgColor={EMPTY_ORDERS_BG}
            buttonText={'Начать!'}
            onButtonClick={onEmptyDataBtnClick}
          />
        </div>
      ) : (
        <div className={'latest-orders__list'}>
          {cardDataList.map((orderItem) => {
            const productCount = orderItem.products.reduce(
              (acc, product) => acc + product.count,
              0,
            );
            return (
              <OrderCard
                direction={isAdaptive ? 'vertical' : 'horizontal'}
                onOrderNumberClick={onOrderNumberClick}
                data={orderItem}
                cardSize={isAdaptive ? 'large' : 'small'}
                key={orderItem.number}
                isShowDate
                scoreParam={<ScoreBalance isWithPlus score={orderItem.bonusAccrual} />}
                quantityProductDropDown={
                  <AppDropdownSelect
                    label={
                      <div className={'latest-orders__list__dropdown_label'}>
                        <AppText text={`Продукты`} color={COLOR_GRAY_MAX} />
                        <AppText
                          text={`${productCount} шт.`}
                          type={'subtitle'}
                          color={COLOR_GRAY_MAX}
                        />
                      </div>
                    }
                    iconColor={COLOR_GRAY_MAX}
                    className={`latest-orders__list__dropdown`}
                    options={orderItem.productsDropDownOptionsList}
                    placement={'bottom'}
                  />
                }
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default LatestOrders;
