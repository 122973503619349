import React, { FC } from 'react';
import { ProductCardSliderList } from '@entities/product';
import { LabelValue } from '@ui-kit';
import { OrderDeliveryInfo, OrderPaymentInfo, orderTagStatus } from '@entities/order';
import './SuccessOrderForm.scss';
import { ISuccessOrderFormProps } from './SuccessOrderForm.types';
import { getDeliveryName, getDeliveryTypeName } from '@entities/delivery';
import { formatDate } from '@frontend-modules/frontend-utils';
import { getPaymentMethodName } from '@entities/payment/utils';

/**
 *
 * @param props
 */
const SuccessOrderForm: FC<ISuccessOrderFormProps> = (props) => {
  const { orderData } = props;

  const productCount = orderData?.products?.reduce((acc, product) => acc + product.count, 0);

  const paymentData = {
    orderQuantity: productCount,
    orderAmount: orderData?.amountOrder,
    // sale: -432,
    // promoCodeSale: 350,
    amountTotal: orderData?.amountTotal,
    bonusTotal: orderData?.bonusTotal,
    scoreSale: orderData?.bonusDebt,
    deliveryPrice: orderData?.amountDelivery,
    title: getPaymentMethodName(orderData?.typePayment),
  };

  const deliveryData = {
    name: orderData?.fullName,
    phone: orderData?.phone,
    deliveryName: `${getDeliveryName(
      orderData?.typeSource,
      orderData?.typeDelivery,
    )} (${getDeliveryTypeName(orderData?.typeDelivery)})`,
    address: `${orderData?.postalCode}, ${orderData?.addressText}`,
    orderNumber: orderData?.number,
    date: formatDate(orderData?.createAt),
    comment: orderData?.comment,
    track: orderData?.trackNumber,
  };

  return (
    <div className={'success-order-form'}>
      <LabelValue
        label={'Номер заказа:'}
        direction={'horizontal'}
        isFullWidth={false}
        valueConfig={{ fontWeight: 600 }}
        value={`№${orderData?.number}`}
      />
      <LabelValue
        label={'Статус заказа:'}
        direction={'horizontal'}
        isFullWidth={false}
        valueConfig={{ fontWeight: 600 }}
        value={orderTagStatus?.[orderData?.status]?.text}
      />
      <div className={'success-order-form__product-list'}>
        <ProductCardSliderList productList={orderData?.products || []} />
      </div>
      <div className={'success-order-form__info'}>
        <OrderPaymentInfo {...paymentData} />
        <OrderDeliveryInfo {...deliveryData} />
      </div>
    </div>
  );
};

export default SuccessOrderForm;
