// noinspection JSUnresolvedReference

import { Endpoints } from '@shared/constants/endpoints';
import { IDeliveryPoint, TDeliveryTypes } from '@shared/models/delivery';

/**
 * @description - Функция для проверки и смещения точек с одинаковыми координатами
 * @param data
 */
export const adjustDuplicateCoordinates = (data: IDeliveryPoint[]) => {
  const adjustedData = [...data];
  const coordinatesSet = new Set();

  adjustedData.forEach((point: { latitude: number; longitude: number }, index) => {
    const { latitude, longitude } = point;

    // Формируем уникальный ключ по координатам
    const coordinateKey = `${latitude.toFixed(1)}-${longitude.toFixed(1)}`;

    // Если точка с такими координатами уже есть, смещаем ее немного
    if (coordinatesSet.has(coordinateKey)) {
      adjustedData[index].latitude += 0.0001;
      adjustedData[index].longitude += 0.0001;
    }

    // Добавляем текущие координаты в множество
    coordinatesSet.add(coordinateKey);
  });

  return adjustedData;
};

// noinspection JSUnresolvedReference

/**
 * @description - делит адрес из инпута так, чтобы не вызывать ререндер, подразумевается, что адрес начинается
 * города
 * @param inputString
 */
export const formatYandexAddress = (inputString: string) => {
  const parts = inputString.split(', ');
  if (parts.length > 1) {
    return parts.slice(1).concat(parts[0]).join(', ');
  }
  return inputString;
};

/**
 * @description - да простят меня боги за это код... АМИНЬ. Данный код необходимость переопределения методов
 * Идут они в два этапа, первый - это вставка тэга !script! который подгружает js скрипт с результатом
 * второй - это подсказки, которые формируют обычный запрос JSON
 *
 * Оба запроса проксируется через наш бэк
 */
export const initReplaceAPIYMap = () => {
  // Сохраняем оригинальную функцию insertBefore для элемента head
  const originalInsertBefore = document.head.insertBefore;

  /*
  eslint-disable func-names
  */
  /**
   *  Переопределяем функцию insertBefore для элемента head
   *   нужно для замены яндекс карт логики
   *
   * @param newChild
   * @param referenceNode
   */
  document.head.insertBefore = function <T extends Node, ChildT extends Node>(
    newChild: T & HTMLScriptElement,
    referenceNode: ChildT | null,
  ) {
    if (
      newChild.src &&
      newChild.src.toString().includes('https://api-maps.yandex.ru/services/search//v2/')
    ) {
      newChild.src = newChild.src.replace(
        'https://api-maps.yandex.ru/services/search//v2/',
        `${process.env.REACT_APP_HOST}${Endpoints.MAPS_SEARCH}`,
      );
    }
    // Вызываем оригинальную функцию insertBefore
    return originalInsertBefore.call(document.head, newChild, referenceNode);
  };
  // Сохраняем оригинальный XMLHttpRequest
  const originalXMLHttpRequest = window.XMLHttpRequest;

  // Переопределяем XMLHttpRequest
  /** */
  function customXHR() {
    // eslint-disable-next-line new-cap
    const xhr = new originalXMLHttpRequest();
    // Сохраняем оригинальные методы
    const originalOpen = xhr.open;
    const originalSend = xhr.send;
    /* eslint-disable func-names */
    /**
     * Переопределяем метод open
     *
     * @param method
     * @param url
     */
    xhr.open = function (method: string, url: string) {
      if (url.toString().includes('https://suggest-maps.yandex.ru/v1/suggest')) {
        const originalURL = url;
        url = `${process.env.REACT_APP_HOST}${Endpoints.SUGGEST}`;
        method = 'POST';
        // eslint-disable-next-line react/no-this-in-sfc
        this.customRequestData = {
          method,
          url,
          originalURL,
          requestBody: null,
        };
      }
      // Вызываем оригинальный метод open с измененными параметрами
      originalOpen.apply(xhr, [method, url, true]);
      // noinspection JSUnresolvedReference
      if (this.customRequestData) {
        this.setRequestHeader('Content-Type', 'application/json');
      }
    };

    /**
     * Переопределяем метод send
     */
    xhr.send = function () {
      // Ваша логика изменения тела запроса
      if (this.customRequestData?.originalURL) {
        const urlParams = new URLSearchParams(new URL(this.customRequestData?.originalURL)?.search);
        const requestBody = {};
        // Если есть параметры, создаем объект и устанавливаем его в body запроса
        if (urlParams) {
          urlParams.forEach((value, key) => {
            requestBody[key] = value;
          });
        }
        // Вызываем оригинальный метод send с измененными параметрами
        originalSend.call(xhr, JSON.stringify(requestBody));
      } else {
        // eslint-disable-next-line prefer-rest-params
        originalSend.apply(xhr, arguments);
      }
    };

    // Добавьте другие методы XMLHttpRequest, если необходимо

    return xhr;
  }

  // Переопределяем глобальный XMLHttpRequest
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.XMLHttpRequest = customXHR;
};

/**
 * @description - функция вернет метод для изменения видимости подсказок для поля "Адрес"
 */
export const suggestChangeVisibility = () => {
  const getSuggestElement = (): HTMLElement | null => {
    return document.querySelector('.app-ui-input ymaps[data-suggest="true"]');
  };

  return (visibility: 'hidden' | 'visible'): void => {
    const element = getSuggestElement();
    if (element) {
      element.style.visibility = visibility;
    }
  };
};

/**
 *
 * @param yMapsInstance
 * @param yMapsInstance.geocode
 * @param address
 * @param deliveryType
 * @param onInputChange
 */
export const validateYMAPAddress = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yMapsInstance: any,
  address: string,
  deliveryType: TDeliveryTypes | '',
  onInputChange: (value: string, type: string) => void,
): Promise<{ isValid: boolean; messages: string[] }> => {
  if (!yMapsInstance) return { isValid: false, messages: [] };
  // suggest list
  const addressYAList = await yMapsInstance?.suggest(address);
  // first elements from list
  const addressYAFirstSuggest = addressYAList?.[0]?.displayName || address;
  const res = await yMapsInstance.geocode(addressYAFirstSuggest);
  const firstGeoObject = res.geoObjects.get(0);

  if (!firstGeoObject) {
    return {
      isValid: false,
      messages: ['Адрес не найден'],
    };
  }

  // eslint-disable-next-line no-underscore-dangle
  const addressYandexData = firstGeoObject.properties?._data;
  const addressWithTown = formatYandexAddress(addressYandexData.text);
  onInputChange(addressWithTown, 'address');

  if (deliveryType === 'express' || deliveryType === 'to_door') {
    // Об оценке точности ответа геокодера можно прочитать тут: https://tech.yandex.ru/maps/doc/geocoder/desc/reference/precision-docpage/
    switch (firstGeoObject.properties.get('metaDataProperty.GeocoderMetaData.precision')) {
      // пропускаем если дошли до номера дома
      case 'exact':
      case 'number':
      case 'near':
        return {
          isValid: true,
          messages: [],
        };
      case 'range':
      case 'street':
        return {
          isValid: false,
          messages: ['Уточните номер дома'],
        };
      case 'other':
      default:
        return {
          isValid: false,
          messages: ['Уточните адрес'],
        };
    }
  } else {
    // проверяем можем ли определить город или что-то на него похожее
    switch (
      !!firstGeoObject.getLocalities().length ||
      firstGeoObject.properties
        .get('metaDataProperty.GeocoderMetaData.Address.Components')
        .some((item: { kind: string }) => item.kind === 'province')
    ) {
      // пропускаем если город имеется
      case true:
        return {
          isValid: true,
          messages: [],
        };
      case false:
      default:
        return {
          isValid: false,
          messages: ['Уточните адрес'],
        };
    }
  }
};

/**
 *
 * @param results
 * @param clicked
 */
export const combineDeliveryPoints = (
  results: IDeliveryPoint[],
  clicked: IDeliveryPoint[],
): IDeliveryPoint[] => {
  const clickedExternalIds = new Set(clicked.map((item) => item.externalId));

  const newPoints = results.filter((item) => !clickedExternalIds.has(item.externalId));

  return [...clicked, ...newPoints];
};
