import React, { useEffect, useState } from 'react';
import './AppOptionList.scss';
import { AppFontIcon, AppText } from '@ui-kit';
import { IOptionListProps, IOptionValue } from './AppOptionList.types';

/**
 * @description - лист с опциями
 * @param props
 */
const AppOptionList = <T extends IOptionValue>(props: IOptionListProps<T>) => {
  const { value, options, onChange, activeKey = 'value', cursor = 'pointer' } = props;
  const [selectedOption, setSelectedOption] = useState(null);

  const onListItemClick = (listItemValue: T) => {
    setSelectedOption(listItemValue);
    onChange(listItemValue);
  };

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  return (
    <div className={'app-ui-option-list'}>
      {options.map((item, index) => (
        <div
          className={`app-ui-option-list_item ${
            selectedOption?.[activeKey] === item[activeKey] ? 'active' : ''
          } cursor-${cursor}`}
          key={String(item?.[activeKey]) || index}
          onClick={() => onListItemClick(item)}
        >
          <div className={'app-ui-option-list_item_content'}>
            {item?.icon && <AppFontIcon icon={item?.icon} iconSize={20} />}
            <AppText text={item.label} type={'body-light'} fontType={'averta'} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default AppOptionList;
