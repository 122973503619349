import React, { FC } from 'react';
import { ILogoButtonProps } from './LogoButton.types';
import './LogoButton.scss';
import { companyLogo, companyLogoSmall } from '@shared/assets/images';
import { AppLink } from '@ui-kit';

/**
 * Кнопка - лого компании.
 *
 */

/**
 *
 * @param props
 */
const LogoButton: FC<ILogoButtonProps> = (props) => {
  const { isSmall, logoButtonLink } = props;

  return (
    <>
      {logoButtonLink ? (
        <AppLink isNested link={logoButtonLink}>
          <img
            className={`${isSmall ? 'company-img__small' : 'company-img'}`}
            src={!isSmall ? companyLogo : companyLogoSmall}
            alt="Biorich"
          />
        </AppLink>
      ) : (
        <img
          className={`${isSmall ? 'company-img__small' : 'company-img'}`}
          src={!isSmall ? companyLogo : companyLogoSmall}
          alt="Biorich"
        />
      )}
    </>
  );
};

export default LogoButton;
