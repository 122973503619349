import { createSlice } from '@reduxjs/toolkit';
import { TAsyncState } from '@shared/models/async-state';
import { TFetchStatus } from '@shared/constants/fetch';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { loadMetaAction } from '@entities/meta';
import { IErrorResponse } from '@shared/models/errors';
import { IMeta } from '@shared/models/meta';

interface IMetaState extends TAsyncState {
  patchMetaStatus?: TFetchStatus;
  patchMetaError?: IErrorResponse;
  data?: IMeta;
}

const initialState: IMetaState = {
  status: 'idle',
  error: null,
  data: {
    freeDelivery: 0,
    minSumPayment: 10,
    phoneOrganization: '+7 (800) 222-52-67',
    workTimeExpressDelivery: 'Пн-Пт | 9:00 - 18:00',
    amountExpressDelivery: 600,
    bonusRelation: 1,
  },
};

const metaSlice = createSlice({
  name: 'meta',
  initialState,
  reducers: {},
  /**
   *
   * @param builder
   */
  extraReducers: (builder) => {
    builder
      .addCase(loadMetaAction.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(loadMetaAction.fulfilled, (state, { payload }) => {
        state.status = 'fulfilled';
        state.data = payload;
      })
      .addCase(loadMetaAction.rejected, (state, { error }) => {
        state.status = 'rejected';
        state.error = error;
      });
  },
});

export const metaReducer = metaSlice.reducer;

export const metaPersistConfig = {
  key: 'biorich::meta',
  storage,
  version: 1,
};

export const persistedMetaReducer = persistReducer(metaPersistConfig, metaReducer);
