import { $HttpClient } from '@shared/api/';
import { Endpoints } from '@shared/constants/endpoints';

/**
 * ServiceProvider запросы сервисов
 */

const ServiceProvider = {
  /**
   * @description запрос обратного звонка
   * @param phone
   */
  callBackRequest: (phone: string) => {
    return $HttpClient.post<{ phone: string }>(Endpoints.CALLBACK, {
      phone,
    });
  },
};

export default ServiceProvider;
