import { $HttpClient } from '@shared/api/';
import { IDeliveryCalculation, IDeliveryParams, IDeliveryPoint } from '@shared/models/delivery';
import { IHttpResponse } from '@shared/models/http-response';
import { TCartParams } from '@shared/models/cart';
import { Endpoints } from '@shared/constants/endpoints';

/**
 * DeliveryProvider запросы связанные с доставкой
 */

const DeliveryProvider = {
  /**
   * @description получение меток по longitude и latitude
   * @param params
   */
  findDeliveryPoints: (params: IDeliveryParams) => {
    return $HttpClient.post<IHttpResponse<IDeliveryPoint>>(Endpoints.DELIVERY_POINTS, {
      ...params,
    });
  },
  /**
   * @description калькуляция стоимости доставки и сроков
   * @param params
   */
  calculateDelivery: (params: Pick<IDeliveryPoint, 'postalCode'> & { products: TCartParams[] }) => {
    return $HttpClient.post<IDeliveryCalculation>(
      Endpoints.DELIVERY_CALCULATION,
      {
        ...params,
      },
      {},
      { abortKey: 'calDeliveryPoint', isDelEmptyParams: true },
    );
  },
};

export default DeliveryProvider;
