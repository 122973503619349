import React from 'react';
import { AuthLayout } from '@widgets/layouts/AuthLayout';
import { AppTextIconLink } from '@ui-kit';
import { PUBLIC_ROUTES } from '@shared/constants/routes';
import { AuthRegistrationUserForm } from '@features/auth/AuthRegistrationUserForm';

/** */
const RegistrationPage = () => {
  return (
    <div className={'authorization-page'}>
      <AuthLayout
        logoButtonLink={PUBLIC_ROUTES.AUTHORIZATION}
        pageType={'registration'}
        headerLink={
          <AppTextIconLink
            isNested={false}
            link={PUBLIC_ROUTES.AUTHORIZATION}
            text={'Вернуться назад'}
          />
        }
      >
        <AuthRegistrationUserForm />
      </AuthLayout>
    </div>
  );
};

export default RegistrationPage;
