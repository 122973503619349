import React, { forwardRef } from 'react';
import { AppFontIcon, AppText, TAppButtonProps } from '@ui-kit';
import { Button } from 'antd';
import './AppButton.scss';

/**
 * @description Свойства кнопки приложения
 * [iconLeft] - Иконка слева от текста кнопки
 * [icon] - Иконка кнопки
 * [text] - Текст кнопки
 * [textColor] - Цвет текста кнопки
 * [isFull] - Флаг, указывающий, занимает ли кнопка всю доступную ширину
 * [iconSize] - Размер иконки кнопки
 * [textType] - Тип текста кнопки
 * [onClick] - Обработчик клика по кнопке
 * [type] - Тип кнопки (возможные значения: 'menu', 'black', 'grayMax')
 * [isCollapsed] - Флаг, указывающий, свернута ли кнопка
 * [className] - Дополнительный CSS-класс кнопки
 * [ref] - Ссылка на DOM-элемент кнопки
 * [size] - Размер кнопки
 */
const AppButton = forwardRef<HTMLElement, TAppButtonProps>((props, ref) => {
  const {
    iconLeft,
    icon,
    iconColor,
    iconLeftColor,
    textType,
    fontType,
    text,
    iconSize,
    children,
    isFull,
    type,
    isCollapsed = false,
    className,
    textColor,
    size = '',
    fontWeight,
    ...restProps
  } = props;
  const isMenuType = type === 'menu';
  const isBlackType = type === 'black';

  const isGrayMaxType = type === 'grayMax';

  return (
    <div
      className={`app-ui-button ${isFull ? 'is-full' : ''} ${
        isMenuType
          ? 'is-menu-button'
          : isBlackType
          ? 'is-black-button'
          : isGrayMaxType
          ? 'is-gray-max-button'
          : ''
      }`}
    >
      <Button
        {...restProps}
        ref={ref}
        type={isMenuType || isBlackType || isGrayMaxType ? 'default' : type}
        className={`${!text && icon ? 'is-icon-only' : ''}  app-ui-button ${
          className || ''
        } ${size}`}
      >
        {iconLeft && (
          <AppFontIcon
            icon={iconLeft}
            iconSize={isMenuType ? 20 : iconSize}
            color={iconLeftColor}
          />
        )}
        {text && !isCollapsed && (
          <AppText
            className={isMenuType ? 'is-menu-text' : ''}
            text={text}
            fontType={isMenuType ? 'averta' : fontType}
            isCursorPointer={isMenuType}
            fontWeight={fontWeight}
            color={textColor}
            type={isMenuType ? 'body' : textType || 'subtitle'}
          />
        )}
        {icon && <AppFontIcon icon={icon} iconSize={iconSize} color={iconColor} />}
      </Button>
    </div>
  );
});

export { AppButton };
