import React, { useEffect, useState } from 'react';
import { COLOR_GRAY_MAX } from '@shared/constants/colors';
import { AppInput, AppText, AppVerticalScrollList } from '@ui-kit';
import { IAppSearchInputProps } from './AppSearchInput.types';
import './AppSearchInput.scss';
import { useClickOutside } from '@shared/hooks/useClickOutside';
import { isEmptyList } from '@frontend-modules/frontend-utils';

/**
 * @description инпут с выпадашкой для поиска
 * @param props
 */
const AppSearchInput = <T,>(props: IAppSearchInputProps<T>) => {
  const {
    results,
    renderItem,
    isResultPanel,
    isNeedInitRequest,
    onLoad,
    onLoadMore,
    limit = 50,
    pagination,
    isFetching,
    isFetchingMore,
    onChange,
    requestSymbolsCount = 3,
    onItemClick,
    clickOutsideIgnoreClasses,
    ...restProps
  } = props;

  const [isPanelOpened, setPanelOpened] = useState(false);
  const [isWasInitRequest, setWasInitRequest] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isUserTyping, setUserTyping] = useState(false);
  const ref = useClickOutside(() => {
    setPanelOpened(false);
  }, ['.app-search-input', ...(clickOutsideIgnoreClasses || [])]);

  const notFountContent = isFetching || isUserTyping ? 'Загрузка...' : 'Ничего не найдено';

  /**
   * @description - через эффект реагируем не на каждое нажатие в инпуте
   * а на то, когда пользователь перестал писать текст
   */
  useEffect(() => {
    setUserTyping(true);
    const delayDebounceFn = setTimeout(() => {
      setUserTyping(false);
      if (isNeedInitRequest && !isWasInitRequest) {
        onLoad?.({
          search: searchValue,
          currentPage: 1,
          limit,
        });
        setWasInitRequest(true);
      } else if (searchValue.length >= requestSymbolsCount) {
        onLoad?.({
          search: searchValue,
          currentPage: 1,
          limit,
        });
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [isNeedInitRequest, isWasInitRequest, limit, requestSymbolsCount, searchValue]);

  /**
   *
   * @param item
   * @param index
   */
  const renderItemLocal = (item, index) => {
    return (
      <div
        className={'app-search-input__panel-items-item'}
        key={index}
        onClick={() => {
          setPanelOpened(false);
          onItemClick(item);
        }}
      >
        <div className={'app-search-input__panel-items-item__content'}>
          {renderItem?.(item, index) || (
            <div className={'app-search-input__panel-items-item__content-default'}>
              <AppText type={'small'} color={COLOR_GRAY_MAX} text={item.label} />
              <AppText type={'body'} text={item.value} />
            </div>
          )}
        </div>
      </div>
    );
  };

  const onChangeLocal = (e) => {
    setSearchValue(e);
    onChange?.(e);
  };

  /**
   *
   * @param event
   */
  const onPanelScroll = (event) => {
    const { target } = event;
    if (Math.round(target.scrollTop) + target.offsetHeight === target.scrollHeight) {
      if (pagination) {
        if (pagination?.currentPage < pagination?.maxPage && pagination?.maxPage > 1) {
          const params = {
            search: searchValue,
            currentPage: pagination?.currentPage + 1,
            limit,
          };
          if (!isFetchingMore) {
            onLoadMore?.(params);
          }
        }
      }
    }
  };

  return (
    <div className={'app-search-input'}>
      <AppInput
        {...restProps}
        label={'Поиск'}
        iconLeft={'search'}
        onFocus={() => {
          setPanelOpened(true);
        }}
        value={searchValue}
        onChange={onChangeLocal}
        iconSize={24}
        iconCursorType={'default'}
        autoComplete={'off'}
      />
      {isResultPanel && isPanelOpened && (
        <div ref={ref} className={'app-search-input__panel'}>
          {isEmptyList(results) ? (
            <AppText
              type={'body'}
              color={COLOR_GRAY_MAX}
              className={'app-search-input__panel-empty'}
              text={notFountContent}
            />
          ) : (
            <AppVerticalScrollList<any>
              paddingBottom={0}
              onScroll={onPanelScroll}
              list={results}
              renderItem={renderItemLocal}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default AppSearchInput;
