import React, { FC, useEffect, useState } from 'react';
import './AppPrompt.scss';
import { IAppPromptProps } from './AppPrompt.types';
import { AppText } from '@ui-kit';
import { COLOR_WHITE } from '@shared/constants/colors';

/**
 * Компонент подсказки. После передачи в компонент текст - на 5сек всплывает подсказка
 *
 * @param props
 *  @param props.text - Текст, отображаемый в компоненте.
 *  @param props.textConfig - Конфигурация для компонента текста.
 *  @param props.children - Дочерние элементы для отображения.
 *  @param props.delay - Время, на которое всплывает подсказка / null - не скрывает.
 */
const AppPrompt: FC<IAppPromptProps> = (props) => {
  const { text, textConfig, delay = 5000, children } = props;
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    if (text) {
      setIsShow(true);
      if (delay) {
        const timeout = setTimeout(() => {
          setIsShow(false);
        }, delay);

        return () => clearTimeout(timeout);
      }
    } else {
      setIsShow(false);
    }
  }, [text, delay]);

  return (
    <div className={'app-ui-prompt'}>
      {children}
      {isShow && (
        <div className={`app-ui-prompt-content`}>
          {typeof text === 'string' ? (
            <AppText text={text} color={COLOR_WHITE} {...textConfig} />
          ) : (
            text
          )}
        </div>
      )}
    </div>
  );
};

export default AppPrompt;
