import React, { useState } from 'react';
import { AppButton } from '@ui-kit';
import { PaymentProvider } from '@entities/payment';
import { showErrorToast } from '@shared/utils/toast';
import { IPayOrderButtonProps } from './PayOrderButton.types';

/**
 * @description - кнопка оплаты заказа
 * @param props
 */
const PayOrderButton = (props: IPayOrderButtonProps) => {
  const { orderId, ...restProps } = props;

  const [isLoading, setLoading] = useState(false);

  /** */
  const onPaymentClick = () => {
    setLoading(true);
    PaymentProvider.getPaymentLink(orderId)
      .then((response) => {
        const { link } = response;
        if (link) {
          window.open(link, '_self');
        } else {
          showErrorToast({ message: 'Ошибка генерации ссылки... попробуйте позже' });
        }
      })
      .catch(() => {
        showErrorToast({ message: 'Ошибка генерации ссылки... попробуйте позже' });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <AppButton
      type={'primary'}
      textType={'body'}
      size={'medium'}
      loading={isLoading}
      text={'Оплатить'}
      onClick={onPaymentClick}
      {...restProps}
    />
  );
};

export default PayOrderButton;
