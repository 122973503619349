import React from 'react';
import './PublicFooter.scss';
import { AppFontIcon, AppLink, AppText } from '@ui-kit';
import {
  COLOR_BLACK,
  COLOR_GRAY_MAX,
  COLOR_GRAY_MEDIUM,
  COLOR_SUCCESS,
  COLOR_VK,
  COLOR_WHITE,
} from '@shared/constants/colors';
import { EXTERNAL_ROUTES } from '@shared/constants/routes';
import { useAppSelector } from '@app/store/hooks';
import { companyLogo, telegram, vk } from '@shared/assets/images';
import { useScreenSize } from '@shared/hooks/useScreenSize';
import { YANDEX_MAP_LINK } from '@entities/yandex/constants';
import dayjs from 'dayjs';
/** */
const PublicFooter = () => {
  const CONTACT_PHONE_NUMBER = useAppSelector((state) => state.meta.data?.phoneOrganization || '');
  const { calculateScreenType } = useScreenSize();

  const isLaptop = calculateScreenType('laptop');
  const isTablet = calculateScreenType('tablet');
  const isMobile = calculateScreenType('mobile');

  const isNeedVertical = isTablet && !isMobile;
  const isOnlyLaptop = isLaptop && !isTablet && !isMobile;

  const currentYear = dayjs().year();

  /**
   *
   * @param icon
   * @param text
   * @param textPlacement
   */
  const renderIconText = (icon, text, textPlacement: 'center' | 'start' = 'center') => {
    return (
      <div className={`render-icon-text ${textPlacement}`}>
        <AppFontIcon
          style={{ borderRadius: 6 }}
          icon={icon}
          color={'#FFFFFF'}
          background={COLOR_SUCCESS}
        />
        {icon === 'mail' ? (
          <AppLink link={`mailto:${text}`}>
            <AppText text={text} type={'small'} color={COLOR_GRAY_MAX} />
          </AppLink>
        ) : (
          <AppText text={text} type={'small'} color={COLOR_GRAY_MAX} />
        )}
      </div>
    );
  };

  /**
   *
   * @param link
   * @param icon
   * @param background
   * @param text
   */
  const socialLink = (link, icon, background, text = '') => {
    return (
      <AppLink className={'social-link'} href={link} style={{ background }}>
        <>
          <img src={icon} alt="Соц.сеть" />
          {text && (
            <AppText
              color={COLOR_WHITE}
              className={'telegram-link__text'}
              fontType={'codec'}
              text={text}
            />
          )}
        </>
      </AppLink>
    );
  };

  /**
   *
   * @param link
   * @param text
   */
  const telegramLink = (link, text) => {
    return (
      <AppLink isNested={false} className={'telegram-link'} href={link}>
        <>
          <img src={telegram} alt="Соц.сеть" />
          <AppText
            color={COLOR_WHITE}
            className={'telegram-link__text'}
            fontType={'codec'}
            text={text}
          />
        </>
      </AppLink>
    );
  };

  return (
    <div className={`public-footer`}>
      <div className="public-footer__content">
        <div className="public-footer__content-item central-office">
          <AppText
            text={'Центральный офис'}
            type={isOnlyLaptop ? 'note' : 'subtitle'}
            fontWeight={600}
          />
          <div className="public-footer__content-item__block">
            {renderIconText('point', 'г. Москва, Очаковское шоссе, 28, стр. 1, оф. 25')}
            {renderIconText('mail', 'info@biorich.com', 'center')}
          </div>
          <div className="public-footer__content-item__block">
            <AppText text={'По обработке интернет-заказов'} type={'small'} />
            {renderIconText('mail', 'order@biorich.com', 'center')}
          </div>
          <div className="public-footer__content-item__block">
            <AppText text={'Телефон'} type={'small'} />
            <AppLink isNested href={`tel:${CONTACT_PHONE_NUMBER}`}>
              <AppText
                text={CONTACT_PHONE_NUMBER}
                color={COLOR_BLACK}
                type={'subtitle'}
                fontType={'codec'}
              />
            </AppLink>
          </div>
          <div className="public-footer__content-item__block-column small">
            <div className="public-footer__content-item__block-row small">
              {socialLink(EXTERNAL_ROUTES.VK_OFFICIAL, vk, COLOR_VK, 'Вконтакте')}
            </div>
            <div
              className={`public-footer__content-item__block-${isMobile ? 'column' : 'row'} small`}
            >
              {telegramLink(
                EXTERNAL_ROUTES.TELEGRAM_OFFICIAL_CHAT,
                isOnlyLaptop ? 'Чат' : 'Чат для вопросов',
              )}
              {telegramLink(
                EXTERNAL_ROUTES.TELEGRAM_OFFICIAL_CHANNEL,
                isOnlyLaptop ? 'Канал' : 'Официальный канал',
              )}
            </div>
          </div>
        </div>
        <div className="public-footer__content-item map">
          <AppText
            text={'Юридическая информация'}
            type={isOnlyLaptop ? 'note' : 'subtitle'}
            fontWeight={600}
          />
          <div className="public-footer__content-item__block">
            <div
              className={`public-footer__content-item__block-${
                isOnlyLaptop ? 'row' : 'column'
              } small`}
            >
              <div className="public-footer__content-item__block-row data">
                <AppText
                  color={COLOR_GRAY_MAX}
                  text={'ООО "БИОРИЧ"'}
                  type={isOnlyLaptop ? 'signature' : 'body'}
                />
                <AppText
                  color={COLOR_GRAY_MAX}
                  text={'ИНН 7722320612'}
                  type={isOnlyLaptop ? 'signature' : 'body'}
                />
                <AppText
                  color={COLOR_GRAY_MAX}
                  text={'ОГРН 1157746231081'}
                  type={isOnlyLaptop ? 'signature' : 'body'}
                />
              </div>
              <AppText
                text={'Продажи на сайте осуществляет ООО "Наука и Питание" ИНН 5406626334'}
                type={'signature'}
                className={'sell-info'}
                color={COLOR_GRAY_MEDIUM}
              />
            </div>
            <div
              className={`public-footer__content-item__block-${
                isNeedVertical ? 'row' : 'column'
              } small`}
            >
              <AppLink
                isNested={false}
                target={'_blank'}
                type={'bordered'}
                link={EXTERNAL_ROUTES.POLICY}
              >
                <AppText
                  text={'Политика конфиденциальности'}
                  type={isOnlyLaptop ? 'small' : !isNeedVertical ? 'body' : 'signature'}
                />
              </AppLink>
              <AppLink
                isNested={false}
                target={'_blank'}
                type={'bordered'}
                link={EXTERNAL_ROUTES.TERMS_OF_SERVICE}
              >
                <AppText
                  text={'Соглашение о пользовании услугами'}
                  type={isOnlyLaptop ? 'small' : !isNeedVertical ? 'body' : 'signature'}
                />
              </AppLink>
              <AppLink
                isNested={false}
                target={'_blank'}
                type={'bordered'}
                link={EXTERNAL_ROUTES.WEBSITE_PUBLIC_OFFER}
              >
                <AppText
                  text={'Публичная оферта'}
                  type={isOnlyLaptop ? 'small' : !isNeedVertical ? 'body' : 'signature'}
                />
              </AppLink>
            </div>
          </div>
          <div className="public-footer__content-item__block with-map">
            <a
              href={YANDEX_MAP_LINK}
              target="_blank"
              rel="noreferrer"
              className="public-footer__content-item__block--map"
              title={'map'}
            >
              {isMobile && (
                <img
                  src="https://api-maps.yandex.ru/services/constructor/1.0/static/?um=constructor%3A7ad778977f84486c05ba1aa57e0ad02c62c8aa070b4850879a1054ea84a2019a&amp;width=337&amp;height=162&amp;lang=ru_RU"
                  alt="карта"
                />
              )}
              {isOnlyLaptop && (
                <img
                  src="https://api-maps.yandex.ru/services/constructor/1.0/static/?um=constructor%3A7ad778977f84486c05ba1aa57e0ad02c62c8aa070b4850879a1054ea84a2019a&amp;width=350&amp;height=172&amp;lang=ru_RU"
                  alt="карта"
                />
              )}
              {!isMobile && !isOnlyLaptop && (
                <img
                  src="https://api-maps.yandex.ru/services/constructor/1.0/static/?um=constructor%3A7ad778977f84486c05ba1aa57e0ad02c62c8aa070b4850879a1054ea84a2019a&amp;height=150&amp;lang=ru_RU"
                  alt="карта"
                />
              )}
            </a>
          </div>
        </div>
        <div className="public-footer__content-item belarus">
          <AppText
            text={'Представитель в Республике Беларусь'}
            type={isOnlyLaptop ? 'note' : 'subtitle'}
            fontWeight={600}
          />
          <div
            className={`public-footer__content-item__block-${isNeedVertical ? 'row' : 'column'}`}
          >
            <div className={'public-footer__content-item__block-column small'}>
              {renderIconText(
                'point',
                'Республика Беларусь, 220034, г. Минск, ул. Захарова, д. 40, кабинет 12 (пом. 1н)',
              )}
              {renderIconText('mail', 'info@para-la-oro.com', 'center')}
            </div>
            <div className="public-footer__content-item__block-column small">
              <AppText text={'Телефон'} type={'small'} />
              <AppLink isNested href={`tel:+375 (17) 271-41-30`}>
                <AppText
                  text={'+375 (17) 271-41-30'}
                  color={COLOR_BLACK}
                  type={isOnlyLaptop || isNeedVertical ? 'body' : 'subtitle'}
                  fontType={'codec'}
                />
              </AppLink>
              <AppLink isNested href={`tel:+375 (29) 601-73-73`}>
                <AppText
                  text={'+375 (29) 601-73-73'}
                  color={COLOR_BLACK}
                  type={isOnlyLaptop || isNeedVertical ? 'body' : 'subtitle'}
                  fontType={'codec'}
                />
              </AppLink>
            </div>
          </div>
          <div className="public-footer__content-item__block logo">
            <div
              className={`public-footer__content-item__block-${
                isOnlyLaptop ? 'column' : 'row'
              } small align-start`}
            >
              <img src={companyLogo} className={'footer-logo'} alt="Biorich" />
              <div className={`public-footer__content-item__block-column align-end gap-0`}>
                <AppText
                  text={`© ${currentYear} «ООО БИОРИЧ».`}
                  color={COLOR_GRAY_MEDIUM}
                  type={'small'}
                />
                <AppText text={`Все права защищены.`} color={COLOR_GRAY_MEDIUM} type={'small'} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicFooter;
