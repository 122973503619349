/**
 * Наблюдение за последним дочерним элементом с указанным именем класса в указанном родительском элементе.
 *
 * @param parentClassName - Имя класса родительского элемента
 * @param childClassName - Имя класса дочернего элемента для наблюдения
 * @param callback - Функция обратного вызова, которая будет вызвана, когда последний дочерний элемент попадает в область видимости
 * @param options
 */
export const lastChildObserver = (
  parentClassName: string,
  childClassName: string,
  callback: () => void,
  options?: IntersectionObserverInit,
) => {
  try {
    const lastChild = document.querySelector(
      `.${parentClassName} .${childClassName} + :last-child`,
    );

    if (lastChild) {
      const intersectionObserver = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            callback();
          }
        },
        {
          root: null,
          ...options,
        },
      );
      intersectionObserver.observe(lastChild);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('lastChildObserver', error);
  }
};
