import { FC, useState } from 'react';
import { TAppDropdownSelectProps } from './AppDropdownSelect.types';
import './AppDropdownSelect.scss';
import { AppFontIcon, AppOptionList, AppPopover, AppText, IOptionValue } from '@ui-kit';
import { COLOR_BLACK } from '@shared/constants/colors';

/**
 *
 * @param props
 */
const AppDropdownSelect: FC<TAppDropdownSelectProps> = (props) => {
  const { onOptionClick, label, className, options, iconColor, ...restProps } = props;

  const [isDropdownSelectOpen, setIsDropdownSelectOpen] = useState(false);

  const onChange = (value: IOptionValue) => {
    setIsDropdownSelectOpen(false);
    onOptionClick?.(value);
  };

  return (
    <div className={`app-ui-dropdown-select ${className || ''}`}>
      <AppPopover
        trigger={'click'}
        className={`app-ui-dropdown-select__popover`}
        open={isDropdownSelectOpen}
        onOpenChange={setIsDropdownSelectOpen}
        content={
          <AppOptionList
            options={options}
            onChange={onChange}
            cursor={!onOptionClick ? 'default' : 'pointer'}
          />
        }
        rootClassName={'arrow-center'}
        {...restProps}
      >
        <div className={'app-ui-dropdown-select__label'}>
          {typeof label === 'string' ? (
            <AppText type={'note'} text={label} className={'ellipses'} />
          ) : (
            <div className={'app-ui-dropdown-select__label_child'}>{label}</div>
          )}
          <AppFontIcon
            icon={isDropdownSelectOpen ? 'arrow-full-up' : 'arrow-full-down'}
            color={iconColor || COLOR_BLACK}
          />
        </div>
      </AppPopover>
    </div>
  );
};

export default AppDropdownSelect;
