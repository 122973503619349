import React from 'react';
import { ScoreInfoCard } from '@entities/score';
import { useScreenSize } from '@shared/hooks/useScreenSize';
import { addProductToCart, findProductInCart } from '@entities/cart';
import { IUserScoreInfoData } from '@shared/models/score';
import { clientCardComponents } from './config/clientCardComponentsMap';
import { useAppSelector } from '@app/store/hooks';
import { AppSkeleton } from '@ui-kit';
import { FetchStatuses } from '@shared/constants/fetch';
import { YandexMetric } from '@entities/yandex';
import { IProductData } from '@shared/models/product';

/** */
const ScoreInfo = () => {
  const userInfo = useAppSelector((state) => state.user.data);
  const userInfoFetchStatus = useAppSelector((state) => state.user.status);

  const isUserInfoFetching = userInfoFetchStatus === FetchStatuses.PENDING;
  const isUserFirstFetching = !userInfo.email;

  const clientStatusInfo: IUserScoreInfoData = {
    userStatus: userInfo?.status,
    score: userInfo.bonus,
    myBonus: userInfo.percentMy || 0,
    clientsBonus: userInfo.percentMyClients,
    referralsBonus: userInfo.percentMyReferrals,
    refLink: userInfo.referralLink,
    dateReferral: userInfo.dateReferral,
    bonusInfo: 'Купите две упаковки LifeFactor получайте 20% баллами с каждой покупки',
  };

  const { calculateScreenType } = useScreenSize();
  const isAdaptive = calculateScreenType('mobile');

  /** */
  const onFooterButtonClick = () => {
    const lifeFactorId = 130;
    const lifeFactorCount = 2;
    const lifeFactorInCart = findProductInCart(lifeFactorId);
    if (lifeFactorInCart.count < lifeFactorCount) {
      addProductToCart(lifeFactorId.toString(), lifeFactorCount);
      const lifeFactorProduct: IProductData = {
        externalId: '130',
        imageUrl: 'https://biorich.com/wp-content/uploads/2022/11/3-1.png',
        type: 'product',
        offer: {
          id: 144,
          price: 2790,
          quantity: 155,
          unit: {
            code: 'pc',
            name: 'Штука',
            sym: 'шт.',
          },
          weight: 270,
        },
        name: 'Life Factor',
        count: 2,
        amount: 5580,
        bonus: 558,
      };
      YandexMetric.trackAddToCart([lifeFactorProduct]);
    }
  };

  return isUserInfoFetching && isUserFirstFetching ? (
    <AppSkeleton height={250} />
  ) : (
    <ScoreInfoCard
      {...clientCardComponents(clientStatusInfo, isAdaptive, onFooterButtonClick)}
      clientStatus={clientStatusInfo.userStatus}
    />
  );
};

export default ScoreInfo;
