import React, { FC } from 'react';
import { AppModal, AppText, LabelValue } from '@ui-kit';
import './OrderDetailsModal.scss';
import { ProductCardSliderList } from '@entities/product';
import {
  IOrderPaymentInfo,
  OrderDeliveryInfo,
  OrderPaymentInfo,
  OrderStatusTag,
} from '@entities/order';
import { IOrderDetailsModalProps } from './OrderDetailsModal.types';
import { formatDate } from '@frontend-modules/frontend-utils';
import { getDeliveryName, getDeliveryTypeName } from '@entities/delivery';
import { getPaymentMethodName } from '@entities/payment/utils';
import { RepeatOrderButton } from '@features/order/RepeatOrder';
import { PayOrderButton } from '@features/payment/PayOrder';
import { COLOR_TITLE_BLACK } from '@shared/constants/colors';

/**
 * @description Модалка информации о заказе
 * @param props
 */
const OrderDetailsModal: FC<IOrderDetailsModalProps> = (props) => {
  const { open: isOpen, data, onCancel } = props;

  const productCount = data.products?.reduce((acc, product) => product.count + acc, 0);

  const isShowPaymentButton = data.status === 'payment';

  const paymentData: IOrderPaymentInfo = {
    orderQuantity: productCount,
    orderAmount: data.amountOrder,
    amountTotal: data.amountTotal,
    // sale: -432,
    // promoCodeSale: 350,
    scoreSale: data?.bonusDebt,
    deliveryPrice: data.amountDelivery,
    title: getPaymentMethodName(data.typePayment),
  };

  const deliveryData = {
    name: data.fullName,
    phone: data.phone,
    deliveryName: `${getDeliveryName(data.typeSource, data.typeDelivery)} (${getDeliveryTypeName(
      data.typeDelivery,
    )})`,
    address: `${data.postalCode}, ${data.addressText}`,
    orderNumber: data.number,
    date: formatDate(data.createAt),
    track: data?.trackNumber,
    comment: data.comment,
  };

  const onRepeatOrderClick = () => {
    onCancel();
  };

  return (
    <AppModal
      open={isOpen}
      onCancel={onCancel}
      title={
        <div className={'order-details-modal-title'}>
          <AppText
            text={'Информация о заказе'}
            tag={'h3'}
            type={'title-h3'}
            color={COLOR_TITLE_BLACK}
          />
          <OrderStatusTag status={data?.status} />
        </div>
      }
      width={680}
    >
      <div className={'order-details-modal'}>
        <ProductCardSliderList productList={data.products} />
        <OrderPaymentInfo {...paymentData} />
        <OrderDeliveryInfo {...deliveryData} />
        <div className={'order-details-modal-footer'}>
          <div className={'order-details-modal-footer__content'}>
            <LabelValue label={'Номер заказа: '} direction={'horizontal'}>
              <AppText text={data.number} />
            </LabelValue>
            <LabelValue label={'Дата: '} direction={'horizontal'}>
              <AppText text={formatDate(data.createAt)} />
            </LabelValue>
          </div>
          {isShowPaymentButton ? (
            <PayOrderButton orderId={data.number} />
          ) : (
            <RepeatOrderButton productList={data.products} onClick={onRepeatOrderClick} />
          )}
        </div>
      </div>
    </AppModal>
  );
};

export default OrderDetailsModal;
