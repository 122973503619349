import React from 'react';
import { CreateOrderForm } from '@widgets/order/CreateOrderForm';
import './CartPage.scss';

/** */
const CartPage = () => {
  return (
    <div className={'cart-page'}>
      <CreateOrderForm />
    </div>
  );
};

export default CartPage;
