import React, { FC } from 'react';
import './AuthLayoutHeader.scss';
import { IAuthHeaderProps } from './AuthLayoutHeader.types';
import { LogoButton } from '@ui-kit';

/**
 *
 * @param props
 */
const AuthLayoutHeader: FC<IAuthHeaderProps> = (props) => {
  const { headerLink, logoButtonLink } = props;

  return (
    <div className={'auth-ui-header'}>
      <LogoButton logoButtonLink={logoButtonLink} />
      {headerLink && headerLink}
    </div>
  );
};

export default AuthLayoutHeader;
