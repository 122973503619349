import React from 'react';
import './AppSider.scss';
import Sider from 'antd/es/layout/Sider';
import { IAppSiderProps } from './AppSider.types';

/**
 * @description - сайдбар
 * @param props
 */
const AppSider = (props: IAppSiderProps) => {
  const { children, header, footer, onHover, collapsible: isCollapsible, ...restProps } = props;

  /**
   *
   * @param value
   */
  const handleHover = (value: boolean) => {
    if (isCollapsible) {
      onHover?.(value);
    }
  };

  return (
    <Sider
      className={'app-ui-sider'}
      {...restProps}
      collapsed={isCollapsible}
      collapsible={isCollapsible}
      collapsedWidth="60"
      trigger={null}
      onMouseEnter={() => handleHover(false)}
      onMouseLeave={() => handleHover(true)}
    >
      {header && <div className={'app-ui-sider__header'}>{header}</div>}
      <div className={'app-ui-sider__menu'}>{children}</div>
      {footer && <div className={'app-ui-sider__footer'}>{footer}</div>}
    </Sider>
  );
};

export default AppSider;
