import React from 'react';
import './AppEmptyData.scss';
import { AppButton, AppText } from '@ui-kit';
import { COLOR_GRAY_MAX } from '@shared/constants/colors';
import { IAppEmptyDataProps } from './AppEmptyData.types';

/**
 *
 * @param props
 */
const AppEmptyData = (props: IAppEmptyDataProps) => {
  const {
    title,
    description,
    bgColor,
    imageUrl,
    type = 'center',
    buttonText,
    onButtonClick,
    descriptionConfig,
    titleConfig,
    buttonConfig,
  } = props;

  return (
    <div
      className={'empty-data__wrapper'}
      style={{ backgroundImage: `url(${imageUrl})`, backgroundColor: bgColor || 'inherit' }}
    >
      <div className={`${type === 'center' ? 'empty-data' : 'empty-data__left'}`}>
        <div className={'empty-data__title'}>
          <AppText text={title} type={'title-h4'} {...titleConfig} />
        </div>
        <div className={'empty-data__description'}>
          {description && (
            <AppText
              text={description}
              type={'subtitle'}
              color={COLOR_GRAY_MAX}
              {...descriptionConfig}
            />
          )}
        </div>
        {buttonText && (
          <div className={'empty-data__btn'}>
            <AppButton
              onClick={onButtonClick}
              text={buttonText}
              type={'black'}
              {...buttonConfig}
              size={buttonConfig?.size || 'large'}
              textType={buttonConfig?.textType || 'note'}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AppEmptyData;
