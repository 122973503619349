import { useEffect } from 'react';
import { isProduction } from '@shared/utils/environment';

/**
 * @description - подключаем скрипт Техподдержки
 * только на PRODUCTION
 * @param props
 */
const TechSupportProvider = (props) => {
  const { children } = props;
  useEffect(() => {
    if (isProduction()) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = '//c.retailcrm.tech/widget/loader.js';
      script.async = true;
      document.head.appendChild(script);

      // Добавляем переменную _rcct к глобальному объекту window
      (window as any)._rcct = '00cb6a3ddd30d0b6bd37806dd23ead91e3820c901ebe29ca4a1422fd34105afa';

      return () => {
        // Очищаем скрипт при размонтировании компонента, если это необходимо
        document.head.removeChild(script);
        // Очищаем переменную _rcct при размонтировании компонента, если это необходимо
        delete (window as any)._rcct;
      };
    }
  }, []);

  return children;
};

export default TechSupportProvider;
