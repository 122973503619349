import Cookies, { CookieAttributes } from 'js-cookie';

const getCookie = (name: string) => {
  return Cookies.get(name);
};

const setCookie = (name: string, value: string | number, config?: CookieAttributes) => {
  return Cookies.set(name, String(value), config);
};

const removeCookie = (name: string) => {
  return Cookies.remove(name);
};

/**
 * @description       - Подписка на куки через долбить по таймеру в interval
 * @param cookieName
 * @param callback
 * @param interval
 */
const subscribeToCookieChanges = (
  cookieName: string,
  callback: (value: string) => void,
  interval = 1000,
): (() => void) => {
  let lastCookieValue = getCookie(cookieName);
  /** */
  const checkCookieChanges = () => {
    const currentCookieValue = getCookie(cookieName);
    if (currentCookieValue !== lastCookieValue) {
      callback(currentCookieValue || '');
      lastCookieValue = currentCookieValue || '';
    }
  };
  checkCookieChanges();
  const intervalId = setInterval(checkCookieChanges, interval);
  return () => {
    clearInterval(intervalId);
  };
};

export { getCookie, setCookie, removeCookie, subscribeToCookieChanges };
