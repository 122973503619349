import React from 'react';
import { AppText, LabelValue } from '@ui-kit';
import { orderTagStatus } from '../../config/OrderTagStatus';
import './OrderCard.scss';
import { IOrderCardProps } from './OrderCard.types';
import { COLOR_GRAY_MEDIUM } from '../../../../shared/constants/colors';
import { formatDate, trimSeparator } from '@frontend-modules/frontend-utils';

/**
 *
 * @param props
 */
const OrderCard = (props: IOrderCardProps) => {
  const {
    data,
    onOrderNumberClick,
    direction = 'horizontal',
    cardSize = 'medium',
    scoreParam,
    controls,
    quantityProductDropDown,
    isShowDate,
  } = props;

  const { number, status, createAt: date, amountTotal } = data;
  return (
    <div className={`order-card ${cardSize}`}>
      <div className={`order-card__header`}>
        <div className={`order-card__header-left`}>
          <AppText
            type={'note-bold'}
            text={`№ ${number}`}
            onClick={() => onOrderNumberClick(data)}
          />
          {!isShowDate && status ? (
            <AppText text={orderTagStatus[status].text} color={orderTagStatus[status].color} />
          ) : (
            <AppText text={formatDate(date)} color={COLOR_GRAY_MEDIUM} />
          )}
        </div>
        <div className={`order-card__header-right`}>{controls}</div>
      </div>

      <div className={'order-card__content'}>
        {!isShowDate && status ? (
          <LabelValue
            label={'Дата'}
            value={formatDate(date)}
            valueConfig={{ type: 'subtitle', fontWeight: 400 }}
          />
        ) : (
          <>{quantityProductDropDown}</>
        )}

        <LabelValue
          label={'Стоимость'}
          value={`${trimSeparator(amountTotal)} руб.`}
          direction={direction}
          valueConfig={{ type: 'subtitle', fontWeight: 400 }}
        />
        <div className={'order-card__content-right'}>
          <LabelValue label={'Баллы'} direction={direction}>
            {scoreParam}
          </LabelValue>
        </div>
      </div>
    </div>
  );
};

export default OrderCard;
