import React from 'react';
import { Radio as AntdRadio } from 'antd';
import './AppRadio.scss';
import { TAppRadioProps } from './AppRadio.types';

/**
 *
 * @param props
 */
const AppRadio = (props: TAppRadioProps) => {
  const { type = 'primary', disabled, ...restProps } = props;

  const localType = disabled ? 'disabled' : type;
  return (
    <div className={`app-ui-radio`}>
      <AntdRadio {...restProps} className={localType} disabled={disabled} />
    </div>
  );
};

export default AppRadio;
