import React, { useState } from 'react';
import './OrderFormProducts.scss';
import { AppCollapse, AppText, LabelValue } from '@ui-kit';
import { COLOR_BLACK, COLOR_GRAY_MINI, COLOR_ORANGE } from '@shared/constants/colors';
import { AppDivider } from '@shared/ui-kit';
import { ScoreBalance } from '@entities/score';
import { ProductCard, ProductCardVerticalList } from '@entities/product';
import { useScreenSize } from '@shared/hooks/useScreenSize';
import { getNoun, trimSeparator } from '@frontend-modules/frontend-utils';
import { useAppSelector } from '@app/store/hooks';
import { TOrderFormProductsProps } from './OrderFormProducts.types';
import { renderDeliveryAmount } from '@entities/delivery';
import { ChangeAmountProductsInCart, RemoveProductFromCartButton } from '@features/cart';

/**
 * @description - компонент списка продуктов в создании товара
 * @param props
 */
const OrderFormProducts = (props: TOrderFormProductsProps) => {
  const {
    cartList,
    deliveryAmount,
    isPublic,
    onRemoveClick,
    onChangeCount,
    bonusesToPay,
    bonusWillAdd,
    isLoading,
  } = props;

  const { calculateScreenType } = useScreenSize();

  const [isCollapse, setIsCollapse] = useState(calculateScreenType('tablet'));
  const onCollapseClick = (isCollapsed: boolean) => {
    setIsCollapse(isCollapsed);
  };

  const { minSumPayment: MIN_SUM_PAY, freeDelivery: FREE_DELIVERY_SUM } = useAppSelector(
    (state) => state.meta.data,
  );
  // const userDiscountPercent = useAppSelector((state) => state.user.data.percentMy);

  const cartListCost = cartList.reduce((total, product) => {
    const productCost = product.offer?.price * product.count;
    return total + productCost;
  }, 0);
  const isMoreThenFreeDeliverySum = cartListCost >= FREE_DELIVERY_SUM;
  const costDelta = FREE_DELIVERY_SUM - cartListCost;
  const totalCost =
    (isMoreThenFreeDeliverySum ? 0 : deliveryAmount || 0) + (cartListCost || 0) - bonusesToPay;
  // const bonusWillAdd = Math.round(((cartListCost - bonusesToPay) * userDiscountPercent) / 100);
  const renderTotalCost = totalCost > MIN_SUM_PAY ? totalCost : MIN_SUM_PAY;
  /** */
  const RenderToPay = () => {
    return (
      <LabelValue
        label={'К оплате'}
        labelConfig={{ color: COLOR_BLACK, type: 'note', fontWeight: 600 }}
        valueConfig={{ color: COLOR_BLACK, type: 'note', fontWeight: 600 }}
        value={`${trimSeparator(renderTotalCost)} руб.`}
        direction={'horizontal'}
      />
    );
  };
  return (
    <div
      className={`order-form-products ${isPublic ? 'public_products' : ''} ${
        isLoading ? 'loading' : ''
      }`}
    >
      <AppCollapse
        isCollapsed={isCollapse}
        onClick={onCollapseClick}
        items={[
          {
            key: '1',
            label: <AppText text={'Продукты'} type={'subtitle'} fontWeight={600} />,
            children: (
              <>
                <ProductCardVerticalList
                  productKey={['externalId', 'count']}
                  productList={cartList}
                  renderProduct={(product) => (
                    <ProductCard
                      productData={product}
                      actionButton={
                        <RemoveProductFromCartButton product={product} onClick={onRemoveClick} />
                      }
                      bottomActionBlock={
                        <ChangeAmountProductsInCart product={product} onChange={onChangeCount} />
                      }
                    />
                  )}
                />
                <div className={'order-form-products__labels'}>
                  {/*<ApplyPromoCode />*/}
                  <div className={'order-form-products__labels__hint'}>
                    <AppText
                      text={
                        isMoreThenFreeDeliverySum
                          ? `Заказ более ${trimSeparator(FREE_DELIVERY_SUM)} | Доставка бесплатна`
                          : `Закажите еще на ${trimSeparator(
                              costDelta,
                            )} руб. и доставка будет бесплатна`
                      }
                      type={'small'}
                      color={COLOR_BLACK}
                    />
                  </div>
                  <AppDivider color={COLOR_GRAY_MINI} />
                  <div className={'order-form-products__labels__prices'}>
                    <LabelValue
                      label={'Всего'}
                      value={`${trimSeparator(cartListCost)} руб.`}
                      direction={'horizontal'}
                    />
                    {!isMoreThenFreeDeliverySum && typeof deliveryAmount === 'number' && (
                      <LabelValue
                        label={'Доставка'}
                        value={renderDeliveryAmount(deliveryAmount)}
                        direction={'horizontal'}
                      />
                    )}

                    {/*<LabelValue*/}
                    {/*  label={'Скидка'}*/}
                    {/*  labelConfig={{ color: COLOR_BLACK }}*/}
                    {/*  value={`${228} руб.`}*/}
                    {/*  direction={'horizontal'}*/}
                    {/*/>*/}
                    {bonusesToPay && !isPublic ? (
                      <LabelValue
                        label={'Списание баллов'}
                        labelConfig={{ color: COLOR_ORANGE }}
                        direction={'horizontal'}
                      >
                        <ScoreBalance score={bonusesToPay} type={'small'} />
                      </LabelValue>
                    ) : null}
                  </div>
                  {!isCollapse && <RenderToPay />}
                  {!isPublic && bonusWillAdd > 0 ? (
                    <div className={'order-form-products__labels__results'}>
                      <AppText
                        type={'body'}
                        color={COLOR_ORANGE}
                        text={`Будет ${getNoun(
                          bonusWillAdd,
                          'начислен',
                          'начислено',
                          'начислено',
                        )}`}
                      />
                      <ScoreBalance isWithPlus score={bonusWillAdd} type={'small'} isWithPostWord />
                    </div>
                  ) : null}
                </div>
              </>
            ),
          },
        ]}
      />
      {isCollapse && <RenderToPay />}
    </div>
  );
};

export default OrderFormProducts;
