import React, { FC } from 'react';
import { IAuthLayoutProps } from './AuthLayout.types';
import './AuthLayout.scss';
import { AuthLayoutHeader } from './ui';

/**
 * @description - Layout авторизации
 * @param props
 */
const AuthLayout: FC<IAuthLayoutProps> = (props) => {
  const { children, pageType, headerLink, logoButtonLink } = props;

  return (
    <div className={'auth-layout'}>
      <div className={`auth-img ${pageType}`} />
      <div className={'auth-content'}>
        <AuthLayoutHeader headerLink={headerLink} logoButtonLink={logoButtonLink} />
        {children}
      </div>
    </div>
  );
};

export default AuthLayout;
