import React from 'react';
import { AuthLayout } from '@widgets/layouts/AuthLayout';
import { AppTextIconLink } from '@ui-kit';
import { PUBLIC_ROUTES } from '@shared/constants/routes';
import { AuthRecoveryForm } from '@features/auth/AuthPasswordRecoveryForm';

/** */
const RecoveryPage = () => {
  return (
    <div className={'authorization-page'}>
      <AuthLayout
        logoButtonLink={PUBLIC_ROUTES.AUTHORIZATION}
        pageType={'recovery'}
        headerLink={
          <AppTextIconLink
            isNested={false}
            link={PUBLIC_ROUTES.AUTHORIZATION}
            text={'Вернуться назад'}
          />
        }
      >
        <AuthRecoveryForm />
      </AuthLayout>
    </div>
  );
};

export default RecoveryPage;
