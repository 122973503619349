import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { IAxiosResponse, IBaseHttpClient, IBaseHttpClientOptions } from './BaseHttpClient.types';

/**
 * @description Базовый класс для работы с сервером
 * НИКОГДА НЕ ИСПОЛЬЗУЙ НАПРЯМУЮ, ТОЛЬКО ЧЕРЕЗ НАСЛЕДОВАНИЕ!
 * @returns {Promise<IAxiosResponse>}
 */
export class $BaseHttpClient implements IBaseHttpClient {
  readonly instance: AxiosInstance;

  constructor(options?: IBaseHttpClientOptions) {
    this.instance = axios.create({
      // eslint-disable-next-line no-underscore-dangle
      baseURL: options?._baseURL,
      // eslint-disable-next-line no-underscore-dangle
      headers: options?._headers,
    });

    this.initializeResponseInterceptor();
  }

  private initializeResponseInterceptor() {
    // в случае необходимости можно указать глобальные интерсепеторы для всех экземпляров HttpClient
    // this.instance.interceptors.response.use()
  }

  /**
   * @description удаляет пустые свойства объекта
   * @param params
   * @param delEmptyParamsEnable
   */
  protected delEmptyParams(params?: object, delEmptyParamsEnable?: boolean): object | undefined {
    if (delEmptyParamsEnable) {
      for (const key in params) {
        if (params[key] === undefined || params[key] === '') {
          delete params[key];
        }
      }
    }
    return params;
  }

  /**
   * @description - универсальный запрос который регулируется через конфиг
   * @param config
   */
  async request(config: AxiosRequestConfig): Promise<IAxiosResponse> {
    return this.instance.request(config);
  }

  async get(
    resource: string,
    params?: object,
    conf?: {
      isDelEmptyParams: boolean;
      abortKey?: string;
    },
  ): Promise<IAxiosResponse> {
    const { isDelEmptyParams = true, abortKey } = conf || {};
    const newConfig = {
      params: this.delEmptyParams(params, isDelEmptyParams),
      abortKey,
    };
    return this.instance.get(resource, newConfig);
  }

  async post<T>(
    resource: string,
    body?: object,
    params?: object,
    conf?: {
      isDelEmptyParams: boolean;
      abortKey?: string;
    },
  ): Promise<IAxiosResponse<T>> {
    const { isDelEmptyParams = true, abortKey } = conf || {};
    const newConfig = {
      params: this.delEmptyParams(params, isDelEmptyParams),
      abortKey,
    };
    return this.instance.post(resource, this.delEmptyParams(body, isDelEmptyParams), newConfig);
  }

  async delete(
    resource: string,
    body?: object,
    params?: object,
    conf?: {
      isDelEmptyParams: boolean;
      abortKey?: string;
    },
  ): Promise<IAxiosResponse> {
    const { isDelEmptyParams = true, abortKey } = conf || {};
    const newConfig = {
      params: this.delEmptyParams(params, isDelEmptyParams),
      abortKey,
    };
    return this.instance.delete(resource, newConfig);
  }

  async put(
    resource: string,
    body?: object,
    params?: object,
    conf?: {
      isDelEmptyParams: boolean;
      abortKey?: string;
    },
  ): Promise<IAxiosResponse> {
    const { isDelEmptyParams = true, abortKey } = conf || {};
    const newConfig = {
      params: this.delEmptyParams(params, isDelEmptyParams),
      abortKey,
    };
    return this.instance.put(resource, this.delEmptyParams(body, isDelEmptyParams), newConfig);
  }

  async patch(
    resource: string,
    body?: object,
    params?: object,
    conf?: {
      isDelEmptyParams: boolean;
      abortKey?: string;
    },
  ): Promise<IAxiosResponse> {
    const { isDelEmptyParams = true, abortKey } = conf || {};
    const newConfig = {
      params: this.delEmptyParams(params, isDelEmptyParams),
      abortKey,
    };
    return this.instance.patch(resource, this.delEmptyParams(body, isDelEmptyParams), newConfig);
  }
}
