import React from 'react';
import { CreateOrderForm } from '@widgets/order/CreateOrderForm';
import { PromoRegistrationBlock } from '@entities/auth';

/** */
const PublicCartPage = () => {
  return <CreateOrderForm isPublic promoComponent={<PromoRegistrationBlock />} />;
};

export default PublicCartPage;
