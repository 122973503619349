export const UNKNOWN_ERROR = 'Ой, что-то пошло не так!';
export const VALIDATION_ERROR = 'Ошибка проверки данных!';
export const NOT_FOUND_ERROR = 'Данные не найдены!';
export const EMPTY_ERROR = 'Поле не может быть пустым!';
export const PASSWORD_CONFIRM_ERROR = 'Пароли должны совпадать!';

export const ERROR_MESSAGE = {
  UNKNOWN_ERROR,
  VALIDATION_ERROR,
  NOT_FOUND_ERROR,
};
