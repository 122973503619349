import React, { useEffect } from 'react';
import { AppButton, AppCarousel, AppLink } from '@ui-kit';
import { loadPromoAction } from '@entities/promo';
import { useAppDispatch, useAppSelector } from '@app/store/hooks';
import { useScreenSize } from '@shared/hooks/useScreenSize';
import './PromoSlider.scss';

/** */
const PromoSlider = () => {
  const { calculateScreenType } = useScreenSize();
  const isAdaptive = calculateScreenType('mobile');

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(loadPromoAction());
  }, []);

  const promoData = useAppSelector((state) => state.promo.data);

  const onChange = () => {};

  return (
    <div className={`promo-slider ${isAdaptive ? 'mobile' : 'desktop'}`}>
      <AppCarousel autoplay autoplaySpeed={7500} afterChange={onChange}>
        {promoData?.map(({ link, mobile, dekstop }) => (
          <div key={link} className={'promo-slider__item'}>
            <div
              className={'promo-slider__item-image'}
              style={{
                backgroundImage: `url(${isAdaptive ? mobile : dekstop})`,
              }}
            />
            <AppLink
              isNested={false}
              target={'_blank'}
              link={link}
              className={'promo-slider__item_link'}
            >
              <AppButton text={'Подробнее'} textType={'body'} type={'black'} size={'medium'} />
            </AppLink>
          </div>
        ))}
      </AppCarousel>
    </div>
  );
};

export default React.memo(PromoSlider);
