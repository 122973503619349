export const COLOR_LIGHT_GREEN = '#54E168';
export const COLOR_DARK_GREEN = '#0CAE22';
export const COLOR_DASHED = '#DDE0EE';
export const COLOR_GRAY_MINI = '#EDEDF0';
export const COLOR_GRAY_MAX = '#6A6D7E';
export const COLOR_GRAY_MEDIUM = '#A9ADC5';
export const COLOR_HOVER = '#F5F7F8';
export const COLOR_ERROR = '#FF002E';
export const COLOR_SUCCESS = '#16C72E';
export const COLOR_WHITE = '#FFFFFF';
export const COLOR_BLACK = '#13141F';
export const COLOR_TITLE_BLACK = '#101C34';
export const COLOR_ORANGE = '#FF9900';
export const COLOR_BLUE = '#0286FF';
export const COLOR_DISABLED = '#DFE1EA';

export const COLOR_CLIENT_BG = '#E8F9EA';
export const COLOR_REFERRAL_BG = '#FFF5E6';
export const EMPTY_ORDERS_BG = '#F2F5F9';

export const COLOR_VK = '#2787F5';
export const COLOR_TELEGRAM = '#28AFEA';
export const COLOR_DZEN = '#000000';

export const COLOR_LIGHT_SUCCESS = 'rgba(22, 199, 46, 0.1)';
export const COLOR_LIGHT_ERROR = 'rgba(255, 0, 46, 0.1)';
export const COLOR_LIGHT_BLUE = 'rgba(2, 134, 255, 0.1)';
