import React from 'react';
import { AuthLayout } from '@widgets/layouts/AuthLayout';
import { PUBLIC_ROUTES } from '@shared/constants/routes';
import { AuthPasswordResetForm } from '@features/auth/AuthPasswordResetForm';

/** */
const ResetPasswordPage = () => {
  return (
    <div className={'authorization-page'}>
      <AuthLayout logoButtonLink={PUBLIC_ROUTES.AUTHORIZATION} pageType={'reset'}>
        <AuthPasswordResetForm />
      </AuthLayout>
    </div>
  );
};

export default ResetPasswordPage;
