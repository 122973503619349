import React from 'react';
import './AppTableHeaderSortCell.scss';
import { IAppTableHeaderSortCellProps } from './AppTableHeaderSortCell.types';
import { AppFontIcon, AppText } from '@ui-kit';

/**
 *
 * @param props
 */
const AppTableHeaderSortCell = (props: IAppTableHeaderSortCellProps) => {
  const { text, onClick, align = 'start', isSorting } = props;

  return (
    <div className={`sort-toggler align-${align}`} onClick={() => onClick?.()}>
      <AppText text={text} />
      <AppFontIcon icon={isSorting ? 'arrow-full-up' : 'arrow-full-down'} iconSize={20} />
    </div>
  );
};

export default AppTableHeaderSortCell;
