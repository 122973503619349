import { useEffect } from 'react';
import { useAppDispatch } from '@app/store/hooks';
import { loadMetaAction } from '@entities/meta';

/**
 * @description - провайдер для подгрузки мета-данных
 * @param prm
 * @param prm.children
 */
const MetaProvider = ({ children }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(loadMetaAction());
  }, [dispatch]);

  return children;
};

export default MetaProvider;
