import { OrderHistoryTable } from '@widgets/order/OrderHistoryTable';
import './HistoryPage.scss';
import React from 'react';

/** */
const HistoryPage = () => {
  return (
    <div className={'history-page'}>
      <OrderHistoryTable />
    </div>
  );
};

export default HistoryPage;
