import { createSlice } from '@reduxjs/toolkit';
import { TAsyncState } from '@shared/models/async-state';
import { IErrorResponse } from '@shared/models/errors';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { logoutAction } from '@entities/auth';
import { IPromo } from '@shared/models/promo';
import { loadPromoAction } from './promoActions';
import { TFetchStatus } from '@shared/constants/fetch';

interface IPromoState extends TAsyncState {
  patchPromoStatus: TFetchStatus;
  patchPromoError: IErrorResponse;
  data: IPromo[] | null;
}

const initialState: IPromoState = {
  status: 'idle',
  error: null,
  data: null,
  patchPromoError: null,
  patchPromoStatus: 'idle',
};

const promoSlice = createSlice({
  name: 'promo',
  initialState,
  reducers: {
    clearPatchUserError(state) {
      state.patchPromoError = null;
    },
  },
  /**
   *
   * @param builder
   */
  extraReducers: (builder) => {
    builder
      .addCase(loadPromoAction.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(loadPromoAction.fulfilled, (state, { payload }) => {
        state.status = 'fulfilled';
        state.data = payload.results;
      })
      .addCase(loadPromoAction.rejected, (state, { error }) => {
        state.status = 'rejected';
        state.error = error;
      });

    builder.addCase(logoutAction, () => {
      return initialState;
    });
  },
});

export const promoReducer = promoSlice.reducer;

export const promoPersistConfig = {
  key: 'biorich::promo',
  storage,
  version: 1,
};

export const persistedPromoReducer = persistReducer(promoPersistConfig, promoReducer);
