import React, { FC, useEffect, useState } from 'react';
import { AppFontIcon, AppSelectCard, AppText } from '@ui-kit';
import './ChooseDeliveryMethodCards.scss';
import { COLOR_DISABLED, COLOR_GRAY_MAX } from '@shared/constants/colors';
import { IChooseDeliveryMethodCardsProps } from './ChooseDeliveryMethodCards.types';
import { useAppSelector } from '@app/store/hooks';

/**
 * @description - компонент выбора способка доставки (вид карточка)
 * @param props
 */
const ChooseDeliveryMethodCards: FC<IChooseDeliveryMethodCardsProps> = (props) => {
  const {
    selectedAddress = '',
    onChange,
    isAddressValid,
    mapRef,
    selectedMethod,
    isDisabled,
  } = props;

  const { workTimeExpressDelivery } = useAppSelector((state) => state.meta.data);

  const [isExpressAllow, setExpressAllow] = useState(false);

  useEffect(() => {
    if (selectedAddress) {
      mapRef?.geocode(selectedAddress).then((response) => {
        if (response) {
          const firstGeoObject = response.geoObjects.get(0);
          if (firstGeoObject) {
            const isMoscow =
              firstGeoObject.properties
                .get('metaDataProperty.GeocoderMetaData.Address.Components')
                .find((item) => item.kind === 'locality').name === 'Москва';
            setExpressAllow(isMoscow);
          }
        } else {
          setExpressAllow(false);
        }
      });
    } else {
      setExpressAllow(false);
    }
  }, [mapRef, selectedAddress]);

  const isDisabledCards = ((!selectedAddress || !isAddressValid) && !selectedMethod) || isDisabled;

  return (
    <div className={'choose-delivery-method-cards'}>
      <AppSelectCard
        title={'До пункта выдачи'}
        type={'radio'}
        isActive={selectedMethod === 'pvz'}
        isDisabled={isDisabledCards}
        onClick={() => {
          onChange?.('pvz');
        }}
        topContent={
          <AppFontIcon
            icon={'pick-up-point'}
            color={isDisabledCards ? COLOR_DISABLED : undefined}
          />
        }
      />
      <AppSelectCard
        title={'До двери'}
        type={'radio'}
        isActive={selectedMethod === 'to_door'}
        isDisabled={isDisabledCards}
        onClick={() => {
          onChange?.('to_door');
        }}
        topContent={
          <AppFontIcon icon={'door'} color={isDisabledCards ? COLOR_DISABLED : undefined} />
        }
      />
      {isExpressAllow && (
        <AppSelectCard
          title={'Экспресс доставка'}
          type={'radio'}
          isActive={selectedMethod === 'express'}
          isDisabled={isDisabledCards}
          onClick={() => {
            onChange?.('express');
          }}
          topContent={
            <AppFontIcon icon={'express'} color={isDisabledCards ? COLOR_DISABLED : undefined} />
          }
          rightElement={
            selectedMethod === 'express' && (
              <div className={'express-hint'}>
                <AppText
                  type={'small'}
                  color={COLOR_GRAY_MAX}
                  text={'Внимание! Экспресс доставка работает только в будние дни'}
                />
                <AppText type={'body'} text={workTimeExpressDelivery} />
              </div>
            )
          }
        />
      )}
    </div>
  );
};

export default ChooseDeliveryMethodCards;
