export const PRODUCT_CARD_WIDTH = 175;
export const PRODUCTS_SLIDER_GAP = 20;

export const PRODUCT_CARD_SLIDER_PROPS = {
  arrows: true,
  variableWidth: true,
  dots: false,
  isSlider: true,
  infinite: false,
};
