export enum Endpoints {
  ORDERS_CREATE = 'api/v1/order/create/',
  ORDERS = 'api/v1/order/',
  ORDERS_BONUS = 'api/v1/order/bonus/',
  ORDERS_PAYMENT = 'api/v1/order/payment/',
  USERS_REGISTER = 'api/v1/user/register/',
  USERS_DETAILS = 'api/v1/user/',
  TOKEN = 'api/v1/token/',
  TOKEN_REFRESH = 'api/v1/token/refresh/',
  PASSWORD_RESET_REQUEST = 'api/v1/password-reset/',
  PASSWORD_RESET_CONFIRM = 'api/v1/password-reset/confirm/',
  PASSWORD_RESET_VALIDATE = 'api/v1/password-reset/validate-token/',
  CART = 'api/v1/cart/',
  DELIVERY_POINTS = 'api/v1/deliverypoints/',
  DELIVERY_CALCULATION = 'api/v1/delivery/calculation/',
  CONSTANTS = 'api/v1/constant/',
  PRODUCTS = 'api/v1/products/',
  BANNER = 'api/v1/banner/',
  CALLBACK = 'api/v1/callback/',
  SUGGEST = 'api/v1/suggest/',
  MAPS_SEARCH = 'api/v1/api-maps/search/',
}
