import React, { FC, useEffect, useRef, useState } from 'react';
import './NavbarMobile.scss';
import { AppButton, AppMenu, AppTour, LogoButton } from '@ui-kit';
import { IAdaptiveNavbarProps } from './NavbarMobile.types';
import { TourProps } from 'antd';
import { NavbarDropdown } from '../NavbarDropdown';
import { ScoreBalance } from '@entities/score';
import { sidebarItems } from '../Sidebar';
import { SidebarFooter } from '../SidebarFooter';
import { CartNavigateButton } from '@entities/cart';

/**
 *
 * @param props
 */
const NavbarMobile: FC<IAdaptiveNavbarProps> = (props) => {
  const { logoButtonLink, onPersonInfoOptionClick, userName, email, score, isEmptyCart } = props;
  const menuRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const onCloseMenuClick = () => {
    setIsOpen(false);
  };

  const onPhoneClickHandler = () => {
    onCloseMenuClick();
  };

  const onMenuButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const menuSteps: TourProps['steps'] = [
    {
      title: '',
      target: menuRef.current,
      className: 'app-ui-tour-description',
      description: (
        <>
          <div className={'app-ui-tour-description-header'}>
            <div className={'app-ui-tour-description-header_controls'}>
              <NavbarDropdown
                userName={userName}
                userEmail={email}
                isAdaptive
                onClick={onPersonInfoOptionClick}
              />
              <CartNavigateButton onClick={onCloseMenuClick} isEmptyCart={isEmptyCart} />
            </div>

            <ScoreBalance score={score} isWithPostWord />
          </div>
          <div className={'app-ui-tour-description-content'}>
            <AppMenu menuItems={sidebarItems} onClick={onCloseMenuClick} />
          </div>
          <div className={'app-ui-tour-description-footer'}>
            <SidebarFooter onClick={onPhoneClickHandler} isLink />
          </div>
        </>
      ),
    },
  ];

  // подсвечивает кнопку закрытия
  useEffect(() => {
    const stickyNavbar = document.querySelector('.personal-content__navbar');
    if (isOpen) {
      stickyNavbar.classList.remove('navbar_sticky');
    } else {
      stickyNavbar.classList.add('navbar_sticky');
    }
  }, [isOpen]);

  return (
    <>
      <div className={'adaptive-navbar'}>
        <LogoButton logoButtonLink={logoButtonLink} />
        <AppButton
          icon={isOpen ? 'close' : 'menu'}
          onClick={onMenuButtonClick}
          ref={menuRef}
          size="large"
          className={isOpen ? 'height-z-index_button' : ''}
        />
      </div>

      <AppTour
        scrollIntoViewOptions
        open={isOpen}
        onClose={onCloseMenuClick}
        steps={menuSteps}
        arrow={false}
        closeIcon={false}
        placement={'bottomRight'}
      />
    </>
  );
};

export default NavbarMobile;
