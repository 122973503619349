import React, { FC } from 'react';
import { AppFontIcon, AppText } from '@ui-kit';
import { COLOR_ERROR, COLOR_GRAY_MAX } from '@shared/constants/colors';
import './DeliveryPointInfoCard.scss';
import { IDeliveryPointInfoCardProps } from '@shared/models/delivery';
import {
  getDeliveryDate,
  getDeliveryName,
  getDeliveryTypeIcon,
  getDeliveryTypeName,
} from '@entities/delivery';
import { renderDeliveryAmount } from '../../utils';

/**
 * @description - карточка информации о пункте выдаче
 * @param props
 */
const DeliveryPointInfoCard: FC<IDeliveryPointInfoCardProps> = (props) => {
  const {
    pointData,
    control,
    isClosable,
    deliveryData,
    isPointError,
    isPointLoading,
    icon,
    title,
  } = props;

  return (
    <div className={'delivery-point-info-card'}>
      <div className={'delivery-point-info-card-description'}>
        <div className={'delivery-point-info-card-description__title'}>
          <div className={'delivery-point-info-card-description__title-text'}>
            <AppFontIcon
              icon={icon || getDeliveryTypeIcon(pointData?.source, pointData?.typeSource)}
              color={COLOR_GRAY_MAX}
            />
            <AppText
              text={
                pointData?.typeSource
                  ? `${getDeliveryTypeName(pointData?.typeSource)} (${getDeliveryName(
                      pointData?.source,
                    )})`
                  : title
              }
              type={'small'}
              color={COLOR_GRAY_MAX}
            />
          </div>
          {!isClosable && (
            <AppText
              type={'body'}
              text={
                isPointLoading || isPointError ? '-' : renderDeliveryAmount(deliveryData?.amount)
              }
            />
          )}
        </div>
        {isClosable && !isPointLoading && isPointError && (
          <div className={'delivery-point-info-card-description__delivery'}>
            <AppText text={`Пункт выдачи недоступен`} color={COLOR_ERROR} type={'body'} />
          </div>
        )}
        {isClosable && !isPointLoading && !isPointError && !pointData.allowedCod && (
          <div className={'delivery-point-info-card-description__delivery'}>
            <AppText text={`Оплата при получении недоступна`} color={COLOR_ERROR} type={'body'} />
          </div>
        )}
        <AppText text={pointData?.address} type={'body'} />
        {pointData?.workTime && (
          <div className={'delivery-point-info-card-description__work-time'}>
            {pointData?.workTime?.map((time) => {
              return <AppText key={time} text={time} type={'small'} color={COLOR_GRAY_MAX} />;
            })}
          </div>
        )}

        <div className={'delivery-point-info-card-description__delivery'}>
          <AppText text={`Срок доставки: `} color={COLOR_GRAY_MAX} type={'body'} />

          <AppText
            text={isPointLoading || isPointError ? '-' : getDeliveryDate(deliveryData)}
            type={'body'}
          />
        </div>
        {isClosable && (
          <>
            <div className={'delivery-point-info-card-description__delivery'}>
              <AppText text={`Стоимость: `} color={COLOR_GRAY_MAX} type={'body'} />
              <AppText
                type={'body'}
                text={
                  isPointLoading || isPointError ? '-' : renderDeliveryAmount(deliveryData?.amount)
                }
              />
            </div>
          </>
        )}
      </div>
      {control}
    </div>
  );
};

export default DeliveryPointInfoCard;
