import React from 'react';
import { CartPng } from '@entities/cart/assets';
import { AppEmptyData } from '@ui-kit';
import './CartEmpty.scss';
import { EXTERNAL_ROUTES } from '@shared/constants/routes';

/**
 * @description Корзина пуста
 */
const CartEmpty = () => {
  return (
    <div className={'cart-empty'}>
      <img src={CartPng} alt={'empty-cart'} />
      <AppEmptyData
        title={'Корзина пуста'}
        description={'Сделайте заказ прямо сейчас!'}
        onButtonClick={() => window.open(EXTERNAL_ROUTES.PRODUCTS)}
        buttonText={'Перейти к продуктам'}
      />
    </div>
  );
};

export default CartEmpty;
