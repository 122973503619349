import React, { FC } from 'react';
import { Modal } from 'antd';
import { AppButton, AppText, TAppModalProps } from '@ui-kit';
import './AppModal.scss';
import { COLOR_GRAY_MAX, COLOR_TITLE_BLACK } from '@shared/constants/colors';

/**
 *
 * @param props
 */
const AppModal: FC<TAppModalProps> = (props) => {
  const {
    className,
    children,
    title,
    description,
    isClosable = true,
    footer = null,
    isCentered = true,
    width = 408,
    onCancel,
    ...restProps
  } = props;

  return (
    <div className={`app-ui-modal`}>
      <Modal
        {...restProps}
        wrapClassName={`${className || ''} app-ui-modal-wrapper`}
        centered={isCentered}
        footer={footer}
        width={width}
        onCancel={onCancel}
        closable={isClosable}
        title={
          <div className={'app-ui-modal__header'}>
            <div className={'app-ui-modal__header_title'}>
              {typeof title === 'string' ? (
                <AppText text={title} tag={'h3'} type={'title-h3'} color={COLOR_TITLE_BLACK} />
              ) : (
                title || null
              )}
              {isClosable && (
                <div className={'app-ui-modal__header_title_close'}>
                  <AppButton icon={'close'} size={'small'} iconSize={18} onClick={onCancel} />
                </div>
              )}
            </div>
            {description && (
              <div className={'app-ui-modal__header_description'}>
                <AppText text={description} type={'note'} color={COLOR_GRAY_MAX} />
              </div>
            )}
          </div>
        }
      >
        {children}
      </Modal>
    </div>
  );
};

export { AppModal };
