import React, { useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { PUBLIC_ROUTES } from '@shared/constants/routes';
import { setCookie } from '@shared/utils/cookies';

/**
 * @description - страница обработки реферальной ссылки
 */
const ReferralLinkPage = () => {
  const { referralCode } = useParams();

  useEffect(() => {
    if (referralCode) {
      setCookie('referralCode', referralCode, { expires: 30 });
    }
  }, [referralCode]);
  return <Navigate to={PUBLIC_ROUTES.REGISTRATION} />;
};

export default ReferralLinkPage;
