import React, { FC, memo } from 'react';
import { Placemark } from '../../utils/yandex';
import { createPlacemarkFactory } from '../../utils/createPlacemarkFactory';
import './YandexCustomPlacemark.scss';
import { IYandexCustomPlacemarkProps } from './YandexCustomPlacemark.types';

/**
 * @description - компонент кастомной отметки на карте
 */
const YandexCustomPlacemark: FC<IYandexCustomPlacemarkProps> = memo((props) => {
  const { data, isActive, onClick, mapInstanceRef } = props;

  if (!mapInstanceRef.current) return null;

  const template = createPlacemarkFactory(mapInstanceRef.current)({
    onClick: () => onClick(data),
    isActive,
    type: data.source,
    isDefaultOpened: data.isDefaultOpened,
  });

  return (
    <Placemark
      geometry={[data.latitude, data.longitude]}
      options={{
        iconLayout: template,
        zIndex: isActive ? 100 : 99,
        balloonPanelMaxMapArea: 0,
      }}
      properties={{
        iconLayout: template,
        // создаём пустой элемент с заданными размерами
        balloonContent: `<div id="point-portal-${data.externalId}" class="point-portal"></div>`,
      }}
    />
  );
});

export default YandexCustomPlacemark;
