import dayjs from 'dayjs';

require('dayjs/locale/ru');

/**
 * @description - получить количество дней до определнной даты
 * @param date
 */
export const getDaysToDate = (date) => {
  const currentDate = dayjs();
  const targetDate = dayjs(date);
  return targetDate.diff(currentDate, 'day');
};

/**
 * @description - приводим дату в нужный вид
 * @param date
 * @param format
 */
export const formatToRussianDate = (date, format = 'DD.MM.YYYY') =>
  dayjs(date).locale('ru').format(format);
