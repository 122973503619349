import { IUser } from '@shared/models/user';
import { $HttpClient } from '@shared/api';
import { Endpoints } from '@shared/constants/endpoints';

/**
 * UserProvider запросы связанные с пользователем
 */

const UserProvider = {
  /**
   * @description Получение данных о пользователе
   */
  loadUser: () => {
    return $HttpClient.post<IUser>(Endpoints.USERS_DETAILS);
  },
};

export default UserProvider;
