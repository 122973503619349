import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppButton, AppInput, AppLink, AppPasswordInput, AppText } from '@ui-kit';
import './AuthLoginUserForm.scss';
import { AuthProvider, setAuth } from '@entities/auth';
import { setAccessToken, setRefreshToken } from '@shared/utils/token';
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from '@shared/constants/routes';
import { EMPTY_ERROR } from '@shared/constants/errors';
import { IAuthLoginParamsValidation } from './AuthLoginUserForm.types';
import { IAuthLoginParams } from '@shared/models/auth';
import { checkIsAllFieldsFilled } from '@shared/utils/validation';

/** */
const AuthLoginUserForm = () => {
  const [loginData, setLoginData] = useState<IAuthLoginParams | null>(null);
  const [loginValidation, setLoginValidation] =
    useState<Partial<IAuthLoginParamsValidation> | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const nextParam = new URLSearchParams(location.search).get('next');

  const onInputChange = (value: string, type: keyof IAuthLoginParams) => {
    setLoginData((prev) => ({ ...prev, [type]: value }));
    setLoginValidation((prev) => ({ ...prev, [type]: null }));
  };

  /** */
  const onContinueClick = () => {
    if (!loginData.username?.length) {
      setLoginValidation((prev) => ({ ...prev, email: [EMPTY_ERROR] }));
    }
    if (!loginData.password?.length) {
      setLoginValidation((prev) => ({ ...prev, password: [EMPTY_ERROR] }));
    } else {
      setIsLoading(true);
      AuthProvider.loginUser({ ...loginData })
        .then((response) => {
          const { access, refresh } = response;
          setAccessToken(access);
          setRefreshToken(refresh);
          dispatch(setAuth({ isAuthStatus: true }));
          navigate(nextParam || PRIVATE_ROUTES.HOME);
        })
        .catch((e) => {
          setLoginValidation(e.response?.data.extra?.fields);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const isAllLoginFieldsFilled = checkIsAllFieldsFilled(loginData);

  return (
    <div className={'authorization-form'}>
      <AppText
        tag={'h2'}
        text={'Личный кабинет'}
        className={'authorization-form__title'}
        type={'title'}
      />
      <div className={'authorization-form__controls'}>
        <div className={'authorization-form__controls__inputs'}>
          <AppInput
            label={'E-mail'}
            onChange={(value) => onInputChange(value, 'username')}
            type={'email'}
            name={'email'}
            onPressEnter={onContinueClick}
            validationError={loginValidation?.username}
          />
          <AppPasswordInput
            name={'password'}
            label={'Пароль'}
            onPressEnter={onContinueClick}
            validationError={loginValidation?.password}
            onChange={(value) => onInputChange(value, 'password')}
          />
        </div>
        <AppButton
          text={'Продолжить'}
          size={'large'}
          type="primary"
          isFull
          onClick={onContinueClick}
          loading={isLoading}
          disabled={!isAllLoginFieldsFilled}
        />
        <div className={'authorization-form__controls__links'}>
          <AppLink isNested link={PUBLIC_ROUTES.RECOVERY}>
            <AppText text={'Забыли пароль?'} />
          </AppLink>
          <AppLink isNested link={PUBLIC_ROUTES.REGISTRATION}>
            <AppText text={'Регистрация'} />
          </AppLink>
        </div>
      </div>
    </div>
  );
};

export default AuthLoginUserForm;
