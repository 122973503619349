import React from 'react';
import { AppCheckbox, AppRadio, AppText } from '@ui-kit';
import './AppSelectCard.scss';

import { COLOR_DISABLED, COLOR_SUCCESS } from '@shared/constants/colors';
import { IAppSelectCardProps } from './AppSelectCard.types';

/**
 *
 * @param props
 */
const AppSelectCard = (props: IAppSelectCardProps) => {
  const {
    type,
    title,
    topContent,
    rightElement,
    isActive,
    isDisabled,
    activeBorderColor = COLOR_SUCCESS,
    onClick,
  } = props;

  /**
   *
   * @param event
   */
  const onClickLocal = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!isDisabled) {
      onClick?.(event);
    }
  };

  return (
    <>
      <div
        onClick={onClickLocal}
        className={`app-select-card ${isDisabled ? 'disabled' : ''}`}
        style={{ borderColor: isDisabled ? COLOR_DISABLED : isActive ? activeBorderColor : '' }}
      >
        <div className={'app-select-card__top'}>
          {topContent}
          {type === 'radio' ? (
            <AppRadio
              disabled={isDisabled}
              checked={isActive}
              onClick={(e) => e.stopPropagation()}
            />
          ) : (
            <AppCheckbox
              disabled={isDisabled}
              checked={isActive}
              onClick={(e) => e.stopPropagation()}
            />
          )}
        </div>
        <AppText color={isDisabled && COLOR_DISABLED} text={title} />
      </div>
      {rightElement}
    </>
  );
};

export default AppSelectCard;
