import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PRIVATE_ROUTES } from '@shared/constants/routes';
import { SuccessOrderForm } from '@widgets/order/SuccessOrderForm';
import { OrderProvider } from '@entities/order';
import { AppButton, AppSkeleton, AppText } from '@ui-kit';
import './OrderStatusPage.scss';
import { PaymentOrderError } from '@entities/payment';
import { showErrorToast } from '@shared/utils/toast';
import { ERROR_STATUSES, INTERVAL_REQUEST_ORDER } from './OrderStatusPage.config';
import { PayOrderButton } from '@features/payment/PayOrder';

/**
 * @description - страница проверки статуса оплаты заказа
 */
const OrderStatusPage = () => {
  const navigate = useNavigate();

  const { orderId } = useParams();
  const [orderData, setOrderData] = useState(null);
  const [isErrorState, setErrorState] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const onMainReturnClick = () => {
    navigate(PRIVATE_ROUTES.HOME);
  };

  /**
   * @description - запросить ордер
   */
  const getOrder = () => {
    OrderProvider.loadOrder(orderId)
      .then((e) => {
        if (e.results.length) {
          const order = {
            amountTotal: e?.amountTotal,
            bonusTotal: e?.bonusTotal,
            ...e.results?.[0],
          };
          setOrderData(order);
          setErrorState(ERROR_STATUSES.includes(order?.status));
        } else {
          showErrorToast({ message: `Заказ с номером ${orderId} не найден` });
          navigate(PRIVATE_ROUTES.HOME);
        }
      })
      .catch(() => {
        navigate(PRIVATE_ROUTES.HOME);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!orderId) {
      navigate(PRIVATE_ROUTES.HOME);
    } else {
      setLoading(true);
      // timeout чтобы успел обработать платеж ЦРМ
      setTimeout(() => {
        getOrder();
      }, 500);
    }
    // нужно для обновление статуса
    const intervalRequestOrderID = setInterval(() => {
      getOrder();
    }, INTERVAL_REQUEST_ORDER);
    return () => {
      clearInterval(intervalRequestOrderID);
    };
  }, [orderId]);

  return (
    <div className={'status-order-page'}>
      {isLoading && <AppSkeleton className={'status-order-page-skeleton'} height={600} />}
      {!isErrorState && !isLoading && (
        <>
          <div className={'status-order-page-content'}>
            <AppText
              className={'status-order-page-content__title'}
              type={'title-h3'}
              text={'Благодарим Вас за покупку!'}
            />
            <SuccessOrderForm orderData={orderData} />
          </div>
          <div className={'status-order-page-footer'}>
            <AppButton
              type={'primary'}
              size={'large'}
              isFull
              className={'status-order-page-footer__button'}
              text={'Вернуться на главную'}
              onClick={onMainReturnClick}
            />
            <>
              {/*Если оплата не произведена*/}
              {orderData?.status === 'payment' && (
                <PayOrderButton
                  text={'Оплатить заказ'}
                  orderId={orderId}
                  size={'large'}
                  isFull
                  className={'status-order-page-footer__button'}
                  textType={'subtitle'}
                />
              )}
            </>
          </div>
        </>
      )}
      {!isLoading && isErrorState && (
        <PaymentOrderError description={'Ждем получения денежных средств от банка'} />
      )}
    </div>
  );
};

export default OrderStatusPage;
