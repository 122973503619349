import React from 'react';
import { AppLink, AppText } from '@ui-kit';
import './SidebarFooter.scss';
import { ISidebarFooterProps } from './SidebarFooter.types';
import { COLOR_BLACK } from '@shared/constants/colors';
import { useAppSelector } from '@app/store/hooks';

/**
 *
 * @param props
 */
const SidebarFooter = (props: ISidebarFooterProps) => {
  const { isCollapsed, isLink } = props;
  const CONTACT_PHONE_NUMBER = useAppSelector((state) => state.meta.data?.phoneOrganization || '');

  return (
    <div className={'sidebar-footer'}>
      {!isCollapsed && (
        <AppLink isNested href={isLink && `tel:+${CONTACT_PHONE_NUMBER}`}>
          <AppText
            text={CONTACT_PHONE_NUMBER}
            color={COLOR_BLACK}
            type={'note'}
            fontType={'codec'}
          />
        </AppLink>
      )}
      {/*<div className={'sidebar-footer__menu-item'}>*/}
      {/*  <AppButton*/}
      {/*    type={'menu'}*/}
      {/*    text={'Обратный звонок'}*/}
      {/*    onClick={onClick}*/}
      {/*    iconLeft={'phone'}*/}
      {/*    isCollapsed={isCollapsed}*/}
      {/*  />*/}
      {/*</div>*/}
    </div>
  );
};

export default SidebarFooter;
