import { createAsyncThunk } from '@reduxjs/toolkit';
import { OrderProvider } from '@entities/order';
import { INotMyOrderListParams, IOrderListParams, TOrderType } from '@shared/models/order';
import { IUser } from '@shared/models/user';

export const loadOrderListAction = createAsyncThunk(
  'history/loadOrderList',
  async (params: { status: TOrderType; query: IOrderListParams; isLoadMore?: boolean }) => {
    const { query } = params;
    return OrderProvider.loadOrders(query);
  },
);

export const loadNotMyOrderListAction = createAsyncThunk(
  'history/loadNotMyOrders',
  async (params: { type: IUser['status']; status: TOrderType; query: INotMyOrderListParams }) => {
    const { query } = params;
    return OrderProvider.loadNotMyOrders(query);
  },
);
