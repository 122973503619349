import React, { useEffect } from 'react';
import './AppTable.scss';
import { Table } from 'antd';
import { TAppTableProps } from './AppTable.types';

/**
 * @description - Таблица
 * @param props
 */
const AppTable = (props: TAppTableProps) => {
  const { className, dataSource, title, isShowTitle, scrollHeight, onLoadMore, ...restProps } =
    props;

  useEffect(() => {
    if (!isShowTitle) {
      const antTableTitle = document.querySelector('.ant-table-title');
      antTableTitle?.remove();
    }
  }, [isShowTitle]);

  useEffect(() => {
    const tableContent = document.querySelector('.ant-table-body') as HTMLDivElement;

    /**
     *
     * @param event
     */
    const onScrollLocal = (event: Event) => {
      const target = event.target as HTMLDivElement;
      const maxScroll = target.scrollHeight - target.clientHeight;
      const currentScroll = target.scrollTop;
      if (currentScroll > maxScroll - 1) {
        onLoadMore?.();
      }
    };
    tableContent?.addEventListener('scroll', onScrollLocal);

    return () => {
      tableContent?.removeEventListener('scroll', onScrollLocal);
    };
  }, [onLoadMore]);

  return (
    <Table
      className={`app-ui-table ${className || ''}`}
      {...restProps}
      dataSource={dataSource}
      pagination={false}
      scroll={{ y: scrollHeight }}
      title={title}
    />
  );
};

export default AppTable;
