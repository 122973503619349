import { combineReducers } from 'redux';
import { persistedAuthReducer } from '@entities/auth';
import { persistedUserReducer } from '@entities/user';
import { persistedPromoReducer } from '@entities/promo';
import { persistedMetaReducer } from '@entities/meta';
import { persistedOrderReducer } from '@entities/order';

export const rootReducer = combineReducers({
  auth: persistedAuthReducer,
  user: persistedUserReducer,
  promo: persistedPromoReducer,
  meta: persistedMetaReducer,
  order: persistedOrderReducer,
});
