import './AppCarousel.scss';
import React, { FC, ReactNode } from 'react';
import { Carousel } from 'antd';
import { AppButton } from '@ui-kit';
import { IAppCarouselProps } from './AppCarousel.types';

/**
 *
 * @param props
 */
const AppCarousel: FC<IAppCarouselProps> = (props) => {
  const { children, isSlider, className = '', arrows: isArrows, ...restProps } = props;

  /** */
  const carouselArrows = () => {
    if (isArrows) {
      /**
       * @param subProps
       * @param subProps.children
       * @param subProps.slideCount
       * @param subProps.currentSlide
       */
      const SlickButtonFix = (subProps: {
        children: ReactNode;
        slideCount?: number;
        currentSlide?: number;
      }) => {
        const { children: subChildren, currentSlide, slideCount, ...others } = subProps;
        return <div {...others}>{subChildren}</div>;
      };

      return {
        prevArrow: (
          <SlickButtonFix>
            <AppButton className={'slick-prev'} icon={'arrow-left'} type={'grayMax'} />
          </SlickButtonFix>
        ),
        nextArrow: (
          <SlickButtonFix>
            <AppButton className={'slick-next'} icon={'arrow-right'} type={'grayMax'} />
          </SlickButtonFix>
        ),
      };
    }
    return {
      prevArrow: null,
      nextArrow: null,
    };
  };

  return (
    <div className={`app-ui-carousel ${isSlider ? 'app-ui-carousel-slider' : ''} ${className}`}>
      <Carousel
        prevArrow={carouselArrows().prevArrow}
        nextArrow={carouselArrows().nextArrow}
        arrows={isArrows}
        {...restProps}
      >
        {children}
      </Carousel>
    </div>
  );
};

export default AppCarousel;
