import React, { FC } from 'react';
import { Popover } from 'antd';
import { TAppPopoverProps } from './AppPopover.types';
import './AppPopover.scss';

/**
 *
 * @param props
 */
const AppPopover: FC<TAppPopoverProps> = (props) => {
  const { content, className, children, ...restProps } = props;
  return (
    <Popover
      content={content}
      {...restProps}
      overlayClassName={`app-ui-popover ${className || ''}`}
    >
      {children}
    </Popover>
  );
};

export default AppPopover;
