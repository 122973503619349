import React from 'react';
import './PromoRegistrationBlock.scss';
import { TPromoRegistrationBlockProps } from './PromoRegistrationBlock.types';
import { AppButton, AppLink, AppText } from '@ui-kit';
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from '@shared/constants/routes';

/**
 *
 * @param props
 */
const PromoRegistrationBlock = (props: TPromoRegistrationBlockProps) => {
  const { textConfig } = props;
  return (
    <div className={'public-registration-block'}>
      <div className={'public-registration-block__text'}>
        <AppText {...textConfig} text={'Зарегистрируйтесь, копите и тратьте баллы на покупки'} />
      </div>
      <div className={'public-registration-block__controls'}>
        <AppLink isNested link={`${PUBLIC_ROUTES.AUTHORIZATION}?next=${PRIVATE_ROUTES.CART}`}>
          <AppButton type={'link'} text={'Вход'} isFull textType={'note'} />
        </AppLink>
        <AppLink isNested link={`${PUBLIC_ROUTES.REGISTRATION}?next=${PRIVATE_ROUTES.CART}`}>
          <AppButton type={'primary'} text={'Регистрация'} textType={'note'} isFull />
        </AppLink>
      </div>
    </div>
  );
};

export default PromoRegistrationBlock;
