import React, { useEffect, useState } from 'react';
import {
  AppDropdownSelect,
  AppEmptyData,
  AppSkeleton,
  AppText,
  IOptionValue,
  LabelValue,
} from '@ui-kit';
import { COLOR_BLACK, COLOR_GRAY_MEDIUM, EMPTY_ORDERS_BG } from '@shared/constants/colors';
import './ActiveOrders.scss';
import { formatDate, isEmptyList, trimSeparator } from '@frontend-modules/frontend-utils';
import { ProductCardSliderList } from '@entities/product';
import { loadOrderListAction } from '@entities/order';
import { ScoreBalance } from '@entities/score';
import { useScreenSize } from '@shared/hooks/useScreenSize';
import { EXTERNAL_ROUTES } from '@shared/constants/routes';
import { useAppDispatch, useAppSelector } from '@app/store/hooks';
import { FetchStatuses } from '@shared/constants/fetch';
import { getDeliveryName, getDeliveryTypeName } from '@entities/delivery';
import { getPaymentMethodName } from '@entities/payment/utils';
import { IOrder } from '@shared/models/order';

/** */
const ActiveOrders = () => {
  const { calculateScreenType } = useScreenSize();
  const isAdaptive = calculateScreenType('mobile');

  const activeOrdersData = useAppSelector((state) => state.order.data.active);
  const isLoading = activeOrdersData?.fetchStatus === FetchStatuses.PENDING;

  const [activeOrder, setActiveOrder] = useState<IOrder>(null);

  const dispatch = useAppDispatch();

  const onEmptyDataBtnClick = () => {
    window.open(EXTERNAL_ROUTES.PRODUCTS);
  };

  useEffect(() => {
    if (!activeOrder) {
      setActiveOrder(activeOrdersData?.results?.[0]);
    }
  }, [activeOrder, activeOrdersData?.results]);

  useEffect(() => {
    dispatch(
      loadOrderListAction({
        status: 'active',
        query: {
          currentPage: 1,
          limit: 100,
          filters: {
            status: 'active',
          },
          sorting: {
            createAt: 'up',
          },
        },
      }),
    );
  }, [dispatch]);

  /**
   *
   * @param items
   */
  const parsedDropdownItems = (items: IOrder[]) => {
    return items.map((item) => {
      return {
        label: `№ ${item?.number}`,
        value: item.number,
      };
    });
  };

  /**
   *
   * @param item
   */
  const onDropdownSelect = (item: IOptionValue) => {
    setActiveOrder(activeOrdersData?.results?.find((order) => order.number === item.value));
  };

  return (
    <div className={'active-orders'}>
      <div className={'active-orders__header'}>
        <AppText text={'Активный заказ'} type={'title-h3'} color={COLOR_BLACK} />
        {!!activeOrder && (
          <>
            {activeOrdersData?.results?.length > 1 ? (
              <AppDropdownSelect
                rootClassName={'active-orders__header-dropdown'}
                label={`№ ${activeOrder?.number}`}
                onOptionClick={onDropdownSelect}
                options={parsedDropdownItems(activeOrdersData?.results)}
                placement={'bottom'}
              />
            ) : (
              <AppText text={`№ ${activeOrder?.number}`} type={'subtitle'} />
            )}
          </>
        )}
      </div>

      {isLoading && isEmptyList(activeOrdersData?.results) ? (
        <AppSkeleton height={405} />
      ) : isEmptyList(activeOrdersData?.results) ? (
        <div className={'empty-active-order'}>
          <AppEmptyData
            bgColor={EMPTY_ORDERS_BG}
            title={'Нет активных заказов'}
            description={'Сделайте заказ прямо сейчас!'}
            buttonText={'Перейти к продуктам'}
            onButtonClick={onEmptyDataBtnClick}
          />
        </div>
      ) : (
        <div className={'active-orders__content'}>
          <div className={'active-orders__content_list'}>
            <ProductCardSliderList productList={activeOrder?.products || []} />
          </div>
          <div className={'active-orders__content__info'}>
            <div className={'active-orders__content__info_header'}>
              <LabelValue
                label={'Информация о заказе'}
                direction={isAdaptive ? 'vertical' : 'horizontal'}
                labelConfig={{ type: 'note-bold', color: COLOR_BLACK }}
                valueConfig={{ type: 'body', color: COLOR_GRAY_MEDIUM }}
                value={`${formatDate(activeOrder?.createAt)}`}
                isFullWidth={false}
              />
              <ScoreBalance
                type={isAdaptive ? 'default' : 'full'}
                isWithPlus
                score={activeOrder?.bonusAccrual}
              />
            </div>
            <div className={'active-orders__content__info_content'}>
              <LabelValue
                label={'Способ доставки'}
                value={getDeliveryName(activeOrder?.typeSource, activeOrder?.typeDelivery)}
              />
              <LabelValue
                labelConfig={{ isNoWrap: true }}
                label={getDeliveryTypeName(activeOrder?.typeDelivery)}
                value={`${activeOrder?.postalCode}, ${activeOrder?.addressText}`}
              />
              <LabelValue label={'Оплата'} value={getPaymentMethodName(activeOrder?.typePayment)} />
              <LabelValue
                label={'ИТОГО'}
                value={`${trimSeparator(activeOrder?.amountTotal)} руб.`}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActiveOrders;
