import React from 'react';
import { AuthLayout } from '@widgets/layouts/AuthLayout';
import { AuthLoginUserForm } from '@features/auth/AuthLoginUserForm';
import { AppTextIconLink } from '@ui-kit';

/** */
const LoginPage = () => {
  return (
    <div className={'authorization-page'}>
      <AuthLayout
        pageType={'login'}
        headerLink={
          <AppTextIconLink
            isNested={false}
            link={process.env.REACT_APP_SITE_URL}
            text={'Вернуться на сайт'}
          />
        }
      >
        <AuthLoginUserForm />
      </AuthLayout>
    </div>
  );
};

export default LoginPage;
