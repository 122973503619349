import React, { FC } from 'react';
import './AppText.scss';
import { IAppTextProps } from '@ui-kit';
import { fontWeightMap } from './AppText.utils';

/**
 * Отображает компонент текста с настраиваемыми свойствами.
 *
 * @param props
 * @param props.text - Отображаемый текст.
 * @param props.color - Цвет текста.
 * @param props.type - Тип текста.
 *   - title: 38px
 *   - title-h3: 32px
 *   - title-h4: 26px
 *   - title-h5: 24px
 *   - title-h6: 22px
 *   - subtitle: 20px
 *   - note: 18px
 *   - note-bold :18px fw 600
 *   - body: 16px / fw 400
 *   - body-bold: 16px / fw 600
 *   - bodyLight: 16px / fw 300
 *   - small: 14px
 *   - signature: 12px
 *   - signature-bold: 12px lh 12px fw 400
 * @param props.fontWeight - Жирность шрифта.
 * @param props.fontType - Какой шрифт применить.
 * @param props.isNoWrap  - Не переносить текст.
 * @param props.tag - HTML-тег для компонента текста.
 * @param props.onClick - Функция обратного вызова для событий клика.
 */
const AppText: FC<IAppTextProps> = (props) => {
  const {
    text,
    color,
    isCursorPointer,
    fontType = 'averta',
    className,
    type = 'body',
    tag = 'span',
    onClick,
    fontWeight,
    isNoWrap,
  } = props;

  return React.createElement(
    tag,
    {
      className: `app-ui-text ${className || ''} ${type} ${fontType} ${
        onClick || isCursorPointer ? 'cursor-pointer' : ''
      } ${fontWeight ? fontWeightMap[fontWeight] : ''} ${isNoWrap ? 'nowrap' : ''}`,
      style: { color: color || '' },
      onClick,
    },
    text,
  );
};

export default AppText;
