/* eslint-disable react/no-array-index-key */
import { AppFontIcon } from '@ui-kit';
import { Fragment } from 'react';
import { IScoreInfoCardProps } from './ScoreInfoCard.types';
import { COLOR_ORANGE } from '@shared/constants/colors';
import './ScoreInfoCard.scss';

/**
 * Компонент карточки тарифа пользователя и информации по бонусам
 *
 * @param props
 */
const ScoreInfoCard = (props: IScoreInfoCardProps) => {
  const {
    headerComponents,
    textComponent,
    contentComponents,
    footerComponent,
    isAdaptive,
    clientStatus,
  } = props;
  return (
    <div className={`score-info-card-wrapper ${isAdaptive ? 'adaptive' : ''}`}>
      <div className={`score-info-card-header status_${clientStatus}`}>
        <div className={'score-info-card-header_img'}>
          <AppFontIcon icon={'coin'} color={COLOR_ORANGE} iconSize={54} />
        </div>
        {headerComponents.map((headerItem, index) => (
          <Fragment key={index}>{headerItem}</Fragment>
        ))}
      </div>
      {textComponent ? (
        <div className={'score-info-card-content_text'}>{textComponent}</div>
      ) : (
        <div className={'score-info-card-content'}>
          {contentComponents.map((contentItem, index) => (
            <Fragment key={index}>{contentItem}</Fragment>
          ))}
        </div>
      )}
      {footerComponent && <div className={'score-info-card-footer'}>{footerComponent} </div>}
    </div>
  );
};

export default ScoreInfoCard;
