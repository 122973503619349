import './AppTag.scss';
import { AppText } from '@ui-kit';
import { Tag } from 'antd';
import { IAppTagProps } from './AppTag.types';

/**
 * @description - Tag
 * @param props
 */
const AppTag = (props: IAppTagProps) => {
  const { backgroundColor, color, text, className, ...restProps } = props;

  return (
    <Tag color={backgroundColor} {...restProps} className={`${className} app-ui-tag`}>
      <AppText text={text} color={color} type={'body'} />
    </Tag>
  );
};

export default AppTag;
