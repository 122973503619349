let timeoutId: NodeJS.Timeout;

/**
 *
 * @param func
 * @param delay
 */
export const debounce = <T>(func: (value?: T) => void, delay: number): ((value?: T) => void) => {
  return (value?: T) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      value ? func(value) : func();
    }, delay);
  };
};
