import React, { FC } from 'react';
import { AppText } from '@ui-kit';
import { getNoun, trimSeparator } from '@frontend-modules/frontend-utils';
import { COLOR_BLACK, COLOR_GRAY_MAX, COLOR_ORANGE } from '@shared/constants/colors';
import { IOrderPaymentInfo } from './OrderPaymentInfo.types';
import './OrderPaymentInfo.scss';
import { renderDeliveryAmount } from '@entities/delivery';

/**
 *
 * @param props
 */
const OrderPaymentInfo: FC<IOrderPaymentInfo> = (props) => {
  const {
    title = 'Оплачено на сайте',
    orderQuantity,
    orderAmount,
    sale,
    promoCodeSale,
    scoreSale,
    deliveryPrice,
    amountTotal,
  } = props;

  return (
    <div className={'order-payment-info-wrapper'}>
      <div className={'order-payment-info'}>
        {title && (
          <div className={'order-payment-info__title'}>
            <AppText text={title} type={'subtitle'} color={COLOR_BLACK} />
          </div>
        )}
        <div className={'order-payment-info__content'}>
          <div className={'order-payment-info__content_item'}>
            <AppText
              color={COLOR_GRAY_MAX}
              text={`${orderQuantity} товар${getNoun(orderQuantity, '', 'а', 'ов')} на сумму`}
            />
            <AppText color={COLOR_GRAY_MAX} text={`${trimSeparator(orderAmount)} руб.`} />
          </div>

          {sale ? (
            <div className={'order-payment-info__content_item'}>
              <AppText color={COLOR_GRAY_MAX} text={'Скидка'} />
              <AppText color={COLOR_GRAY_MAX} text={`${trimSeparator(sale)} руб.`} />
            </div>
          ) : null}

          {promoCodeSale ? (
            <div className={'order-payment-info__content_item'}>
              <AppText color={COLOR_GRAY_MAX} text={`Купон`} />
              <AppText color={COLOR_GRAY_MAX} text={`${trimSeparator(promoCodeSale)} руб.`} />
            </div>
          ) : null}

          {scoreSale ? (
            <div className={'order-payment-info__content_item'}>
              <AppText color={COLOR_ORANGE} text={'Оплата баллами'} />
              <AppText color={COLOR_ORANGE} text={`-${trimSeparator(scoreSale)} руб.`} />
            </div>
          ) : null}

          <div className={'order-payment-info__content_item'}>
            <AppText color={COLOR_GRAY_MAX} text={`Доставка`} />
            <AppText color={COLOR_GRAY_MAX} text={renderDeliveryAmount(deliveryPrice)} />
          </div>
        </div>
        <div className={'order-payment-info__content_item'}>
          <AppText color={COLOR_BLACK} type={'subtitle'} text={`Итого`} />
          <AppText
            color={COLOR_BLACK}
            type={'subtitle'}
            text={`${trimSeparator(amountTotal)} руб.`}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderPaymentInfo;
