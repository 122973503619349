import { YMaps } from '@entities/yandex';

import React, { useEffect } from 'react';
import { TYandexMapsProviderProps } from './YandexProvider.types';
import { isProduction } from '@shared/utils/environment';

/**
 * @description - оборачиваем приложение в Api Yandex Map и включаем Yandex Metric
 * @param props
 */
const YandexProvider = (props: TYandexMapsProviderProps) => {
  const { children } = props;

  useEffect(() => {
    if (isProduction()) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.innerHTML = `
      (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
      m[i].l=1*new Date();
      for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
      k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
      (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

      ym(56808985, "init", {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true,
        webvisor:true,
        ecommerce:"dataLayer"
      });

      window.dataLayer = window.dataLayer || [];

      ym(56808985, 'getClientID', function(clientID) {
        window.yandexMetricClientID = clientID
      });
    `;
      document.head.appendChild(script);

      const noscript = document.createElement('noscript');
      noscript.innerHTML =
        '<div><img src="https://mc.yandex.ru/watch/56808985" style="position:absolute; left:-9999px;" alt="" /></div>';
      document.body.appendChild(noscript);

      return () => {
        document.head.removeChild(script);
        document.body.removeChild(noscript);
      };
    }
  }, []);

  return (
    <YMaps
      version={'2.1.79'}
      query={{
        load: 'package.full',
        apikey: process.env.REACT_APP_YANDEX_MAP_KEY,
        suggest_apikey: process.env.REACT_APP_YANDEX_SUGGEST_KEY,
      }}
    >
      {children}
    </YMaps>
  );
};

export default YandexProvider;
