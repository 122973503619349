import React from 'react';
import './PublicLayout.scss';
import { PublicFooter, PublicNavbar } from './ui';
import { useScreenSize } from '@shared/hooks/useScreenSize';

/**
 *
 * @param props
 * @param props.children
 */
const PublicLayout = ({ children: page }) => {
  const { screenSize } = useScreenSize();
  const isAdaptive = screenSize.width < 900;

  return (
    <div className={'public-layout'}>
      <PublicNavbar isAdaptive={isAdaptive} />
      <div className={'public-layout-content'}>{page}</div>
      <div className={'public-layout-footer'}>
        <PublicFooter />
      </div>
    </div>
  );
};

export default PublicLayout;
