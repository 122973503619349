import React, { useEffect, useRef, useState } from 'react';
import { AppButton, AppSkeleton, AppText } from '@ui-kit';
import './CreateOrderForm.scss';
import { ChoosePaymentMethod } from '@features/payment/ChoosePaymentMethod';
import { useAppSelector } from '@app/store/hooks';
import {
  CartEmpty,
  CartProvider,
  cookieCartItemsKey,
  getCartItems,
  removeAllProductFromCart,
} from '@entities/cart';
import { isEmptyList } from '@frontend-modules/frontend-utils';
import { DeliveryProvider } from '@entities/delivery';
import { validateYMAPAddress, YandexMetric } from '@entities/yandex';
import { IDeliveryCalculation, IDeliveryPoint, TDeliveryTypes } from '@shared/models/delivery';
import { Event } from 'yandex-maps';
import { OrderFormConfirm, OrderFormDelivery, OrderFormInputs, OrderFormProducts } from './ui';
import { useScreenSize } from '@shared/hooks/useScreenSize';
import { IOrderForm } from '@shared/models/order';
import { debounce } from '@shared/utils/debounce';
import { OrderProvider } from '@entities/order';
import { showErrorToast } from '@shared/utils/toast';
import { ICreateOrderFormProps, IDeliveryPriceParams } from './CreateOrderForm.types';
import { subscribeToCookieChanges } from '@shared/utils/cookies';
import { ICalculateDeliveryPrice, ICartParams, TCartItem } from '@shared/models/cart';

const localWindow: any = window;

/**
 * @description - форма создания заказа
 * @param props
 */
const CreateOrderForm = (props: ICreateOrderFormProps) => {
  const { promoComponent, isPublic } = props;

  const userStoreData = useAppSelector((state) => state.user.data);
  const { calculateScreenType } = useScreenSize();

  const [detailedCartList, setDetailedCartList] = useState<TCartItem[]>([]);
  const [orderInfo, setOrderInfo] = useState<IOrderForm>({
    fullName: `${
      isPublic
        ? ''
        : userStoreData?.lastOrder?.fullName ||
          `${userStoreData.firstName} ${userStoreData.lastName}`
    }`,
    email: `${userStoreData?.lastOrder?.email || userStoreData.email || ''}`,
    phone: `${userStoreData?.lastOrder?.phone || userStoreData.phone || ''}`,
    address: `${userStoreData?.lastOrder?.addressText || ''}`,
    comment: '',
    delivery: ``,
  });
  const [cartParams, setCartParams] = useState<Omit<ICartParams, 'products'> | null>(null);
  const [validationError, setValidationError] = useState(null);
  const [isShowPostalCodeInput, setIsShowPostalCodeInput] = useState(false);
  const [isConfirmAgreements, setConfirmAgreements] = useState(false);
  const [isAddressValid, setAddressValid] = useState(false);
  const [isOrderCreating, setOrderCreating] = useState(false);
  const [isDeliveryErrorExist, setDeliveryErrorExist] = useState(false);
  const [bonusWillAdd, setBonusWillAdd] = useState<number | null>(null);
  const [paymentMethod, setPaymentMethod] = useState(userStoreData?.lastOrder?.typePayment || null);
  const [addressError, setAddressError] = useState([]);
  const [activeDeliveryPoint, setActiveDeliveryPoint] = useState<IDeliveryPoint | null>(null);
  const [deliveryData, setDeliveryData] = useState<IDeliveryCalculation | null>(null);
  const [activeMapDeliveryPoint, setActiveMapDeliveryPoint] = useState<IDeliveryPoint | null>(null);
  const [cartLoading, setCartLoading] = useState<'blur' | 'skeleton' | null>(null);
  const [isDeliveryDataLoading, setDeliveryLoading] = useState(false);
  const [mapConfig, setMapConfig] = useState({
    center: [55.751574, 37.573856],
    zoom: 16,
    controls: [],
  });

  const ymaps = useRef(null);
  const ymapsSuggest = useRef(null);
  const timeoutValidateAddressRef = useRef<NodeJS.Timeout | null>(null);
  const deliveryMethodRef = useRef(null);
  const cartCookiesList = useRef(
    getCartItems().map(({ id, count }) => ({ externalId: id, count })),
  );

  const isTablet = calculateScreenType('tablet');
  const isCartEmpty = isEmptyList(cartCookiesList.current);
  const mapShowStatus = orderInfo.delivery === 'pvz' && isAddressValid ? 'show' : 'hidden';
  const isContinueButtonDisabled =
    !isConfirmAgreements || !orderInfo.address || !paymentMethod || !deliveryData;
  const isContinueButtonLoading =
    isDeliveryDataLoading || isOrderCreating || !!cartLoading || !!addressError?.length;

  /**
   *
   * @param method
   */
  const getSourceByMethod = (method: TDeliveryTypes | '') => {
    if (method === 'to_door') return 'cdek';
    if (method === 'express') return 'other';
    return activeDeliveryPoint?.source;
  };

  /**
   *
   * @param value
   * @param type
   */
  const onInputChange = (value: string, type: keyof typeof orderInfo) => {
    // нужно для сброса активной точки и адекватного отображения на странице калькуляции
    if (type === 'address' && (deliveryData || !value)) {
      setDeliveryData(null);
    }
    if (type === 'address' && value !== orderInfo.address) {
      setOrderInfo((prev) => ({ ...prev, address: value, manualPostalCode: '' }));
      setIsShowPostalCodeInput(false);
    }
    setOrderInfo((prev) => ({ ...prev, [type]: value }));
  };

  /**
   *
   * @param value
   */
  const getCart = (value: ICartParams) => {
    detailedCartList.length ? setCartLoading('blur') : setCartLoading('skeleton');
    CartProvider.getCartData(value).then((response) => {
      if (response?.products.length) {
        const productList = response?.products;
        const cartListLocal = cartCookiesList.current.map((localStorageCartItem) => {
          return {
            ...productList.find(
              (product) => String(product.externalId) === String(localStorageCartItem.externalId),
            ),
            count: localStorageCartItem.count,
          };
        });
        setBonusWillAdd(response?.bonusAccrual);
        setDetailedCartList(cartListLocal);
        setCartParams((prevState) => ({
          ...prevState,
          bonusDebt: response?.bonusDebt,
        }));
      }
      setCartLoading(null);
    });
  };

  // Списание бонусов
  const debouncedBonusesToPay = debounce((value: number) => {
    getCart({
      ...cartParams,
      products: cartCookiesList.current,
      bonusDebt: value,
    });
    setCartParams((prevState) => ({
      ...prevState,
      bonusDebt: value,
    }));
  }, 1000);

  /**
   * @description - калькуляция кол-ва полученных бонусов, расчеты происходят на бэке из-за сложной формулы рассчета
   * @param prm
   * @param prm.params
   * @param prm.errorCallback
   * @param prm.successCallback
   * @param prm.isViewOnly - без запроса на /cart
   */
  const calculateDeliveryPrice = ({
    params,
    errorCallback,
    successCallback,
    isViewOnly,
  }: ICalculateDeliveryPrice) => {
    setDeliveryLoading(true);
    DeliveryProvider.calculateDelivery(params)
      .then((calcRes) => {
        if (calcRes) {
          setDeliveryData(calcRes);
          setCartParams((prevState) => ({
            ...prevState,
            amountDelivery: calcRes.amount || 0,
          }));
          if (!isViewOnly) {
            getCart({
              ...cartParams,
              products: params.products,
              amountDelivery: calcRes.amount || 0,
            });
          }
          successCallback?.(calcRes);
          setDeliveryLoading(false);
        }
      })
      .catch((e) => {
        errorCallback?.(e);
        setDeliveryLoading(false);
      });
  };

  /**
   *
   * @param addressLocal
   * @param type
   */
  const loadDoorDeliveryData = async (addressLocal: string, type: TDeliveryTypes) => {
    const res = await ymaps.current.geocode(addressLocal);
    const firstGeoObject = res.geoObjects.get(0);
    const dataPostalCode = firstGeoObject.properties.get(
      'metaDataProperty.GeocoderMetaData.Address.postal_code',
    );
    const dataCountryCode = firstGeoObject.properties.get(
      'metaDataProperty.GeocoderMetaData.Address.country_code',
    );
    // если индекс был ранее введен вручную
    if (orderInfo.manualPostalCode) {
      setIsShowPostalCodeInput(true);
    }
    if (type !== 'pvz') {
      setOrderInfo((prev) => ({
        ...prev,
        responsePostalCode: dataPostalCode,
        countryCode: dataCountryCode,
      }));
    }

    if (dataPostalCode || orderInfo.manualPostalCode) {
      const params: IDeliveryPriceParams = {
        postalCode: dataPostalCode || orderInfo.manualPostalCode,
        typeDelivery: type,
        products: cartCookiesList.current,
        countryCode: dataCountryCode,
        typeSource: getSourceByMethod(type),
      };
      calculateDeliveryPrice({
        params,
        /**
         *
         * @param e
         */
        errorCallback: (e) => {
          if (e?.response?.data?.extra?.fields?.postalCode) {
            setAddressError(e?.response?.data?.extra?.fields?.postalCode);
          } else {
            setAddressError(['Произошла ошибка']);
          }
        },
      });
    } else if (type !== 'pvz') {
      setIsShowPostalCodeInput(true);
      setOrderInfo((prev) => ({ ...prev, responsePostalCode: '' }));
      !orderInfo.manualPostalCode &&
        showErrorToast({ message: 'Не найден индекс, введите вручную' });
    }
  };

  /**
   * @description - получение координат по почтовому индексу
   * @param pointDataParams
   * @param withoutUploadCart
   */
  const loadDeliveryPointData = (pointDataParams: IDeliveryPoint, withoutUploadCart?: boolean) => {
    if (!orderInfo.address) return;
    if (pointDataParams.postalCode) {
      const params = {
        typeDelivery: pointDataParams.typeSource,
        typeSource: pointDataParams.source,
        postalCode: pointDataParams.postalCode,
        products: cartCookiesList.current,
        pointId: pointDataParams.externalId,
      };
      calculateDeliveryPrice({
        params,
        errorCallback: () => {
          setDeliveryErrorExist(true);
        },
        successCallback: () => {
          setDeliveryErrorExist(false);
        },
        isViewOnly: withoutUploadCart,
      });
    }
  };

  /**
   * @description - валидация адреса
   * @param addressLocal
   * @param deliveryTypeLocal
   */
  const validateAddress = async (addressLocal: string, deliveryTypeLocal = orderInfo.delivery) => {
    if (!addressLocal) {
      setAddressValid(false);
      return [];
    }

    const validationData = await validateYMAPAddress(
      ymaps.current,
      addressLocal,
      deliveryTypeLocal,
      onInputChange,
    );

    setAddressValid(validationData.isValid);

    if (validationData.isValid && deliveryTypeLocal && deliveryTypeLocal !== 'pvz') {
      await loadDoorDeliveryData(addressLocal, deliveryTypeLocal);
    }

    return validationData.messages;
  };

  /**
   * @description получить координаты
   * @param addressLocal
   */
  const getCoordinates = async (addressLocal: string) => {
    const res = await ymaps.current.geocode(addressLocal);
    const firstGeoObject = res.geoObjects.get(0);
    if (firstGeoObject) {
      return firstGeoObject.geometry.getCoordinates();
    }
    // eslint-disable-next-line no-console
    console.error('Не удалось получить координаты для выбранного адреса');
    return mapConfig.center;
  };

  /**
   * @description - валидация адреса
   * @param value
   */
  const setAddressThenValidate = async (value: string) => {
    let newOrderInfo = null;
    setOrderInfo((prev) => {
      newOrderInfo = { ...prev, address: value };
      return newOrderInfo;
    });
    const errors = await validateAddress(value, newOrderInfo?.delivery || '');
    setAddressError(errors);
  };

  /**
   * @description - при потере фокуса. Добавлен таймаут для проверки был ли выбран адрес из подсказки, дабы не
   * сработали сразу два метода
   */
  const onInputAddressBlur = () => {
    timeoutValidateAddressRef.current = setTimeout(async () => {
      ymapsSuggest?.current?.changeVisibility('hidden');
      const errors = await validateAddress(orderInfo.address);
      setAddressError(errors);
      if (orderInfo.address) {
        //change logical for set coordinates
        ymaps.current?.suggest(orderInfo.address).then(async (items) => {
          let coordinates: any = null;
          if (items?.[0]?.displayName) {
            coordinates = await getCoordinates(items?.[0]?.displayName);
          } else {
            coordinates = await getCoordinates(orderInfo.address);
          }
          setMapConfig((prevState) => ({ ...prevState, center: coordinates }));
        });
      }
    }, 100);
  };

  /**
   * @description - выбор адреса из инпута
   * @param event
   */
  const onInputAddressSelect = async (event: Event) => {
    ymapsSuggest?.current.changeVisibility('hidden');
    // Отменяем предыдущую задержку, если она есть
    if (timeoutValidateAddressRef.current !== null) {
      clearTimeout(timeoutValidateAddressRef.current);
      timeoutValidateAddressRef.current = null;
    }
    const addressStringValue = event.get('item').displayName;
    await setAddressThenValidate(addressStringValue);
    const coordinates = await getCoordinates(addressStringValue);
    setMapConfig((prevState) => ({ ...prevState, center: coordinates }));
  };

  /**
   *
   * @param point
   */
  const onDeliveryPointClick = (point: IDeliveryPoint) => {
    setActiveMapDeliveryPoint(point);

    setMapConfig((prevState) => ({
      ...prevState,
      center: [point.latitude, point.longitude],
    }));
    loadDeliveryPointData(point, true);
  };

  /**
   *
   * @param value
   */
  const onDeliveryMethodChange = async (value: '' | TDeliveryTypes) => {
    if (value === orderInfo.delivery) return;
    setDeliveryData(null);
    onInputChange(value, 'delivery');

    // если выбран ПВЗ, то запуска загрузку калькуляции доставки
    if (value === 'pvz') {
      setIsShowPostalCodeInput(false);
      if (activeDeliveryPoint) {
        onDeliveryPointClick(activeDeliveryPoint);
        setOrderInfo((prev) => ({ ...prev, responsePostalCode: activeDeliveryPoint.postalCode }));
      }
    }
    const errors = await validateAddress(orderInfo.address, value);
    setAddressError(errors);
  };

  /** */
  const cartUpdateRequestSwitcher = () => {
    cartCookiesList.current = getCartItems().map(({ id, count }) => ({ externalId: id, count }));

    /** с уже выбранной доставкой */
    if (orderInfo.delivery) {
      switch (orderInfo.delivery) {
        case 'pvz': {
          if (activeDeliveryPoint) {
            loadDeliveryPointData(activeDeliveryPoint);
          } else {
            loadDoorDeliveryData(orderInfo.address, orderInfo.delivery);
          }
          break;
        }
        default: {
          loadDoorDeliveryData(orderInfo.address, orderInfo.delivery);
          break;
        }
      }
    } else {
      /** Когда доставка еще не выбрана */
      getCart({ ...cartParams, products: cartCookiesList.current });
    }
  };

  /** изменение кол-ва товаров*/
  const debounceUpdateCartList = debounce(cartUpdateRequestSwitcher, 1000);

  /**
   */
  const loadCartProducts = () => {
    const localStorageItems = getCartItems();
    const localStorageIds = localStorageItems.map((item) => item.id);
    // проверка изменений корзины в куках
    const isCartItemsIdsEqual =
      localStorageItems.length === detailedCartList.length &&
      localStorageItems.every((cartItem) =>
        detailedCartList.some((item) => item.externalId === cartItem.id),
      );

    const isCartItemsCountsEqual = localStorageItems.every((cartItem) =>
      detailedCartList.some((item) => item.count === cartItem.count),
    );
    cartCookiesList.current = localStorageItems.map(({ id, count }) => ({ externalId: id, count }));

    if (localStorageIds.length) {
      setCartParams((prevState) => ({
        ...prevState,
        products: cartCookiesList.current,
      }));
    }

    if (!isCartItemsIdsEqual) {
      cartUpdateRequestSwitcher();
    } else if (!isCartItemsCountsEqual) {
      debounceUpdateCartList();
    }
  };

  /**
   *
   * @param id
   */
  const onRemoveCartItemClick = (id: string) => {
    setDetailedCartList((prevState) => prevState.filter((item) => item.externalId !== id));
    debounceUpdateCartList();
  };
  /**
   * @description перерасчет суммы за изменяемый товар
   * @param id
   * @param newCount
   */
  const onChangeCartItemCount = (id: string, newCount: number) => {
    const changedItemIndex = detailedCartList.findIndex((item) => item.externalId === id);
    const targetItem = detailedCartList[changedItemIndex];
    const newItemPrice = targetItem.offer.price * newCount;

    const changedDetailedCartList = [...detailedCartList];
    changedDetailedCartList[changedItemIndex] = {
      ...targetItem,
      count: newCount,
      amount: newItemPrice,
    };
    setDetailedCartList(changedDetailedCartList);
    debounceUpdateCartList();
  };

  /** */
  const onGoBackClick = () => {
    setActiveDeliveryPoint(null);
    setDeliveryData(null);
    setOrderInfo((prev) => ({ ...prev, delivery: '' }));
    setTimeout(() => {
      deliveryMethodRef?.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }, 100);
  };

  /** */
  const onContinueClick = () => {
    /**
     *
     * @param address
     * @param deliveryType
     */
    const getAddressByDelivery = (address: string, deliveryType: TDeliveryTypes) => {
      if (deliveryType === 'pvz') {
        // пробуем доставь адрес пвз
        // если не достали - берем адрес из инпута
        return activeDeliveryPoint
          ? `г. ${activeDeliveryPoint.city}, ${activeDeliveryPoint.address}`
          : address;
      }
      return address;
    };

    if (orderInfo.delivery) {
      const {
        responsePostalCode,
        manualPostalCode,
        fullName,
        phone,
        email,
        comment,
        delivery,
        address,
      } = orderInfo;
      const params = {
        postalCode: responsePostalCode || manualPostalCode,
        clientID: localWindow.yandexMetricClientID || 'yandex-client-id',
        fullName,
        phone,
        email,
        comment,
        addressText: getAddressByDelivery(address, delivery),
        products: cartCookiesList.current,
        typeDelivery: delivery === 'pvz' ? activeDeliveryPoint?.typeSource : delivery, // пвз/до двери/экспресс + проверка на постамат
        typeSource: getSourceByMethod(delivery), // сдек/почта/другое
        typePayment: paymentMethod,
        bonus: cartParams.bonusDebt,
        code: activeMapDeliveryPoint?.code,
        ...(delivery === 'pvz'
          ? { pointId: activeDeliveryPoint.externalId }
          : { countryCode: orderInfo.countryCode }),
      };

      setOrderCreating(true);
      OrderProvider.createOrder(params)
        .then((response) => {
          YandexMetric.trackCreateOrder(detailedCartList, response);
          const { link } = response;
          // В link на деве приходит сразу страница успешной оплаты,
          // но на проде будет ссылка на платежный шлюз если оплата онлайн
          // поэтому ничего страшного, что тут window.open
          window.open(link, '_self');
          removeAllProductFromCart();
        })
        .catch((e) => {
          if (e?.response?.status === 400) {
            showErrorToast({ message: 'Проверьте заполненные поля' });
            setValidationError(e.response.data.extra.fields);
          }
          if (e?.response?.data?.extra?.fields?.postalCode) {
            setIsShowPostalCodeInput(true);
          }
          setOrderCreating(false);
        });
    }
  };
  /** */
  const onPickDeliveryPointClick = () => {
    setActiveDeliveryPoint(activeMapDeliveryPoint);
    setOrderInfo((prev) => ({ ...prev, responsePostalCode: activeMapDeliveryPoint.postalCode }));
    getCart({
      ...cartParams,
      products: cartCookiesList.current,
    });
  };

  useEffect(() => {
    if (userStoreData.firstName) {
      setOrderInfo((prevState) => {
        return {
          ...prevState,
          fullName: `${
            isPublic
              ? ''
              : userStoreData?.lastOrder?.fullName ||
                `${userStoreData.firstName} ${userStoreData.lastName}`
          }`,
          email: `${userStoreData?.lastOrder?.email || userStoreData.email || ''}`,
          phone: `${userStoreData?.lastOrder?.phone || userStoreData.phone || ''}`,
        };
      });
      if (userStoreData?.lastOrder?.addressText) {
        setAddressThenValidate(userStoreData?.lastOrder?.addressText);
      }
    }
  }, [userStoreData]);

  useEffect(() => {
    loadCartProducts();
  }, []);

  /**
   * обновление суммы доставки, когда добавляются/удаляются товары в корзине
   */
  useEffect(() => {
    // Возвращает метод для отписки
    const unsubscribe = subscribeToCookieChanges(cookieCartItemsKey, () => {
      loadCartProducts();
    });
    return () => {
      unsubscribe();
    };
  }, [detailedCartList, orderInfo?.address, activeDeliveryPoint]);

  return (
    <div
      className={`create-order-form ${
        isCartEmpty ? 'empty' : isTablet && promoComponent ? 'with-promo' : ''
      } ${isPublic ? 'public' : ''}`}
    >
      {isCartEmpty ? (
        <CartEmpty />
      ) : (
        <>
          <div className={`create-order-form__wrapper`}>
            {isTablet && promoComponent && (
              <div className={'create-order-form__promo'}>{promoComponent}</div>
            )}
            <AppText type={'title-h3'} text={'Оформление заказа'} />
            <div className={'create-order-form__form'}>
              <div className={'create-order-form__form_content'}>
                <div className={'create-order-form__form_content-step step-1'}>
                  <AppText
                    type={'subtitle'}
                    className={'create-order-form__form_content-step__title'}
                    text={'Получатель'}
                    fontWeight={600}
                  />
                  <OrderFormInputs
                    orderInfo={orderInfo}
                    validationError={validationError}
                    isShowPostalCodeInput={isShowPostalCodeInput}
                    setValidationError={setValidationError}
                    cartCookiesList={cartCookiesList}
                    setAddressError={setAddressError}
                    calculateDeliveryPrice={calculateDeliveryPrice}
                    ymapsSuggest={ymapsSuggest}
                    addressError={addressError}
                    onInputAddressBlur={onInputAddressBlur}
                    isDeliveryDataLoading={isDeliveryDataLoading}
                    onInputChange={onInputChange}
                  />
                </div>
                <div
                  className={'create-order-form__form_content-step step-2'}
                  ref={deliveryMethodRef}
                >
                  <AppText
                    type={'subtitle'}
                    className={'create-order-form__form_content-step__title'}
                    text={'Выберите удобный способ доставки'}
                    fontWeight={600}
                  />
                  <OrderFormDelivery
                    mapConfig={mapConfig}
                    orderInfo={orderInfo}
                    mapShowStatus={mapShowStatus}
                    activeMapDeliveryPoint={activeMapDeliveryPoint}
                    isDeliveryErrorExist={isDeliveryErrorExist}
                    isTablet={isTablet}
                    isDeliveryDataLoading={isDeliveryDataLoading}
                    setActiveMapDeliveryPoint={setActiveMapDeliveryPoint}
                    loadDeliveryPointData={loadDeliveryPointData}
                    onInputAddressSelect={onInputAddressSelect}
                    onInputAddressBlur={onInputAddressBlur}
                    setMapConfig={setMapConfig}
                    onGoBackClick={onGoBackClick}
                    onDeliveryPointClick={onDeliveryPointClick}
                    deliveryData={deliveryData}
                    detailedCartList={detailedCartList}
                    onPickDeliveryPointClick={onPickDeliveryPointClick}
                    ymaps={ymaps}
                    ymapsSuggest={ymapsSuggest}
                    cartLoading={cartLoading}
                    isAddressValid={isAddressValid}
                    onDeliveryMethodChange={onDeliveryMethodChange}
                    activeDeliveryPoint={activeDeliveryPoint}
                    setActiveDeliveryPoint={setActiveDeliveryPoint}
                    setDeliveryData={setDeliveryData}
                  />
                </div>
                <div className={'create-order-form__form_content-step step-3'}>
                  <AppText
                    type={'subtitle'}
                    className={'create-order-form__form_content-step__title'}
                    text={'Выберите удобный способ оплаты'}
                    fontWeight={600}
                  />
                  <ChoosePaymentMethod
                    isPublic={isPublic}
                    onChange={setPaymentMethod}
                    selectedMethod={paymentMethod}
                    activeDeliveryPoint={activeDeliveryPoint}
                    onChangeBonusesSum={debouncedBonusesToPay}
                    deliveryData={deliveryData}
                    cartList={detailedCartList}
                  />
                </div>
                <div className={'create-order-form__form_content-step step-4'}>
                  <OrderFormConfirm
                    isConfirmAgreements={isConfirmAgreements}
                    setConfirmAgreements={setConfirmAgreements}
                  />
                </div>
                <div className={'create-order-form__form_footer'}>
                  <AppButton
                    isFull
                    type={'primary'}
                    text={'Продолжить'}
                    size={'large'}
                    disabled={isContinueButtonDisabled}
                    loading={isContinueButtonLoading}
                    onClick={onContinueClick}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={'create-order-form__products'}>
            {!isTablet && promoComponent}
            {cartLoading === 'skeleton' && !isTablet ? (
              <AppSkeleton height={400} />
            ) : (
              <OrderFormProducts
                isLoading={cartLoading === 'blur' || isDeliveryDataLoading}
                isPublic={isPublic}
                deliveryAmount={deliveryData?.amount || null}
                cartList={detailedCartList}
                bonusesToPay={cartParams?.bonusDebt || 0}
                bonusWillAdd={bonusWillAdd}
                onRemoveClick={onRemoveCartItemClick}
                onChangeCount={onChangeCartItemCount}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CreateOrderForm;
