import React, { useEffect, useRef } from 'react';
import { AppInput, AppPhoneInput, AppPrompt } from '@ui-kit';
import { AxiosError } from 'axios';
import { IErrorResponse } from '@shared/models/errors';
import { IOrderFormInputsProps } from './OrderFormInputs.types';

/**
 * @description - инпуты сборы информации о заказе
 * @param props
 */
const OrderFormInputs = (props: IOrderFormInputsProps) => {
  const {
    isShowPostalCodeInput,
    validationError,
    orderInfo,
    onInputChange,
    setValidationError,
    setAddressError,
    cartCookiesList,
    calculateDeliveryPrice,
    ymapsSuggest,
    addressError,
    onInputAddressBlur,
    isDeliveryDataLoading,
  } = props;

  const addressPromptText =
    orderInfo.delivery === 'to_door' && addressError[0] === 'Уточните номер дома'
      ? 'Укажите № дома'
      : null;
  const addressPromptRef = useRef(null);

  /**
   *
   * @param value
   */
  const onPostalCodeManualChange = (value: string) => {
    onInputChange(value, 'manualPostalCode');

    if (value.length === 6) {
      const params = {
        postalCode: value,
        typeDelivery: orderInfo.delivery,
        products: cartCookiesList.current,
        countryCode: orderInfo.countryCode,
      };
      calculateDeliveryPrice({
        params,
        /**
         *
         * @param e
         */
        errorCallback: (e: AxiosError<IErrorResponse>) => {
          if (e?.response?.data?.extra?.fields?.postalCode) {
            setAddressError(e?.response?.data?.extra?.fields?.postalCode);
          } else {
            setValidationError(e.response.data.extra.fields);
            setAddressError(['Произошла ошибка']);
          }
        },
      });
    }
  };

  /**
   *
   * @param value
   */
  const onInputAddressChange = (value: string) => {
    onInputChange(value, 'address');
    ymapsSuggest?.current?.changeVisibility('visible');
  };

  useEffect(() => {
    // скролл до адреса без номера дома
    if (addressPromptText && addressPromptRef.current) {
      addressPromptRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }
  }, [addressPromptText]);

  return (
    <div
      className={`create-order-form__form_content-step__inputs ${
        isShowPostalCodeInput ? 'with-postal-code' : ''
      }`}
    >
      <div className="form_name">
        <AppInput
          label={'Имя и Фамилия'}
          name={'name'}
          validationError={validationError?.fullName}
          defaultValue={orderInfo.fullName}
          onChange={(value) => onInputChange(value, 'fullName')}
        />
      </div>
      <div className="form_phone">
        <AppPhoneInput
          label={'Телефон'}
          validationError={validationError?.phone}
          defaultValue={orderInfo.phone}
          onChange={(value) => onInputChange(value, 'phone')}
        />
      </div>

      <div className="form_email">
        <AppInput
          label={'E-mail'}
          onChange={(value) => onInputChange(value, 'email')}
          defaultValue={orderInfo.email}
          validationError={validationError?.email}
          type={'email'}
          name="email"
        />
      </div>

      <div className="form_address" ref={addressPromptRef}>
        <AppPrompt text={addressPromptText} delay={5000}>
          <AppInput
            validationError={addressError || validationError?.addressText}
            label={'Адрес'}
            id={'suggest'}
            onChange={onInputAddressChange}
            value={orderInfo.address}
            onBlur={() => {
              onInputAddressBlur();
            }}
          />
        </AppPrompt>
      </div>

      {isShowPostalCodeInput &&
      (orderInfo.delivery === 'to_door' || orderInfo.delivery === 'express') ? (
        <div className="form_index">
          <AppInput
            mask={[{ mask: '000000' }]}
            disabled={isDeliveryDataLoading}
            label={'Почтовый индекс'}
            onChange={onPostalCodeManualChange}
            value={orderInfo?.manualPostalCode}
            validationError={validationError?.postalCode}
          />
        </div>
      ) : null}
      <div className="form_comment">
        <AppInput
          isTextArea
          label={'Комментарий к заказу'}
          defaultValue={orderInfo?.comment}
          validationError={validationError?.comment}
          onChange={(value) => onInputChange(value, 'comment')}
        />
      </div>
    </div>
  );
};

export default OrderFormInputs;
