import React, { useEffect, useMemo } from 'react';
import {
  AppEmptyData,
  AppSearchInput,
  AppSkeleton,
  AppTable,
  AppTableHeaderSortCell,
  AppText,
} from '@ui-kit';
import { ColumnsType } from 'antd/es/table';
import {
  loadOrderListAction,
  OBSERVER_EVENT_ORDER_DETAIL_MODAL,
  OrderCard,
  OrderStatusTag,
} from '@entities/order';
import { COLOR_GRAY_MAX, EMPTY_ORDERS_BG } from '@shared/constants/colors';
import { useScreenSize } from '@shared/hooks/useScreenSize';
import './OrderHistoryTable.scss';
import { ScoreBalance } from '@entities/score';
import { customEventEmitter, isEmptyList, trimSeparator } from '@frontend-modules/frontend-utils';
import { IOrder, IOrderListParams } from '@shared/models/order';
import { useAppDispatch, useAppSelector } from '@app/store/hooks';
import { FetchStatuses } from '@shared/constants/fetch';
import { debounce } from '@shared/utils/debounce';
import { EXTERNAL_ROUTES } from '@shared/constants/routes';
import { lastChildObserver } from '@shared/utils/observer';
import { formatToRussianDate } from '@shared/utils/date';

const observer = customEventEmitter.Instance;

const tableHeaderHeight = 88;
const laptopTableHeaderHeight = 126;
const headerHeight = 94;
const bodyGap = 32;

const historyPageItemsLimit = 50;

/**
 * Компонент
 */
const OrderHistoryTable = () => {
  const { screenSize, calculateScreenType } = useScreenSize();
  const dispatch = useAppDispatch();
  const orderHistory = useAppSelector((state) => state.order.data.history);
  const isLoading = orderHistory?.fetchStatus === FetchStatuses.PENDING;
  const isLoadingMore = orderHistory?.fetchStatus === FetchStatuses.PENDING_MORE;

  const isMobile = calculateScreenType('mobile');
  const isLaptop = calculateScreenType('laptop');
  const isTablet = calculateScreenType('tablet');

  const onOrderNumberClick = (order: IOrder) => {
    observer.emit(OBSERVER_EVENT_ORDER_DETAIL_MODAL, order);
  };

  /**
   * Загрузка данных для таблицы
   *
   * @param params
   * @param params.page
   * @param params.search
   * @param params.sorting
   */
  const loadHistoryData = (params: {
    page?: number;
    sorting: IOrderListParams['sorting'];
    search?: string;
  }) => {
    const { page, sorting, search } = params;
    dispatch(
      loadOrderListAction({
        status: 'history',
        query: {
          currentPage: page || 1,
          limit: historyPageItemsLimit,
          filters: {
            status: 'history',
            ...(search?.length > 0 && { search }),
          },
          sorting,
        },
        isLoadMore: page !== 1,
      }),
    );
  };

  /**
   * Поиск заказа
   *
   * @param val
   */
  const onSearchInputChange = (val: string) => {
    loadHistoryData({ page: 1, sorting: orderHistory?.sorting, search: val });
  };
  const debouncedSetSearchValue = debounce(onSearchInputChange, 1500);

  const tableColumns = useMemo<ColumnsType<IOrder>>(() => {
    /**
     * Сортировка
     *
     * @param key
     */
    const onSortClick = (key: 'amountTotal' | 'createAt' | 'status') => {
      switch (orderHistory?.sorting[key]) {
        case 'down':
          loadHistoryData({
            page: 1,
            sorting: { [key]: 'up' },
            search: orderHistory?.filters.search,
          });
          break;
        default:
          loadHistoryData({
            page: 1,
            sorting: { [key]: 'down' },
            search: orderHistory?.filters.search,
          });
      }
    };

    return [
      {
        title: <AppText text={'Номер заказа'} />,
        dataIndex: 'orderNumber',
        key: 'orderNumber',
        maxWidth: 180,
        width: isTablet ? '20%' : '',
        /**
         *
         * @param _
         * @param order
         */
        render: (_, order) => (
          <AppText
            onClick={() => onOrderNumberClick(order)}
            type={isTablet ? 'note-bold' : 'note-bold'}
            text={`№ ${order.number}`}
          />
        ),
      },
      {
        title: <AppText text={'Баллы'} />,
        dataIndex: 'score',
        key: 'score',
        maxWidth: 92,
        width: isTablet ? '10%' : '',

        /**
         *
         * @param _
         * @param prm
         * @param prm.bonusAccrual
         */
        render: (_, { bonusAccrual }) => (
          <AppText
            text={trimSeparator(bonusAccrual)}
            type={isTablet ? 'body' : 'note'}
            color={COLOR_GRAY_MAX}
          />
        ),
      },
      {
        /** */
        title: () => (
          <AppTableHeaderSortCell
            text={`Ценa ${!isTablet ? 'в руб.' : ''}`}
            onClick={() => onSortClick('amountTotal')}
            isSorting={orderHistory?.sorting?.amountTotal === 'down'}
          />
        ),
        dataIndex: 'price',
        key: 'price',
        maxWidth: 142,
        width: isTablet ? '12%' : '',

        /**
         *
         * @param _
         * @param prm
         * @param prm.amountTotal
         */
        render: (_, { amountTotal }) => (
          <AppText
            text={`${trimSeparator(amountTotal)} ₽`}
            type={isTablet ? 'body' : 'note'}
            color={COLOR_GRAY_MAX}
          />
        ),
      },
      {
        /** */
        title: () => (
          <AppTableHeaderSortCell
            text={'Дата'}
            onClick={() => onSortClick('createAt')}
            isSorting={orderHistory?.sorting?.createAt === 'down'}
          />
        ),
        dataIndex: 'createAt',
        key: 'createAt',
        maxWidth: 182,
        width: isTablet ? '20%' : '',

        /**
         *
         * @param _
         * @param prm
         * @param prm.createAt
         */
        render: (_, { createAt }) => (
          <AppText
            text={formatToRussianDate(createAt, 'DD MMMM YYYY')}
            type={isTablet ? 'body' : 'note'}
            color={COLOR_GRAY_MAX}
          />
        ),
      },
      {
        /** */
        title: () => (
          <AppTableHeaderSortCell
            text={'Статус'}
            align={'center'}
            onClick={() => {
              onSortClick('status');
            }}
            isSorting={orderHistory?.sorting?.status === 'down'}
          />
        ),
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        maxWidth: 172,
        width: isTablet ? '20%' : '',

        render: (_, { status }) => <OrderStatusTag status={status} />,
      },
      // показываем только на десктопе
      ...(!isMobile && !isLaptop
        ? [
            {
              /** */
              title: () => (
                <AppSearchInput
                  defaultValue={orderHistory?.filters?.search}
                  onChange={debouncedSetSearchValue}
                />
              ),
              dataIndex: 'search',
              key: 'search',
              width: 250,
            },
          ]
        : []),
    ];
  }, [debouncedSetSearchValue, isLaptop, isMobile, loadHistoryData, orderHistory?.sorting]);

  const scrollHeight = useMemo(() => {
    return `calc(100vh - ${
      (isLaptop ? laptopTableHeaderHeight : tableHeaderHeight) + headerHeight + bodyGap
    }px)`;
  }, [screenSize.width]);

  const onEmptyDataBtnClick = () => {
    window.open(EXTERNAL_ROUTES.PRODUCTS);
  };

  /** */
  const onLoadMore = () => {
    if (
      isLoading ||
      isLoadingMore ||
      orderHistory?.pagination.maxPage <= orderHistory?.pagination?.currentPage
    ) {
      return false;
    }
    loadHistoryData({
      page: orderHistory?.pagination?.currentPage + 1,
      sorting: orderHistory?.sorting,
      search: orderHistory?.filters.search,
    });
  };

  useEffect(() => {
    loadHistoryData({
      page: 1,
      sorting: {
        createAt: 'up',
      },
      search: '',
    });
  }, []);

  useEffect(() => {
    if (isMobile && orderHistory?.results?.length >= historyPageItemsLimit) {
      lastChildObserver('order-card__wrapper', 'order-card', onLoadMore);
    }
  }, [orderHistory, isMobile]);

  return (
    <>
      {!isMobile ? (
        isLoading && isEmptyList(orderHistory?.results) ? (
          <AppSkeleton className={'ant-table'} />
        ) : (
          <AppTable
            rowKey={'number'}
            scrollHeight={scrollHeight}
            isShowTitle={isLaptop}
            title={() => (
              <AppSearchInput
                defaultValue={orderHistory?.filters?.search}
                onChange={debouncedSetSearchValue}
              />
            )}
            onLoadMore={onLoadMore}
            dataSource={orderHistory?.results}
            columns={tableColumns}
            locale={{ emptyText: 'Список заказов пуст' }}
            loading={
              (isLoadingMore || isLoading) && {
                indicator: <AppText text={''} />,
              }
            }
          />
        )
      ) : (
        <div className={`order-history__mobile-wrapper ${isLoadingMore ? 'loading' : ''}`}>
          <div className={'order-history__mobile-search'}>
            {!isLoading && (
              <AppSearchInput
                defaultValue={orderHistory?.filters?.search}
                onChange={debouncedSetSearchValue}
                disabled={isLoadingMore}
              />
            )}
          </div>
          <div className={'order-card__wrapper'}>
            {isLoading ? (
              <>
                <AppSkeleton height={'152px'} />
                <AppSkeleton height={'152px'} />
                <AppSkeleton height={'152px'} />
              </>
            ) : orderHistory?.results?.length ? (
              orderHistory?.results.map((orderItem) => (
                <OrderCard
                  direction={'vertical'}
                  onOrderNumberClick={onOrderNumberClick}
                  data={orderItem}
                  key={orderItem.number}
                  scoreParam={<ScoreBalance isWithPlus score={orderItem.bonusAccrual} />}
                />
              ))
            ) : (
              <AppEmptyData
                title={'Вы еще не совершили ни одной покупки'}
                description={'Хотите начать сейчас?'}
                bgColor={EMPTY_ORDERS_BG}
                buttonText={'Начать!'}
                buttonConfig={{ size: 'medium', textType: 'body' }}
                onButtonClick={onEmptyDataBtnClick}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default OrderHistoryTable;
