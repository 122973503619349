import React from 'react';
import { TOrderStatus } from '@shared/models/order';
import { AppTag } from '@ui-kit';
import { orderTagStatus } from '../../config/OrderTagStatus';
import './OrderStatusTag.scss';

/**
 * @description - тег статуса заказа
 * @param props
 * @param props.status
 */
const OrderStatusTag = (props: { status: TOrderStatus }) => {
  const { status } = props;
  return (
    <AppTag
      className={'order-status-tag'}
      color={orderTagStatus?.[status]?.color}
      backgroundColor={orderTagStatus?.[status]?.backgroundColor}
      text={orderTagStatus?.[status]?.text}
    />
  );
};

export default OrderStatusTag;
