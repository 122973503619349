import React, { useEffect, useState } from 'react';
import { AppButton, AppInput, AppPasswordInput, AppPhoneInput, AppText } from '@ui-kit';
import './AuthRegistrationUserForm.scss';
import {
  IAuthRegistrationInfo,
  IAuthRegistrationValidation,
} from './AuthRegistrationUserForm.types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { PASSWORD_CONFIRM_ERROR } from '@shared/constants/errors';
import { AuthProvider, setAuth } from '@entities/auth';
import { checkIsAllFieldsFilled } from '@shared/utils/validation';
import { getCookie, removeCookie } from '@shared/utils/cookies';
import { setUser } from '@entities/user';
import { setAccessToken, setRefreshToken } from '@shared/utils/token';
import { PRIVATE_ROUTES } from '@shared/constants/routes';

/** */
const AuthRegistrationUserForm = () => {
  const [userInfo, setUserInfo] = useState<IAuthRegistrationInfo | null>(null);
  const [validationError, setValidationError] =
    useState<Partial<IAuthRegistrationValidation> | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const nextParam = new URLSearchParams(location.search).get('next');

  const isPasswordConfirmed =
    userInfo?.passwordConfirm && userInfo?.password === userInfo?.passwordConfirm;

  /**
   *
   * @param value
   * @param type
   */
  const onInputChange = (value: string, type: keyof IAuthRegistrationInfo) => {
    setUserInfo((prev) => ({ ...prev, [type]: value }));
    if (validationError) {
      if (type === 'password') {
        setValidationError((prev) => ({ ...prev, passwordConfirm: null }));
      }
      if (type !== 'passwordConfirm') {
        setValidationError((prev) => ({ ...prev, [type]: null }));
      }
    }
  };

  useEffect(() => {
    if (userInfo?.passwordConfirm?.length) {
      if (isPasswordConfirmed) {
        setValidationError((prev) => ({ ...prev, passwordConfirm: null }));
      } else
        setValidationError((prev) => ({ ...prev, passwordConfirm: ['Пароли должны совпадать'] }));
    } else setValidationError((prev) => ({ ...prev, passwordConfirm: null }));
  }, [userInfo?.passwordConfirm]);
  /** */
  const onContinueClick = () => {
    if (isPasswordConfirmed) {
      const { passwordConfirm, ...updatedUserInfo } = userInfo;
      setIsLoading(true);
      AuthProvider.registrationUser({ ...updatedUserInfo, referralCode: getCookie('referralCode') })
        .then(() => {
          const authUserData = {
            email: updatedUserInfo.email,
            firstName: updatedUserInfo.firstName,
            lastName: updatedUserInfo.lastName,
            phone: updatedUserInfo.phone,
          };
          dispatch(setUser({ ...authUserData }));
          AuthProvider.loginUser({
            username: userInfo.email,
            password: userInfo.password,
          }).then((response) => {
            const { access, refresh } = response;
            setAccessToken(access);
            setRefreshToken(refresh);
            removeCookie('referralCode');
            dispatch(setAuth({ isAuthStatus: true }));
            navigate(nextParam || PRIVATE_ROUTES.HOME);
          });
        })
        .catch((e) => {
          setValidationError(e.response.data.extra.fields);
        })
        .finally(() => setIsLoading(false));
    } else {
      setValidationError({
        passwordConfirm: [PASSWORD_CONFIRM_ERROR],
      });
    }
  };
  const isAllRegistrationFieldsFilled = checkIsAllFieldsFilled(userInfo) && isPasswordConfirmed;

  return (
    <div className={'registration-form'}>
      <AppText
        tag={'h2'}
        text={'Регистрация'}
        className={'registration-form__title'}
        type={'title'}
      />
      <div className={'registration-form__controls'}>
        <div className={'registration-form__controls__inputs'}>
          <div className={'registration-form__controls__inputs_columns'}>
            <AppInput
              label={'Имя'}
              name={'name'}
              onPressEnter={onContinueClick}
              onChange={(value) => onInputChange(value, 'firstName')}
              validationError={validationError?.firstName}
            />
            <AppInput
              label={'Фамилия'}
              name={'surname'}
              onPressEnter={onContinueClick}
              onChange={(value) => onInputChange(value, 'lastName')}
              validationError={validationError?.lastName}
            />
            <AppInput
              label={'E-mail'}
              onChange={(value) => onInputChange(value, 'email')}
              type={'email'}
              onPressEnter={onContinueClick}
              name="email"
              validationError={validationError?.email}
            />
            <AppPhoneInput
              label={'Телефон'}
              onPressEnter={onContinueClick}
              validationError={validationError?.phone}
              onChange={(value) => onInputChange(value, 'phone')}
            />
          </div>

          <div className={'registration-form__controls__inputs_rows'}>
            <AppPasswordInput
              label={'Пароль'}
              name="password"
              isShowPrompt
              onPressEnter={onContinueClick}
              onChange={(value) => onInputChange(value, 'password')}
              validationError={validationError?.password}
            />
            <AppPasswordInput
              label={'Повторите пароль'}
              onPressEnter={onContinueClick}
              onChange={(value) => onInputChange(value, 'passwordConfirm')}
              validationError={validationError?.passwordConfirm}
            />
          </div>
        </div>
        <AppButton
          text={'Зарегистрироваться'}
          disabled={!isAllRegistrationFieldsFilled}
          loading={isLoading}
          type="primary"
          size={'large'}
          isFull
          onClick={onContinueClick}
        />
      </div>
    </div>
  );
};

export default AuthRegistrationUserForm;
